/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import classNames from 'classnames';
import {PrinterSelector, usePrinterContext} from '../../context/PrinterContext';
import {PrinterLabelId} from './PrinterConstants';

type LabelPrinterButtonProps = {
	printerSelector: PrinterSelector;
	labelId: PrinterLabelId;
	data: any;
};

const LabelPrinterButton: React.FC<LabelPrinterButtonProps> = ({printerSelector, labelId, data}) => {
	const printerContext = usePrinterContext();
	const {status, printById, usePrinterDevice} = printerContext;
	const usedPrinterDevice = usePrinterDevice(printerSelector);
	const [printing, setPrinting] = useState<boolean | undefined>(false);	
	const toolTip = status !== 'loading' ? !usedPrinterDevice ? 
		`Geen printer '${printerSelector.id}' geronden, kies een andere printer.` : `print naar '${usedPrinterDevice.id}'` : undefined;

	return (
		<span title={toolTip}
              data-bs-toggle={toolTip ? "tooltip" : undefined}
              data-bs-boundary={toolTip ? "window" : undefined} 
              data-bs-placement={toolTip ? "top" : undefined}>
			<button
				disabled={!usedPrinterDevice || printing}
				className={classNames(
					'btn btn-primary',
					status === 'success' ? 'btn-primary' : null,
					status === 'error' || !usedPrinterDevice ? 'btn-danger' : null,
					status === 'loading' || !status ? 'btn-secondary' : null
				)}
				onClick={async () => {
					if (usedPrinterDevice) {
						setPrinting(true);
						try {
							await printById(labelId, data, usedPrinterDevice);
						} finally {
							setPrinting(false);
						}
					}
				}}
			>
				Print label
			</button>
		</span>
	);
};
export {LabelPrinterButton};
