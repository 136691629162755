import {FC} from 'react'
import * as Yup from 'yup'
import {getIn, Formik, FormikHelpers} from 'formik'
import clsx from 'clsx'
import {
    CustomerRepresentation,
    DeliveryConditions,
    PaymentConditions,
    PutCustomerRepresentation,
    PostCustomerRepresentation
} from '../../../../modules/api-client/generated'
import ApiClient from "../../../../modules/api-client/ApiClient";
import {usePostCustomer} from '../hooks/use-post-customer';
import {usePutCustomer} from '../hooks/use-put-customer';
import {CatalogPicker} from "../../pim/catalog/CatalogPicker";
import {PriceListPicker} from "../../pim/pricelist/PriceListPicker";
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const companyNameExists = (companyName: string | undefined, customerId: string | undefined, resolve: any) => {

    if (!companyName) {
        resolve(true);
    } else {
        ApiClient.Crm.Customer
            .crmCustomerNameExistsGet(companyName, customerId)
            .then((d: { status: number; }) => {
                if (d.status === 404) {
                    resolve(true);
                } else if (d.status === 200) {
                    resolve(false);
                } else {
                    resolve(true);
                }
            })
            .catch(() => {
                resolve(true);
            });
    }
};

const cocNumberExists = (cocNumber: string | undefined, cocDepartment: string | undefined, customerId: string | undefined, resolve: any) => {
    if (!cocNumber) {
        resolve(true);
    } else {
        ApiClient.Crm.Customer
            .crmCustomerCocExistsGet(cocNumber, cocDepartment, customerId)
            .then((d: { status: number; }) => {
                if (d.status === 404) {
                    resolve(true);
                } else if (d.status === 200) {
                    resolve(false);
                } else {
                    resolve(true);
                }
            })
            .catch(() => {
                resolve(true);
            });
    }
};

type Props = {
    isCustomerLoading: boolean
    customer: CustomerRepresentation | undefined | null
    handleClose: () => void,
}

const CustomerModalForm: FC<Props> = ({customer, isCustomerLoading, handleClose}) => {

    const isNewCustomer = customer === null;

    const customerSchema = {
        companyName: Yup.string()
            .max(100, 'Maximaal 100 karakters.')
            .required('Bedrijf is verplicht.')
            .test(
                'companyName',
                'Bedrijfsnaam al in gebruik.',
                (value) => {
                    return new Promise(
                        resolve => companyNameExists(value, isNewCustomer ? undefined : customer?.id || undefined, resolve));
                }
            )
        ,

        cocNumber: Yup.string()
            .max(8, 'Maximaal 8 karakters.')
            .matches(
                /^[0-9]*$/,
                'Alleen cijfers zijn toegestaan.'
            )
            .test(
                'cocNumber',
                'Combinatie Kvk nummer + vestigingsnummer al in gebruik.',
                (value, context) => {
                    return new Promise(
                        resolve => cocNumberExists(value, context.parent.cocDepartment, isNewCustomer ? undefined : customer?.id || undefined, resolve));
                }
            )
        ,

        cocDepartment: Yup.string()
            .matches(
                /^[0-9]*$/,
                'Alleen cijfers zijn toegestaan'
            )
            .max(12, 'Maximaal 12 karakters.')
            .test(
                'cocDepartment',
                'Combinatie Kvk nummer + vestigingsnummer al in gebruik.',
                (value, context) => {
                    return new Promise(resolve => cocNumberExists(context.parent.cocNumber, value, customer?.id ? customer.id : undefined, resolve));
                }
            )
        ,

        vatNumber: Yup.string()
            .max(14, 'Maximaal 14 karakters.'),

        email: Yup.string()
            .email('Geen geldig e-mail.')
            .min(3, 'Minimaal 3 karakters.')
            .max(255, 'Maximaal 255 karakters.')
            .required('Email is verplicht.')
        ,

        deliveryNotificationEmail: Yup.string()
            .max(255, 'Maximaal 255 karakters.')
        ,

        telephone: Yup.string()
            .max(25, 'Maximaal 25 karakters.')
        ,

        paymentConditions: Yup.string()
            .required('Betalingscondities zijn verplicht.')
        ,
        priceListId: Yup.string()
            .required('Prijslijst is verplicht.')
        ,

        catalogId: Yup.string()
            .required('Catalogus is verplicht.')
        ,

        deliveryConditions: Yup.string()
            .required('Leveringscondities zijn verplicht.')

    };

    const addressSchema = {
        address: Yup.object().shape({

            street: Yup.string()
                .required('Straat is verplicht.')
                .max(200, 'Maximaal 200 karakters.')
            ,

            houseNumber: Yup.string()
                .required('Huisnummer is verplicht.')
                .matches(
                    /^[0-9]*$/,
                    'Alleen cijfers zijn toegestaan.'
                ).max(5, 'Maximaal 5 karakters.')
            ,

            houseNumberAddition: Yup.string().max(4, 'Maximaal 4 karakters.')
            ,

            postalCode: Yup.string()
                .required("Postcode is verplicht.")
                .max(6, 'Maximaal 6 karakters.')
                .matches(/^[1-9][0-9]{3}?(?!sa|sd|ss)[a-z]{2}$/i,
                    'Postcode moet bestaan uit 4 cijfers en twee letters zonder spatie (voorbeeld 1234AB)'
                )
            ,

            city: Yup.string()
                .required("Stad is verplicht.")
                .max(100, 'Maximaal 100 karakters.')
            ,

            country: Yup.string()
                .required('Land is verplicht.')
                .max(60, 'Maximaal 60 karakters.')
            ,

            contactPerson: Yup.object().shape({
                firstName: Yup.string()
                    .required('Voornaam is verplicht.')
                    .max(200, 'Maximaal 200 karakters.')
                ,

                lastName: Yup.string()
                    .required('Achternaam is verplicht.')
                    .max(200, 'Maximaal 200 karakters.')
                ,

                email: Yup.string()
                    .required('E-mail is verplicht.')
                    .email('Geen geldig e-mail')
                    .max(255, 'Maximaal 255 karakters.')
                ,

                telephone: Yup.string()
                    .max(25, 'Maximaal 25 karakters.')
            })
        })
    };

    type FormValues = PutCustomerRepresentation & PostCustomerRepresentation & { withAddress: boolean };

    const formInitialValues: FormValues = {
        id: customer?.id || undefined!,
        companyName: customer?.companyName || '',
        cocNumber: customer?.cocNumber || undefined,
        cocDepartment: customer?.cocDepartment || undefined,
        email: customer?.email || '',
        deliveryNotificationEmail: customer?.deliveryNotificationEmail || '',
        telephone: customer?.telephone || '',
        vatNumber: customer?.vatNumber || '',
        deliveryConditions: customer?.deliveryConditions || undefined!,
        paymentConditions: customer?.paymentConditions || undefined!,
        catalogId: customer?.catalogId || undefined,
        priceListId: customer?.priceListId || undefined,
        isArchived: customer?.isArchived || false,
        waiveTransportCosts: customer?.waiveTransportCosts || false,
        gaImportEnabled: customer?.gaImportEnabled || false,
        withAddress: false,
        address:
            {
                city: '',
                country: 'NL',
                houseNumber: undefined!,
                houseNumberAddition: '',
                postalCode: '',
                region: '',
                street: '',
                contactPerson: {
                    email: '',
                    telephone: '',
                    firstName: '',
                    lastName: ''
                },
            }
    };

    const formSchema = () => Yup.lazy((values) => {
        return values.withAddress === true ?
            Yup.object().shape(customerSchema).concat(Yup.object().shape(addressSchema)) :
            Yup.object().shape(customerSchema)
    });

    const mutatePostCustomer = usePostCustomer();
    const mutatePutCustomer = usePutCustomer();


    const onSubmit = async (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {
        if (isNewCustomer) {
            if (!values.withAddress) {
                values.address = undefined;
            }
            await mutatePostCustomer(values)
        } else {
            await mutatePutCustomer(values)
        }
        setSubmitting(false);
        handleClose()
    };

    return (
        <>
            <Formik initialValues={formInitialValues} validationSchema={formSchema} onSubmit={onSubmit}
                    validateOnBlur={true} validateOnChange={true}>

                {(formik) => (

                    <form id='kt_modal_customer_form' className='form' onSubmit={formik.handleSubmit} noValidate>

                        {/* begin::Scroll */}
                        <div
                            className='d-flex flex-column scroll-y me-n7 pe-7'
                        >

                            {/* begin form element */}
                            <div className='row mb-3'>
                                <div className={'col-4'}>
                                    <label htmlFor='companyName' className='required fw-bold fs-6 mb-2'>
                                        Bedrijfsnaam {customer?.code ? '(' + customer?.code + ')' : ''}
                                    </label>
                                </div>
                                <div className={'col'}>
                                    <input
                                        {...formik.getFieldProps('companyName')}
                                        type='text'
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            {'is-invalid': formik.touched.companyName && formik.errors.companyName},
                                            {
                                                'is-valid': formik.touched.companyName && !formik.errors.companyName,
                                            }
                                        )}
                                        id='companyName'
                                        disabled={formik.isSubmitting || isCustomerLoading}
                                    />
                                    {formik.touched.companyName && formik.errors.companyName && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.companyName}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* end form element */}

                            {/* begin form element */}
                            <div className='row mb-3'>
                                <div className={'col-4'}>

                                    <label htmlFor='cocNumber' className='fw-bold fs-6 mb-2'>
                                        KvK nummer
                                    </label>
                                </div>
                                <div className={'col-4'}>
                                    <input
                                        {...formik.getFieldProps('cocNumber')}
                                        type='text'
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            {'is-invalid': formik.touched.cocNumber && formik.errors.cocNumber},
                                            {'is-valid': formik.touched.cocNumber && !formik.errors.cocNumber}
                                        )}
                                        id='cocNumber'
                                        disabled={formik.isSubmitting || isCustomerLoading}
                                    />
                                    {formik.touched.cocNumber && formik.errors.cocNumber && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.cocNumber}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className={'col-4'}>
                                    <input
                                        {...formik.getFieldProps('cocDepartment')}
                                        type='text'
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            {'is-invalid': formik.touched.cocDepartment && formik.errors.cocDepartment},
                                            {
                                                'is-valid': formik.touched.cocDepartment && !formik.errors.cocDepartment,
                                            }
                                        )}
                                        id='cocDepartment'
                                        disabled={formik.isSubmitting || isCustomerLoading}

                                    />
                                    {formik.touched.cocDepartment && formik.errors.cocDepartment && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.cocDepartment}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* end form element */}

                            {/* begin form element */}
                            <div className='row mb-3'>
                                <div className={'col-4'}>
                                    <label htmlFor='vatNumber' className='fw-bold fs-6 mb-2'>
                                        BTW nummer
                                    </label>
                                </div>
                                <div className={'col'}>
                                    <input
                                        type='text'
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            {'is-invalid': formik.touched.vatNumber && formik.errors.vatNumber},
                                            {
                                                'is-valid': formik.touched.vatNumber && !formik.errors.vatNumber,
                                            }
                                        )}
                                        id='cocDepartment'
                                        disabled={formik.isSubmitting || isCustomerLoading}
                                        {...formik.getFieldProps('vatNumber')}
                                    />
                                    {formik.touched.vatNumber && formik.errors.vatNumber && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.vatNumber}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* end form element */}

                            {/* begin form element */}
                            <div className='row mb-3'>
                                <div className={'col-4'}>

                                    <label htmlFor='email' className='required fw-bold fs-6 mb-2'>
                                        Email
                                    </label>
                                </div>
                                <div className={'col'}>
                                    <input
                                        type='text'
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            {'is-invalid': formik.touched.email && formik.errors.email},
                                            {
                                                'is-valid': formik.touched.email && !formik.errors.email,
                                            }
                                        )}
                                        id='email'
                                        disabled={formik.isSubmitting || isCustomerLoading}
                                        {...formik.getFieldProps('email')}
                                    />
                                    {formik.touched.email && formik.errors.email && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.email}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* end form element */}

                            {/* begin form element */}
                            <div className='row mb-3'>
                                <div className={'col-4'}>

                                    <label htmlFor='deliveryNotificationEmail' className='fw-bold fs-6 mb-2'>
                                        Email afleverbevestigingen
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="email-info-tooltip">
                                                    Je kunt meerdere e-mailadressen invoeren, gescheiden door een puntkomma (;).
                                                </Tooltip>
                                            }
                                        >
                                            <i className="bi bi-info-circle ms-2" style={{cursor: 'pointer'}}></i>
                                        </OverlayTrigger>

                                    </label>
                                </div>
                                <div className={'col'}>
                                    <input
                                        type='text'
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            {'is-invalid': formik.touched.deliveryNotificationEmail && formik.errors.deliveryNotificationEmail},
                                            {
                                                'is-valid': formik.touched.deliveryNotificationEmail && !formik.errors.deliveryNotificationEmail,
                                            }
                                        )}
                                        id='deliveryNotificationEmail'
                                        disabled={formik.isSubmitting || isCustomerLoading}
                                        {...formik.getFieldProps('deliveryNotificationEmail')}
                                    />
                                    {formik.touched.deliveryNotificationEmail && formik.errors.deliveryNotificationEmail && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.deliveryNotificationEmail}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* end form element */}

                            {/* begin form element */}
                            <div className='row mb-3'>
                                <div className={'col-4'}>

                                    <label htmlFor='telephone' className='fw-bold fs-6 mb-2'>
                                        Telefoon
                                    </label>
                                </div>
                                <div className={'col'}>
                                    <input
                                        type='text'
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            {'is-invalid': formik.touched.telephone && formik.errors.telephone},
                                            {
                                                'is-valid': formik.touched.telephone && !formik.errors.telephone,
                                            }
                                        )}
                                        id='telephone'
                                        disabled={formik.isSubmitting || isCustomerLoading}
                                        {...formik.getFieldProps('telephone')}
                                    />
                                    {formik.touched.telephone && formik.errors.telephone && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.telephone}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* end form element */}

                            {/* begin form element */}
                            <div className='row mb-3'>
                                <div className={'col-4'}>

                                    <label htmlFor='paymentConditions' className='required fw-bold fs-6 mb-2'>
                                        Betalingscondities
                                    </label>
                                </div>
                                <div className={'col'}>
                                    <select
                                        {...formik.getFieldProps('paymentConditions')}
                                        id="paymentConditions"
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            {'is-invalid': formik.touched.paymentConditions && formik.errors.paymentConditions},
                                            {'is-valid': formik.touched.paymentConditions && !formik.errors.paymentConditions}
                                        )}
                                        data-kt-select2='true'
                                        value={formik.values.paymentConditions ? formik.values.paymentConditions : ''}
                                        onChange={(e) => {
                                            formik.setFieldValue("paymentConditions", e.target.value as PaymentConditions);
                                        }}
                                    >
                                        <option value=''></option>
                                        <option value={PaymentConditions.Vooraf}>Vooraf</option>
                                        <option value={PaymentConditions.OpFactuur30Dagen}>Op factuur 30 dagen</option>
                                        <option value={PaymentConditions.OpFactuur8Dagen}>Op factuur 8 dagen</option>
                                        <option value={PaymentConditions.AutomatischeIncasso}>Automatische incasso
                                        </option>
                                    </select>

                                    {formik.touched.paymentConditions && formik.errors.paymentConditions && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.paymentConditions}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* end form element */}

                            {/* begin form element */}
                            <div className='row mb-3'>
                                <div className={'col-4'}>

                                    <label htmlFor='deliveryConditions' className='required fw-bold fs-6 mb-2'>
                                        Leveringscondities
                                    </label>
                                </div>
                                <div className={'col'}>
                                    <select
                                        {...formik.getFieldProps('deliveryConditions')}
                                        id="deliveryConditions"
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            {'is-invalid': formik.touched.deliveryConditions && formik.errors.deliveryConditions},
                                            {'is-valid': formik.touched.deliveryConditions && !formik.errors.deliveryConditions}
                                        )}
                                        data-kt-select2='true'
                                        value={formik.values.deliveryConditions ? formik.values.deliveryConditions : ''}
                                        onChange={(e) => {
                                            formik.setFieldValue("deliveryConditions", e.target.value as DeliveryConditions);
                                        }}
                                    >
                                        <option value=''></option>
                                        <option value={DeliveryConditions.AfFabriek}>Af fabriek</option>
                                        <option value={DeliveryConditions.Bezorging}>Bezorging</option>
                                    </select>

                                    {formik.touched.deliveryConditions && formik.errors.deliveryConditions && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.deliveryConditions}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* end form element */}

                            {/* begin form element */}
                            <div className='row mb-3'>
                                <div className={'col-4'}>

                                    <label htmlFor='catalogId' className='required fw-bold fs-6 mb-2'>
                                        Catalogus
                                    </label>
                                </div>
                                <div className={'col'}>
                                    <CatalogPicker name="catalogId"/>

                                    {formik.touched.catalogId && formik.errors.catalogId && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.catalogId}</span>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                            {/* end form element */}
                            {/* begin form element */}
                            <div className='row mb-3'>
                                <div className={'col-4'}>

                                    <label htmlFor='priceListId' className='required fw-bold fs-6 mb-2'>
                                        Prijslijst
                                    </label>
                                </div>
                                <div className={'col'}>
                                    <PriceListPicker name="priceListId"/>

                                    {formik.touched.priceListId && formik.errors.priceListId && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.priceListId}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* end form element */}

                            <div className='row mb-3'>
                                <div className={'col-4'}>
                                    <label className='fw-bold fs-6 mb-2'>
                                    </label>
                                </div>
                                <div className={'col'}>

                                    <div className="form-check form-switch">
                                        {/* begin::Input */}
                                        <input
                                            {...formik.getFieldProps('waiveTransportCosts')}
                                            className={clsx(
                                                'form-check-input'
                                            )}
                                            type='checkbox'
                                            name='waiveTransportCosts'
                                            id='waiveTransportCosts'
                                            value={formik.values.waiveTransportCosts ? 'true' : 'false'}
                                            checked={!!formik.values.waiveTransportCosts}

                                            disabled={formik.isSubmitting || isCustomerLoading}
                                        />
                                        {/* end::Input */}

                                        {/* begin::Label */}
                                        <label className=' fw-bold fs-6 mb-2 form-check-label'
                                               htmlFor='waiveTransportCosts'>
                                            Verzendkosten NIET berekenen
                                        </label>
                                        {/* end::Label */}
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-3'>
                                <div className={'col-4'}>
                                    <label className='fw-bold fs-6 mb-2'>
                                    </label>
                                </div>
                                <div className={'col'}>

                                    <div className="form-check form-switch">
                                        {/* begin::Input */}
                                        <input
                                            {...formik.getFieldProps('gaImportEnabled')}
                                            className={clsx(
                                                'form-check-input'
                                            )}
                                            type='checkbox'
                                            name='gaImportEnabled'
                                            id='gaImportEnabled'
                                            value={formik.values.gaImportEnabled ? 'true' : 'false'}
                                            checked={!!formik.values.gaImportEnabled}

                                            disabled={formik.isSubmitting || isCustomerLoading}
                                        />
                                        {/* end::Input */}

                                        {/* begin::Label */}
                                        <label className='fw-bold fs-6 mb-2 form-check-label'
                                               htmlFor='gaImportEnabled'>
                                            Groeneveld import actief
                                        </label>
                                        {/* end::Label */}
                                    </div>
                                </div>
                            </div>

                            {(!isNewCustomer &&
                                <div className='row mb-3'>
                                    <div className={'col-4'}>
                                        <label className='fw-bold fs-6 mb-2'>
                                        </label>
                                    </div>
                                    <div className={'col'}>

                                        <div className="form-check form-switch">
                                            {/* begin::Input */}
                                            <input
                                                {...formik.getFieldProps('isArchived')}
                                                className={clsx(
                                                    'form-check-input'
                                                )}
                                                type='checkbox'
                                                name='isArchived'
                                                id='isArchived'
                                                value={formik.values.isArchived ? 'true' : 'false'}
                                                checked={!!formik.values.isArchived}
                                                disabled={formik.isSubmitting || isCustomerLoading}
                                            />
                                            {/* end::Input */}

                                            {/* begin::Label */}
                                            <label className='fw-bold fs-6 mb-2 form-check-label'
                                                   htmlFor='isArchived'>
                                                Gearchiveerd
                                            </label>
                                            {/* end::Label */}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {(isNewCustomer &&
                                <div className='row mb-3'>
                                    <div className={'col-4'}>
                                        <label className='fw-bold fs-6 mb-2'>
                                        </label>
                                    </div>
                                    <div className={'col'}>
                                        <div className="form-check form-switch">
                                            <input
                                                id="withAddressWithAddress"
                                                name="withAddress"
                                                type="radio"
                                                className='form-check-input mb-3 mb-lg-0'
                                                value={formik.values.withAddress ? 'true' : 'false'}
                                                checked={!!formik.values.withAddress}
                                                onClick={(e) => {
                                                    formik.setFieldValue("withAddress", !formik.values.withAddress);
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="withAddressWithAddress">
                                                Adres toevoegen
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* begin::address */}

                            {(isNewCustomer && formik.values.withAddress &&
                                <>
                                    <div className='separator separator-dashed my-5'></div>

                                    <div className='title m-0 p-0'>
                                        <h3 className='fw-bolder'>Adres gegevens</h3>
                                    </div>

                                    {/* begin::address.street */}
                                    <div className='row mb-3'>
                                        <div className={'col-4'}>
                                            <label htmlFor='address.street' className='required fw-bold fs-6 mb-2'>
                                                Straat + huisnummer
                                            </label>
                                        </div>
                                        <div className={'col-4'}>
                                            <input
                                                {...formik.getFieldProps('address.street')}
                                                type='text'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    {'is-invalid': getIn(formik.touched, 'address.street') && getIn(formik.errors, 'address.street')},
                                                    {'is-valid': getIn(formik.touched, 'address.street') && !getIn(formik.errors, 'address.street')}
                                                )}
                                                id='address.street'
                                                disabled={formik.isSubmitting || isCustomerLoading}
                                            />
                                            {getIn(formik.touched, 'address.street') && getIn(formik.errors, 'address.street') && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span
                                                            role='alert'>{getIn(formik.errors, 'address.street')}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className={'col'}>
                                            <input
                                                {...formik.getFieldProps('address.houseNumber')}
                                                type='text'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    {'is-invalid': getIn(formik.touched, 'address.houseNumber') && getIn(formik.errors, 'address.houseNumber')},
                                                    {'is-valid': getIn(formik.touched, 'address.houseNumber') && !getIn(formik.errors, 'address.houseNumber')}
                                                )}
                                                id='address.houseNumber'
                                                disabled={formik.isSubmitting || isCustomerLoading}
                                            />
                                            {getIn(formik.touched, 'address.houseNumber') && getIn(formik.errors, 'address.houseNumber') && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                    <span
                                                        role='alert'>{getIn(formik.errors, 'address.houseNumber')}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className={'col'}>
                                            <input
                                                {...formik.getFieldProps('address.houseNumberAddition')}
                                                type='text'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    {'is-invalid': getIn(formik.touched, 'address.houseNumberAddition') && getIn(formik.errors, 'address.houseNumberAddition')},
                                                    {'is-valid': getIn(formik.touched, 'address.houseNumberAddition') && !getIn(formik.errors, 'address.houseNumberAddition')}
                                                )}
                                                id='address.houseNumberAddition'
                                                disabled={formik.isSubmitting || isCustomerLoading}
                                            />
                                            {getIn(formik.touched, 'address.houseNumberAddition') && getIn(formik.errors, 'address.houseNumberAddition') && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                    <span
                                                        role='alert'>{getIn(formik.errors, 'address.houseNumberAddition')}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* end::address.street */}

                                    {/* begin::address.postalCode */}
                                    <div className='row mb-3'>
                                        <div className={'col-4'}>

                                            <label htmlFor='address.postalCode' className='required fw-bold fs-6 mb-2'>
                                                Postcode Plaats
                                            </label>
                                        </div>
                                        <div className={'col'}>
                                            <input
                                                {...formik.getFieldProps('address.postalCode')}
                                                type='text'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    {'is-invalid': getIn(formik.touched, 'address.postalCode') && getIn(formik.errors, 'address.postalCode')},
                                                    {'is-valid': getIn(formik.touched, 'address.postalCode') && !getIn(formik.errors, 'address.postalCode')}
                                                )}
                                                id='address.postalCode'
                                                disabled={formik.isSubmitting || isCustomerLoading}
                                            />
                                            {getIn(formik.touched, 'address.postalCode') && getIn(formik.errors, 'address.postalCode') && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                    <span
                                                        role='alert'>{getIn(formik.errors, 'address.postalCode')}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className={'col'}>
                                            <input
                                                {...formik.getFieldProps('address.city')}
                                                type='text'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    {'is-invalid': getIn(formik.touched, 'address.city') && getIn(formik.errors, 'address.city')},
                                                    {'is-valid': getIn(formik.touched, 'address.city') && !getIn(formik.errors, 'address.city')}
                                                )}
                                                id='address.city'
                                                disabled={formik.isSubmitting || isCustomerLoading}
                                            />
                                            {getIn(formik.touched, 'address.city') && getIn(formik.errors, 'address.city') && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{getIn(formik.errors, 'address.city')}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* begin::address.country */}
                                    <div className='row mb-3'>
                                        <div className={'col-4'}>
                                            <label htmlFor='address.country' className='required fw-bold fs-6 mb-2'>
                                                Land
                                            </label>
                                        </div>
                                        <div className={'col'}>
                                            <select
                                                {...formik.getFieldProps('address.country')}
                                                id="address.country"
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    {'is-invalid': getIn(formik.touched, 'address.country') && getIn(formik.errors, 'address.country')},
                                                    {'is-valid': getIn(formik.touched, 'address.country') && !getIn(formik.errors, 'address.country')}
                                                )}
                                                data-kt-select2='true'
                                                value={formik.values.address?.country ? formik.values.address.country : 'NL'}
                                                onChange={(e) => {
                                                    formik.setFieldValue("address.country", e.target.value);
                                                    formik.validateField("address.country");
                                                }}
                                            >
                                                <option value='NL'>Nederland</option>
                                                <option value='BE'>België</option>
                                                <option value='DE'>Duitsland</option>
                                                <option value='FR'>Frankrijk</option>
                                                <option value='GB'>Verenigd Koninkrijk</option>
                                                <option value='PL'>Polen</option>
                                            </select>

                                            {getIn(formik.touched, 'address.country') && getIn(formik.errors, 'address.country') && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span
                                                            role='alert'>{getIn(formik.errors, 'address.country')}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* end::address.country */}

                                    <div className='title m-0 p-0'>
                                        <h3 className='fw-bolder'>Contactpersoon</h3>
                                    </div>

                                    {/* begin::address.contactPerson.firstName */}
                                    <div className='row mb-3'>
                                        <div className={'col-4'}>

                                            <label htmlFor='address.contactPerson.firstName'
                                                   className='required fw-bold fs-6 mb-2'>
                                                Voor- en achternaam
                                            </label>
                                        </div>
                                        <div className={'col'}>
                                            <input
                                                {...formik.getFieldProps('address.contactPerson.firstName')}
                                                type='text'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    {'is-invalid': getIn(formik.touched, 'address.contactPerson.firstName') && getIn(formik.errors, 'address.contactPerson.firstName')},
                                                    {'is-valid': getIn(formik.touched, 'address.contactPerson.firstName') && !getIn(formik.errors, 'address.contactPerson.firstName')}
                                                )}
                                                id='address.contactPerson.firstName'
                                                disabled={formik.isSubmitting}
                                            />
                                            {getIn(formik.touched, 'address.contactPerson.firstName') && getIn(formik.errors, 'address.contactPerson.firstName') && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                    <span
                                                        role='alert'>{getIn(formik.errors, 'address.contactPerson.firstName')}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className={'col'}>
                                            <input
                                                {...formik.getFieldProps('address.contactPerson.lastName')}
                                                type='text'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    {'is-invalid': getIn(formik.touched, 'address.contactPerson.lastName') && getIn(formik.errors, 'address.contactPerson.lastName')},
                                                    {'is-valid': getIn(formik.touched, 'address.contactPerson.lastName') && !getIn(formik.errors, 'address.contactPerson.lastName')}
                                                )}
                                                id='address.contactPerson.lastName'
                                                disabled={formik.isSubmitting}
                                            />
                                            {getIn(formik.touched, 'address.contactPerson.lastName') && getIn(formik.errors, 'address.contactPerson.lastName') && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                    <span
                                                        role='alert'>{getIn(formik.errors, 'address.contactPerson.lastName')}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* end::address.contactPerson.firstName */}

                                    {/* begin::address.contactPerson.email */}
                                    <div className='row mb-3'>
                                        <div className={'col-4'}>
                                            <label htmlFor='address.contactPerson.email'
                                                   className='required fw-bold fs-6 mb-2'>
                                                E-mail & telefoonnummer
                                            </label>
                                        </div>
                                        <div className={'col'}>
                                            <input
                                                {...formik.getFieldProps('address.contactPerson.email')}
                                                type='text'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    {'is-invalid': getIn(formik.touched, 'address.contactPerson.email') && getIn(formik.errors, 'address.contactPerson.email')},
                                                    {'is-valid': getIn(formik.touched, 'address.contactPerson.email') && !getIn(formik.errors, 'address.contactPerson.email')}
                                                )}
                                                id='address.contactPerson.email'
                                                disabled={formik.isSubmitting}
                                            />
                                            {getIn(formik.touched, 'address.contactPerson.email') && getIn(formik.errors, 'address.contactPerson.email') && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                    <span
                                                        role='alert'>{getIn(formik.errors, 'address.contactPerson.email')}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className={'col'}>
                                            <input
                                                {...formik.getFieldProps('address.contactPerson.telephone')}
                                                type='text'
                                                className={clsx(
                                                    'form-control form-control-solid mb-3 mb-lg-0',
                                                    {'is-invalid': getIn(formik.touched, 'address.contactPerson.telephone') && getIn(formik.errors, 'address.contactPerson.telephone')},
                                                    {'is-valid': getIn(formik.touched, 'address.contactPerson.telephone') && !getIn(formik.errors, 'address.contactPerson.telephone')}
                                                )}
                                                id='address.contactPerson.telephone'
                                                disabled={formik.isSubmitting}
                                            />
                                            {getIn(formik.touched, 'address.contactPerson.telephone') && getIn(formik.errors, 'address.contactPerson.telephone') && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                    <span
                                                        role='alert'>{getIn(formik.errors, 'address.contactPerson.telephone')}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {/* end::address */}

                        </div>

                        {/* begin::Actions */}
                        <div className='text-center pt-15'>
                            <button
                                type='reset'
                                className='btn btn-light me-3'
                                data-kt-customer-modal-action='cancel'
                                data-dismiss="modal"
                                onClick={() => handleClose()}
                                disabled={formik.isSubmitting}
                            >
                                Annuleren
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-customer-modal-action='submit'
                                disabled={formik.isSubmitting}
                            >
                                <span className='indicator-label'>
                                    {isNewCustomer ? 'Toevoegen' : 'Wijzigen'}
                                </span>
                                {formik.isSubmitting && (
                                    <span className='indicator-progress'>
                                        Even wachten a.u.b
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Actions */}

                    </form>
                )}
            </Formik>

        </>
    )
}

export {CustomerModalForm}
