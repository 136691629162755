import React from 'react';
import {Field, FieldProps} from 'formik';
import {useQuery} from '@tanstack/react-query';
import {CustomSelect, OptionsType, SelectOption} from '../CustomSelect';

export interface StopRabbetPickerSelectProps {
	name: string;
	required?: boolean;
	queryFn: () => Promise<ItemCollectionType>;
	queryKey?: string;
}

export type StopRabbetPickerProps = {
	required?: boolean;
	queryFn: () => Promise<ItemCollectionType>;
	queryKey?: string;
};

export type ItemCollectionType = number[];

export const StopRabbetPicker = ({required, queryFn, queryKey}: StopRabbetPickerProps): React.ComponentType<FieldProps<string>> => {
	let options: OptionsType<SelectOption> = [];

	const {data: list} = useQuery<ItemCollectionType>([queryKey ?? 'StopRabbetPicker'], queryFn);

	if (list) {
		options = list.map((item) => {
			return {value: item, label: `${item}mm`};
		});
	}

	required ??= true;

	return (props: FieldProps<string>) => <CustomSelect<SelectOption> options={options} isMulti={false} required={required} isClearable={!required} placeholder={'Geen aanslagsponning'} {...props} />;
};

export const StopRabbetPickerField: React.FC<StopRabbetPickerSelectProps> = ({name, required, queryFn, queryKey}) => {
	let options: OptionsType<SelectOption> = [];

	const {data: list} = useQuery<ItemCollectionType>([queryKey ?? 'StopRabbetPickerField'], queryFn );

	if (list) {
		options = list.map((item) => {
			return {value: item, label: `${item}mm`};
		});
	}

	return (
		<>
			<Field
				className="custom-select"
				name={name}
				options={options}
				component={CustomSelect}
				placeholder={'Kies een aanslag sponning'}
				isMulti={false}
				isClearable={!required}
			/>
		</>
	);
};
