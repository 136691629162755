import React, { useState } from 'react';

interface YearPickerProps {
        setSelectedYear: (year: number) => void;
        initialYear?: number;
        minYear?: number;
        maxYear?: number;
}

const YearPicker: React.FC<YearPickerProps> = ({ setSelectedYear, initialYear = new Date().getFullYear(), minYear = 2023, maxYear = new Date().getFullYear() }) => {
        const [year, setYear] = useState<number>(initialYear);

        const handlePrevYear = () => {
                const newYear = Math.max(year - 1, minYear);
                setYear(newYear);
                setSelectedYear(newYear);
        };

        const handleNextYear = () => {
                const newYear = Math.min(year + 1, maxYear);
                setYear(newYear);
                setSelectedYear(newYear);
        };

        return (
            <div className="d-flex justify-content-between align-items-center me-3">
                    <a href="#" className="mx-2" onClick={year > minYear ? handlePrevYear : () => {}}>
                            <i className={`fa fa-caret-left text-${year > minYear ? 'white' : 'muted'}`} />
                    </a>
                    <span className="text-white">{year}</span>
                    <a href="#" className="mx-2" onClick={year < maxYear ? handleNextYear : () => {}}>
                            <i className={`fa fa-caret-right text-${year < maxYear ? 'white' : 'muted'}`} />
                    </a>
            </div>
        );
};

export default YearPicker;