import React, {FC} from 'react';
import {PriceSpecification} from '../../../modules/api-client/generated';
import {Tooltip} from 'react-bootstrap';
import {TooltipProps} from 'react-bootstrap/Tooltip';
import {EkoTable, TableHeader} from '../../../shared/components/table';
import Euro from '../../../shared/components/Euro';

export type PriceSpecificationPopoverProps = TooltipProps &
	React.RefAttributes<HTMLDivElement> & {
		spec: PriceSpecification;
		level: number;
	};

export const PriceSpecificationPopover: FC<PriceSpecificationPopoverProps> = ({spec, level, ...props}: PriceSpecificationPopoverProps) => {
	const renderLevel = (priceSpecification: PriceSpecification, level: number = 0, index: number = 0): React.ReactElement => {
		return (
			<React.Fragment key={`price-specification-${level}-${index}`}>
				{!priceSpecification.composition && (
					<tr className={`level-${level}`}>
						<td className="fit-content text-end pe-0">{priceSpecification.quantity}x</td>
						<td className="text-start ps-1">{priceSpecification.product?.title}</td>
						<td className="fit-content text-end">{!priceSpecification?.composition && <Euro value={priceSpecification?.unitPrice} />}</td>
					</tr>
				)}
				{priceSpecification?.composition && priceSpecification.composition.map((s, index) => renderLevel(s, level + 1, index))}
			</React.Fragment>
		);
	};

	return (
		<Tooltip className="price-specification-tooltip" {...props}>
			<EkoTable className="price-specification-table gy-1">
				<TableHeader>
					<th colSpan={2}>Prijsopbouw</th>
				</TableHeader>
				<tbody>
					{spec && renderLevel(spec)}
				</tbody>
				<tfoot>
					<tr>
						<td className="border-top" colSpan={2} style={{borderTopStyle: 'double', borderTopWidth: '3px'}}></td>
						<td
							className="fw-bold border-top text-end text-nowrap"
							style={{
								borderTopStyle: 'double',
								borderTopWidth: '3px',
							}}
						>
							<Euro value={spec?.unitPrice} />
						</td>
					</tr>
				</tfoot>
			</EkoTable>
		</Tooltip>
	);
};
