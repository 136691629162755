import { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";

import { useData } from "./hooks";
import {
  dymoUrlBuilder as innerDymoUrlBuilder,
  getDymoPrintersFromXml as innerGetDymoPrintersFromXml,
  dymoRequestBuilder as innerDymoRequestBuilder,
  printLabel as innerPrintSingleLabel,
} from "./dymo_utils";

export const dymoUrlBuilder = innerDymoUrlBuilder;
export const dymoRequestBuilder = innerDymoRequestBuilder;
export const getDymoPrintersFromXml = innerGetDymoPrintersFromXml;
export const printLabel = innerPrintSingleLabel;

export function useDymoCheckService(port) {
  const [status, setStatus] = useState({ status: "initial", host: '', port: '', protocol: '' });
  const tokenSource = useRef();

  useEffect(() => {
    if (tokenSource.current) {
      tokenSource.current.cancel();
    }
    tokenSource.current = axios.CancelToken.source();
    setStatus("loading");
    dymoRequestBuilder({ method: "GET", wsAction: "status", cancelToken: tokenSource.current.token })
      .then((response) => {
        tokenSource.current = null;
        setStatus({
          status: "success",         
          host: response?.config?.activeHost,
          port: response?.config?.activePort,
          protocol: response?.config?.activeProtocol,
        });        
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setStatus({
            status: "error",         
            host: error?.config?.activeHost,
            port: error?.config?.activePort,
            protocol: error?.config?.activeProtocol
          });
        }
      });
    return () => {
      if (tokenSource.current) {
        tokenSource.current.cancel();
      }
    };
  }, [port]);

  return status;
}

export function useDymoFetchPrinters(statusDymoService, modelPrinter = "LabelWriterPrinter", port) {
  
  const [data, setData] = useData(
    { statusFetchPrinters: "initial", 
      printers: null, 
      error: null, 
      getPrinters: async () => {}, 
      host: null, 
      port: null, 
      protocol: null }
    );
  const tokenSource = useRef();

  const getPrinters = useCallback(async () => {
      if (statusDymoService === "success") {
        
        if (tokenSource.current) {
          tokenSource.current.cancel();
        }

        console.log('get dymo printers')
        
        setData({ statusFetchPrinters: "loading", printers: [], host: null, port: null, protocol: null });

        tokenSource.current = axios.CancelToken.source();
       
        let promise = dymoRequestBuilder({ method: "GET", wsAction: "getPrinters", cancelToken: tokenSource.current.token })
          .then((response) => {

            console.log('success getting dymo printers')
            tokenSource.current = null;
            
            setData({
              statusFetchPrinters: "success",
              printers: getDymoPrintersFromXml(
                response.data, 
                modelPrinter,                 
                response.config.activeHost, 
                response.config.activePort,
                response.config.activeProtocol
                ),
              error: null,
              host: response.config.activeHost,
              port: response.config.activePort,
              protocol: response.config.activeProtocol,
              getPrinters
            });
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {                            
              setData({ 
                statusFetchPrinters: "error", 
                printers: null, 
                error: error, 
                getPrinters, 
                host: error?.config?.activeHost,
                port: error?.config?.activePort,
                protocol: error?.config?.activeProtocol,
              });
            }
          });

          return promise;
      }
    }, [modelPrinter, setData, statusDymoService]
  );

  useEffect(() => {    
    getPrinters();
    return () => {
      if (tokenSource.current) {
        tokenSource.current.cancel();
      }
    };

  }, [modelPrinter, port, setData, statusDymoService, getPrinters]);

  
  return data;
}

export function useDymoOpenLabel(statusDymoService, labelXML, port) {
  const [data, setData] = useData({ statusOpenLabel: "initial", label: null, error: null });
  const tokenSource = useRef();

  useEffect(() => {
    if (statusDymoService === "success") {
      if (tokenSource.current) {
        tokenSource.current.cancel();
      }
      tokenSource.current = axios.CancelToken.source();
      setData({ statusOpenLabel: "loading" });
      dymoRequestBuilder({
        method: "POST",
        wsAction: "renderLabel",
        cancelToken: tokenSource.current.token,
        axiosOtherParams: { data: `labelXml=${encodeURIComponent(labelXML)}&renderParamsXml=&printerName=` },
        headers: { "Access-Control-Request-Private-Network": true, "Access-Control-Allow-Origin": "*" },
      })
        .then((response) => {
          tokenSource.current = null;
          setData({ statusOpenLabel: "success", label: response.data, error: null });
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setData({ statusOpenLabel: "error", label: null, error: error });
          }
        });
    }
    return () => {
      if (tokenSource.current) {
        tokenSource.current.cancel();
      }
    };
  }, [statusDymoService, labelXML, setData, port]);

  return data;
}
