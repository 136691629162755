import React from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
import { EkoCard, EkoCardBody } from "../../../../../_metronic/helpers";
import {useGetTransportPlanning} from "../expedition/hooks/use-transport-planning";
import Loading from "../../../../shared/components/Loading";
import StatSticker from "../../../../shared/components/StatSticker";
import {Link} from 'react-router-dom'
import {EmRoutePath} from "../EmRoutes";
import {formatDate} from "../../../../shared/components/date";
import { DateTime } from "luxon";

const TransportPlanning: React.FC = () => {


    const {
        isInitialLoading,
        data: expeditionsByDate,
        isError
    } = useGetTransportPlanning();

    if (isError) {
        return <>Fout bij het ophalen van de gegevens!</>;
    }
    return (
        <>
            <PageTitle>
                Transport planning
            </PageTitle>
            <EkoCard>
                <EkoCardBody>
                    {(isInitialLoading || !expeditionsByDate )&& <Loading />}
                    <div className="d-flex flex-column">
                        {expeditionsByDate && expeditionsByDate.filter(x=>x.deliveredOrderCount != x.orderCount).map((expedition) => (
                            <Link
                                key={expedition.date}
                                to={EmRoutePath.link(EmRoutePath.planningTransportV2DateLink(`${expedition.date}`))}
                            className={`d-flex justify-content-between align-items-center mb-4 p-2 border rounded bg-light text-decoration-none ${DateTime.fromISO(expedition.date).hasSame(DateTime.now(), 'day') ? 'bg-light-success' : ''}`}>
                                    <div className={'fs-xl-2 mb-0 text-nowrap'}>{DateTime.fromISO(expedition.date).toFormat('ccc dd-MM-yyyy')}</div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <StatSticker value={expedition.customerCount} label=" adressen" iconClass="fa fa-map-location-dot" />
                                    <StatSticker value={expedition.maxSillLength} label=" max lengte" iconClass="fa fa-ruler" />
                                    <StatSticker value={expedition.orderCount} label=" orders" iconClass="fa fa-box" />
                                    <StatSticker value={expedition.totalSillCount} label=" dorpels" iconClass="fa fa-cubes" />
                                    <StatSticker value={expedition.plannedOrderCount} label=" niet gereed" iconClass="fa fa-times-circle" variant={expedition.plannedOrderCount > 0 ? `danger`:undefined} />
                                    <StatSticker value={expedition.readyForShipmentOrderCount} label=" gereed" iconClass="fa fa-pallet-box" variant={expedition.readyForShipmentOrderCount > 0 ? `warning`:undefined} />
                                    <StatSticker value={expedition.inTransitOrderCount} label=" ingeladen" iconClass="fa fa-forklift" variant={expedition.inTransitOrderCount > 0 ? `info`:undefined} />
                                    <StatSticker value={expedition.deliveredOrderCount} label=" afgeleverd" iconClass="fa fa-check" variant={expedition.deliveredOrderCount == expedition.orderCount ? `success`:undefined} />
                                </div>
                            </Link>
                        ))}
                    </div>
                </EkoCardBody>
            </EkoCard>
        </>
    )
}

export default TransportPlanning
