import React, {ReactElement, useState, useEffect} from 'react';
import {PageTitle} from "../../../../../_metronic/layout/core";
import {EkoCard, EkoCardBody} from "../../../../../_metronic/helpers";
import {DateTime} from "luxon";
import {useParams, useNavigate} from "react-router-dom";
import {EmRoutePath} from "../EmRoutes";
import "react-datepicker/dist/react-datepicker.css";
import Loading from '../../../../shared/components/Loading';
import {EkoTable, TableHeader} from '../../../../shared/components/table';
import {useExpeditionsByDate} from "../expedition/hooks/use-expeditions-by-date";
import {
    ExpeditionByDateCustomer,
    ExpeditionStatusCodes
} from "../../../../modules/api-client/generated";
import {ExpeditionStatus} from "../../../../shared/components/ExpeditionStatus";
import {EkoCardHeader, EkoCardToolbar} from "../../../../shared/components/card";
import SpinnerButtonV2 from "../../../../shared/components/SpinnerButtonV2";
import {Address} from "../../../../shared/components/Address";
import StatSticker from "../../../../shared/components/StatSticker";
import {ContactPerson} from "../../../../shared/components/ContactPerson";
import {useUpdateExpeditionStatus} from "../../sm/orders/hooks/use-put-expedition-status";

const TransportPlanningV2: React.FC = () => {
    const {date} = useParams<{ date: string }>();
    const [showModal, setShowModal] = useState<boolean>(false)


    const parsedDate = date ? DateTime.fromFormat(date, "yyyy-MM-dd") : DateTime.invalid("Invalid date");
    const defaultDate = parsedDate.isValid ? parsedDate : DateTime.now();

    const [datedt, setDatedt] = useState<DateTime>(defaultDate);

    const {
        isInitialLoading,
        data: expeditionsByDate,
        isError
    } = useExpeditionsByDate(datedt);

    if (isInitialLoading) {
        return <Loading/>;
    }
    if (isError) {
        return <>Fout bij het ophalen van de gegevens!</>;
    }

    return (
        <>

            {!expeditionsByDate &&
                <>Geen gegevens gevonden voor deze datum!</>
            }
            <PageTitle toolbar={
                <div className="d-flex justify-content-end align-items-center mb-4">
                    {expeditionsByDate &&
                        <>
                            <StatSticker value={expeditionsByDate.customerCount} label=" adressen"
                                         iconClass="fa fa-map-location-dot"/>
                            <StatSticker value={expeditionsByDate.maxSillLength} label=" max lengte"
                                         iconClass="fa fa-ruler"/>
                            <StatSticker value={expeditionsByDate.orderCount} label=" orders" iconClass="fa fa-box"/>
                            <StatSticker value={expeditionsByDate.totalSillCount} label=" dorpels"
                                         iconClass="fa fa-cubes"/>
                            <StatSticker value={expeditionsByDate.plannedOrderCount} label=" niet gereed"
                                         iconClass="fa fa-times-circle" variant="danger"/>
                            <StatSticker value={expeditionsByDate.readyForShipmentOrderCount} label=" gereed"
                                         iconClass="fa fa-pallet-box" variant="warning"/>
                            <StatSticker value={expeditionsByDate.inTransitOrderCount} label=" ingeladen"
                                         iconClass="fa fa-forklift" variant="info"/>
                            <StatSticker value={expeditionsByDate.deliveredOrderCount} label=" afgeleverd"
                                         iconClass="fa fa-check" variant="success"/>
                        </>

                    }
                </div>
            }>
                {`Planning voor ` + datedt.toFormat('dd-MM-yyyy')}
            </PageTitle>
            <div className={'row'}>
                <div className={'col'}>
                    {isInitialLoading && <Loading/>}
                    {expeditionsByDate && expeditionsByDate.customers
                        .sort((a, b) => {
                            const aAllDelivered = a.orders.every(order => order.expeditionStatus === ExpeditionStatusCodes.Delivered);
                            const bAllDelivered = b.orders.every(order => order.expeditionStatus === ExpeditionStatusCodes.Delivered);
                            return Number(aAllDelivered) - Number(bAllDelivered);
                        })
                        .map((customer) => {
                        return (
                            <React.Fragment key={customer.customer}>
                                <CustomerOrders customer={customer}/>
                            </React.Fragment>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

interface CustomerOrdersProps {
    customer: ExpeditionByDateCustomer
}

const CustomerOrders: React.FC<CustomerOrdersProps> = ({customer}) => {

    const navigate = useNavigate();

    const [selectedOrders, setSelectedOrders] = useState<string[]>([]);

    const inTransitMutation = useUpdateExpeditionStatus();

    useEffect(() => {
        const selectableOrders = customer.orders
            .filter(order => order.expeditionStatus === ExpeditionStatusCodes.ReadyForShipment || order.expeditionStatus === ExpeditionStatusCodes.InTransit)
            .map(order => order.orderId);
        setSelectedOrders(selectableOrders);
    }, [customer.orders]);


    const onInTransitClick = () => {
        inTransitMutation.mutate({
            status: ExpeditionStatusCodes.InTransit,
            orderIds: selectedReadyForShipmentOrders.map(s => s.orderId),
        });
    }
    const onDeliverBtnClick = async () => {
        const queryParams = new URLSearchParams({selectedOrders: selectedInTransitOrders.map(s => s.orderId).join(',')});
        navigate(EmRoutePath.link('transport/v2/' + EmRoutePath.deliver + '?' + queryParams.toString()));
    }

    const handleCheckboxChange = (orderId: string) => {
        setSelectedOrders((prevSelectedOrders) =>
            prevSelectedOrders.includes(orderId)
                ? prevSelectedOrders.filter((id) => id !== orderId)
                : [...prevSelectedOrders, orderId]
        );
    };

    const handleSelectAllChange = () => {
        const selectableOrders = customer.orders.filter(order =>
            order.expeditionStatus === ExpeditionStatusCodes.ReadyForShipment || order.expeditionStatus === ExpeditionStatusCodes.InTransit
        ).map(order => order.orderId);

        if (selectedOrders.length === selectableOrders.length) {
            setSelectedOrders([]);
        } else {
            setSelectedOrders(selectableOrders);
        }
    };
    const allOrdersDelivered = customer.orders.every(order => order.expeditionStatus === ExpeditionStatusCodes.Delivered);


    const selectedReadyForShipmentOrders = customer.orders.filter(order =>
        selectedOrders.includes(order.orderId) && order.expeditionStatus === ExpeditionStatusCodes.ReadyForShipment
    );

    const selectedInTransitOrders = customer.orders.filter(order =>
        selectedOrders.includes(order.orderId) && order.expeditionStatus === ExpeditionStatusCodes.InTransit
    );

    return (
        <EkoCard className={'mb-5'} key={customer.customer}>
            <EkoCardHeader title={customer.customer} className={'py-7'} subtitle={
                <div className={'d-flex flex-column mt-2'}>
                    <Address address={customer.address} inline={true}/>
                    <ContactPerson contactPerson={customer.contactPerson} inline={true}/>
                </div>}>
                <EkoCardToolbar>

                    <div>
                        {allOrdersDelivered ? (
                            <span className={'text-success'}><i className={'fa fa-check-circle text-success'}></i> Alles afgeleverd</span>
                        ) : (
                            <>
                                <SpinnerButtonV2 type="button" className="btn btn-info me-2"
                                                 disabled={selectedReadyForShipmentOrders.length === 0}
                                                 onClick={() => onInTransitClick()} dotdotdot={false}>
                                    {selectedReadyForShipmentOrders.length} order(s) inladen
                                </SpinnerButtonV2>
                                <SpinnerButtonV2 type="button" className="btn btn-success"
                                                 disabled={selectedInTransitOrders.length === 0}
                                                 onClick={() => onDeliverBtnClick()} dotdotdot={false}>
                                    {selectedInTransitOrders.length} order(s) afleveren
                                </SpinnerButtonV2>
                            </>
                        )}
                    </div>
                </EkoCardToolbar>
            </EkoCardHeader>
            {!allOrdersDelivered && (
                <EkoCardBody>
                    <EkoTable className={'card-xxl-stretch'}>
                        <colgroup>
                            <col width={'50%'}/>
                            <col width={'30%'}/>
                            <col width={'20%'}/>
                        </colgroup>
                        <TableHeader>
                            <th>

                                <div className="d-flex align-items-center" onClick={handleSelectAllChange}>
                                    <input
                                        type="checkbox"
                                        checked={selectedOrders.length === customer.orders.filter(order =>
                                            order.expeditionStatus === ExpeditionStatusCodes.ReadyForShipment || order.expeditionStatus === ExpeditionStatusCodes.InTransit
                                        ).length}
                                    />
                                    <span className="fs-4 ms-2">Order</span>
                                </div>
                            </th>
                            <th>Dorpels</th>
                            <th>Status</th>
                        </TableHeader>
                        <tbody>
                        {customer.orders.map((order) => (
                            <tr key={order.orderId}
                                onClick={() =>
                                    (order.expeditionStatus === ExpeditionStatusCodes.InTransit || order.expeditionStatus === ExpeditionStatusCodes.ReadyForShipment) &&
                                    handleCheckboxChange(order.orderId)
                                }>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="checkbox"
                                            disabled={order.expeditionStatus != ExpeditionStatusCodes.InTransit && order.expeditionStatus != ExpeditionStatusCodes.ReadyForShipment}
                                            checked={selectedOrders.includes(order.orderId)}
                                        />
                                        <span className="fs-4 ms-2">{order.orderCode}</span>
                                    </div>
                                </td>
                                <td>{order.sillCount} dorpels</td>
                                <td>
                                    <ExpeditionStatus status={order.expeditionStatus}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                        <TableHeader>
                            <th>{customer.orderCount} orders</th>
                            <th>{customer.sillCount} dorpels<br/>
                                <span className={''}>{customer.maxSillLength}m maximale lengte</span></th>
                            <th></th>

                        </TableHeader>
                    </EkoTable>
                </EkoCardBody>
            )}
        </EkoCard>
    );
};

export default TransportPlanningV2
