/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {OrderAddressModel, OrderAddressRepresentation} from "../../modules/api-client/generated";

type Props = {
    address?: {
        street?: string|null;
        houseNumber?: string|number|null;
        houseNumberAddition?: string|null;
        postalCode?: string|null;
        city?: string|null;
        country?: string|null;
        attention?: string|null;
        extraAddressLine?: string|null;
    }
    withCountry?: boolean
    inline?: boolean;

}
const Address: React.FC<Props> = ({address,withCountry = false, inline = false}) => {
    if (!address) return (<></>)

    const addressLine = `${address.street} ${address.houseNumber}${address.houseNumberAddition ? ' ' + address.houseNumberAddition : ''}, ${address.postalCode} ${address.city}${withCountry ? ', ' + address.country : ''}`;

    return (
        <div>
            {address.attention && <span className="fw-bold">{address.attention}<br /></span>}
            {inline ? (
                <span>{addressLine}</span>
            ) : (
                <>
                    <span>{address.street} {address.houseNumber} {address.houseNumberAddition}</span><br />
                    {address.extraAddressLine && <span>{address.extraAddressLine}<br /></span>}
                    <span>{address.postalCode} {address.city}</span>
                    {withCountry && <span><br />{address.country}</span>}
                </>
            )}
        </div>
    );
}

export {Address}
