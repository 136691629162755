import React, { useLayoutEffect } from 'react';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import * as Yup from 'yup';
import {
	useCreateLine,
	useUpdateLine,
	useGetMatrixConfigurationLine
} from '../../../hooks/matrix/use-matrix-configuration-mapping';
import Loading from '../../../../components/Loading';
import {CatalogItemPicker} from "../../../../../backoffice/modules/pim/sill/SillDetails/SillVariantsCard";
import TextField from "../../../../components/TextField";
import FieldErrors from "../../../../components/FieldErrors";
import {UpdateMatrixConfigurationLine} from "../../../../../modules/api-client/generated";
import {StandardProductPicker} from "../../../../../backoffice/modules/pim/catalogitem/CatalogItemPicker";

const schema = {
	code: Yup.string().max(50, 'Maximaal 50 karakters.').required('Code is verplicht.'),
	omschrijving: Yup.string().max(255, 'Maximaal 255 karakters.').required('Omschrijving is verplicht.'),
	dxfBestand: Yup.string().nullable(),
	dxfBlock: Yup.string().nullable(),
	catalogItemId: Yup.string().nullable(),
};

const formSchema = () =>
	Yup.lazy(() => {
		return Yup.object().shape(schema);
	});

interface MatrixConfigurationLineUpsertProps {
	id: string | undefined;
	handleSuccess?: () => void;
}

const MatrixConfigurationLineUpsert: React.FC<MatrixConfigurationLineUpsertProps> = ({ id, handleSuccess }) => {
	const createMutation = useCreateLine();
	const updateMutation = useUpdateLine();
	const isAdd = !id;
	const mutation = isAdd ? createMutation : updateMutation;
	const { data: importMapping, isInitialLoading, isError } = useGetMatrixConfigurationLine(id);

	if (isInitialLoading) return <Loading />;
	if (isError) return <>Error...</>;

	const initialValues:UpdateMatrixConfigurationLine = {
		id: importMapping?.id ?? '',
		code: importMapping?.code ?? '',
		omschrijving: importMapping?.omschrijving ?? '',
		dxfBestand: importMapping?.dxfBestand ?? null,
		dxfBlock: importMapping?.dxfBlock ?? null,
		catalogItemId: importMapping?.catalogItem?.id ?? null,
	};

	const onSubmit = async (values:UpdateMatrixConfigurationLine, { setSubmitting, resetForm }: FormikHelpers<typeof initialValues>) => {
		await mutation.mutateAsync(values, {
			onSuccess: () => {
				resetForm({
					values: {
						id: '',
						code: '',
						omschrijving: '',
						dxfBestand: null,
						dxfBlock: null,
						catalogItemId: null,
					},
				});
				if (handleSuccess) {
					handleSuccess();
				}
			},
			onSettled: () => {
				setSubmitting(false);
			},
		});
	};


	return (
		<Formik initialValues={initialValues} enableReinitialize={true} onSubmit={onSubmit} validationSchema={formSchema}>
			<MatrixConfigurationLineUpsertForm id={id} mutation={mutation} />
		</Formik>
	);
};

interface MatrixConfigurationLineUpsertFormProps {
	id: string | undefined;
	mutation: ReturnType<typeof useCreateLine | typeof useUpdateLine>;
}

const MatrixConfigurationLineUpsertForm: React.FC<MatrixConfigurationLineUpsertFormProps> = ({ id, mutation }) => {
	const { handleSubmit, isSubmitting, errors, setErrors } = useFormikContext<UpdateMatrixConfigurationLine>();

	const mergeServerErrors = () => {
		if (mutation.serverValidationErrors) {
			setErrors({ ...errors, ...mutation.serverValidationErrors });
		}
	};
	useLayoutEffect(mergeServerErrors, [mutation.serverValidationErrors, errors, setErrors]);

	return (
		<form onSubmit={handleSubmit}>
			<div className="row mt-5">
				<div className={'col d-flex flex-column w-100'}>
					<div>
							<div className={'my-4'}>
								<label className="form-label" htmlFor={'code'}>
									Externe Matrix code
								</label>
								<p>
								<small id="codeHelp" className="form-text text-danger">
									Let op: deze wordt bepaald door Matrix!
								</small>
								</p>
								<TextField row={false} name={'code'} />

							</div>
							<div className={'my-4'}>
								<label className="form-label" htmlFor={'omschrijving'}>
									Omschrijving
								</label>
								<TextField row={false} name={'omschrijving'} />
							</div>
							<div className={'my-4'}>
								<label className="form-label" htmlFor={'dxfBestand'}>
									DXF Bestand
								</label>
								<TextField row={false} name={'dxfBestand'} />
							</div>
							<div className={'my-4'}>
								<label className="form-label" htmlFor={'dxfBlock'}>
									DXF Block
								</label>
								<TextField row={false} name={'dxfBlock'} />
							</div>
							<div className={'my-4'}>
								<label className="form-label" htmlFor={'catalogItemId'}>
									Ekosiet catalogus item
								</label>
								<StandardProductPicker name={'catalogItemId'} />
								<FieldErrors field="catalogItemId" />
							</div>
					</div>
					<div className="align-self-end mt-auto">
						<button type="submit" className="btn btn-primary" data-kt-customer-modal-action="submit" disabled={isSubmitting}>
							{!isSubmitting && <span className="indicator-label">{id ? 'Wijzigen' : 'Toevoegen'}</span>}
							{isSubmitting && (
								<span className="indicator-label">
                  Even wachten a.u.b
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
							)}
						</button>
					</div>
				</div>
			</div>
		</form>
	);
};

export default MatrixConfigurationLineUpsert;