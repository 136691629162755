import React from 'react';
import {Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {ErrorBoundary, FallbackProps} from 'react-error-boundary';
import axios from 'axios';
import {ImportsPage} from '../pages/ImportsPage';
import {ImportPage} from '../pages/ImportPage';
import {ValidationResolverPage} from "../pages/ValidationResolverPage";
import {CustomerImportProfileMappingPage} from "../pages/mappings/GA/CustomerImportProfileMappingPage";
import {CustomerImportSluitpotMappingPage} from "../pages/mappings/GA/CustomerImportSluitpotMappingPage";
import {SillTypeMappingPage} from "../pages/mappings/GA/SillTypeMappingPage";
import {CompartmentLayoutMappingPage} from "../pages/mappings/GA/CompartmentLayoutMappingPage";
import {StopProfilesMappingPage} from "../pages/mappings/GA/StopProfilesMappingPage";
import {GlazingProfilesMappingPage} from "../pages/mappings/GA/GlazingProfilesMappingPage";
import {GlazingBarMappingPage} from "../pages/mappings/GA/GlazingBarMappingPage";
import {CornerpieceMappingPage} from "../pages/mappings/GA/CornerpieceMappingPage";
import {ImportMappingPage} from "../pages/mappings/ImportMappingPage";
import {MatrixCatalogItemMappingPage} from "../pages/mappings/Matrix/MatrixCatalogItemMappingPage";


export const ImportRoutePath = {

    list: 'list',

    import: ':id',
    importValidation: 'c/:customerId/v/:validationId',

    importMapping: 'mapping',
    importGaProfileMapping: 'profile',
    importGaSluitpotMapping: 'sluitpot',
    importGaSillTypeMapping: 'sills',
    importGaStopProfileMapping: 'stop-profiles',
    importGaGlazingprofileMapping: 'glazingprofiles',
    importGaGlazingbarMapping: 'glazingbars',
    importGaCornerpiecesMapping: 'cornerpieces',
    importGaCompartmentLayoutMapping: 'compartment-layout',
	importGaArticleMapping: 'articles',

    importMatrixCatalogItemMapping: 'matrix/catalog-item',
    importMatrixDiscountGroupMapping: 'matrix/kortingsgroepen',

    importLink: (id: string) => {
        return ImportRoutePath.import.replace(':id', id);
    },

    importValidationLink: (id: string, customerId: string, validationId: string) => {
        return ImportRoutePath.importValidation
            .replace(':id', id)
            .replace(':customerId', customerId)
            .replace(':validationId', validationId)
            ;
    },
};

const ImportRoutes: React.FC = () => {
    return (
        <Routes>
            <Route element={<ErrorBoundary FallbackComponent={AclFallback}> <Outlet/>
            </ErrorBoundary>}>

                <Route path={ImportRoutePath.importMapping} element={<ImportMappingPage/>}>
                    <Route path={ImportRoutePath.importGaProfileMapping} element={<CustomerImportProfileMappingPage/>}/>
                    <Route path={ImportRoutePath.importGaSluitpotMapping} element={<CustomerImportSluitpotMappingPage/>}/>
                    <Route path={ImportRoutePath.importGaSillTypeMapping} element={<SillTypeMappingPage/>}/>
                    <Route path={ImportRoutePath.importGaCompartmentLayoutMapping} element={<CompartmentLayoutMappingPage/>}/>
                    <Route path={ImportRoutePath.importGaStopProfileMapping} element={<StopProfilesMappingPage/>}/>
                    <Route path={ImportRoutePath.importGaGlazingprofileMapping} element={<GlazingProfilesMappingPage/>}/>
                    <Route path={ImportRoutePath.importGaGlazingbarMapping} element={<GlazingBarMappingPage/>}/>
                    <Route path={ImportRoutePath.importGaCornerpiecesMapping} element={<CornerpieceMappingPage/>}/>
                    <Route path={ImportRoutePath.importGaArticleMapping} element={<>TODO</>}/>
                    <Route path={ImportRoutePath.importMatrixCatalogItemMapping} element={<MatrixCatalogItemMappingPage/>}/>
                    <Route path={ImportRoutePath.importMatrixDiscountGroupMapping} element={<>TODO: Matrix discountgroup mapping</>}/>
                </Route>
                <Route path={ImportRoutePath.list} element={<ImportsPage/>}/>
                <Route path={ImportRoutePath.import} element={<><ImportPage/><Outlet/></>}>
                    <Route path={ImportRoutePath.importValidation} element={<ValidationResolverPage/>}/>
                </Route>
            </Route>
            <Route index element={<Navigate to={ImportRoutePath.list}/>}/>
        </Routes>
    );
};

const AclFallback: React.FC<FallbackProps> = (props) => {
    if (axios.isAxiosError(props.error)) {
        if (props.error.response?.status === 404) {
            return <>Not found</>;
        }
        if (props.error.response?.status === 500) {
            return <>Server error</>;
        }
    }
    return <>Error</>;
};

export default ImportRoutes;
