import React, {useState, useEffect} from "react";
import Loading from "../../../../../shared/components/Loading";
import {
    useUpdateExpeditionStatus
} from "../../../sm/orders/hooks/use-put-expedition-status";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Button, Modal} from "react-bootstrap";
import {modalsRoot} from "../../../../../global-helpers";
import {SignatureCanvas} from "./SignatureCanvas";
import * as api from "../../../../../modules/api-client/generated";
import PhotoCapture from "./PhotoCapture";
import {
    usePostPhotos,
    usePostSignature
} from "../hooks/use-post-proof-of-delivery";

import {Tabs, Tab} from "react-bootstrap";
import {EmRoutePath} from "../../EmRoutes";
import SpinnerButtonV2 from "../../../../../shared/components/SpinnerButtonV2";
import {ExpeditionStatusCodes} from "../../../../../modules/api-client/generated";

const DeliverOrders: React.FC = () => {

    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [orders, setOrders] = useState<string[]>([]);

    const [photoFileIds, setPhotoFileIds] = useState<string[]>([]);
    const [signatureFileId, setSignatureFileId] = useState<string | null>(null);
    const [isPhotoSkipped, setPhotoSkipped] = useState(false);
    const [key, setKey] = useState('signature');

    const navigate = useNavigate();
    const saveSignatureMutation = usePostSignature();
    const savePhotosMutation = usePostPhotos();
    const deliverMutation = useUpdateExpeditionStatus();

    useEffect(() => {
        const selectedOrders = searchParams.get('selectedOrders')?.split(',') || [];
        setOrders(selectedOrders);
        setLoading(false);
    }, [searchParams]);


    useEffect(() => {
        if (photoFileIds.length > 0) {
            savePhotosMutation.mutate({
                orderIds: orders,
                fileIds: photoFileIds
            });

        }
    }, [photoFileIds]);

    useEffect(() => {
        if (signatureFileId) {
            saveSignatureMutation.mutate({
                orderIds: orders,
                fileId: signatureFileId,
            });
        }

    }, [signatureFileId]);

    useEffect(() => {
        if (signatureFileId) {
            setKey('photo');
        }
    }, [signatureFileId]);

    useEffect(() => {
        if ((photoFileIds.length > 0 || isPhotoSkipped) && (signatureFileId)) {
            setKey('afleveren');
        }
    }, [photoFileIds, isPhotoSkipped, signatureFileId]);


    const deliver = () => {
        deliverMutation.mutate(
            {
                status: ExpeditionStatusCodes.Delivered,
                orderIds: orders,
            });
        navigate(EmRoutePath.link(EmRoutePath.planningTransportV2));
    }

    const handleSkipped = () => {
        setPhotoSkipped(true);
    };


    const getTabIcon = (tabKey: string) => {
        if (tabKey === 'signature') {
            if (signatureFileId) {
                return <i className="fa fa-check-circle text-success"/>;
            }
            return <i className="fa fa-circle text-secondary"/>;
        }
        if (tabKey === 'photo') {
            if (photoFileIds.length > 0) {
                return <i className="fa fa-check-circle text-success"/>;
            }
            if (isPhotoSkipped) {
                return <i className="fa fa-exclamation-triangle text-warning"/>;
            }
            return <i className="fa fa-circle text-secondary"/>;
        }
        return null;
    };

    return (
        <Modal
            container={modalsRoot}
            tabIndex={-1}
            aria-hidden="true"
            autoFocus={true}
            className="configurator-log-modal"
            dialogClassName={'modal-dialog-centered'}
            contentClassName={'shadow-lg'}
            show={true}
            onHide={() => navigate(-1)}
            fullscreen={true}
            animation={false}
            backdrop={'static'}
        >
            <Modal.Header closeButton>
                <Modal.Title>Orders afleveren</Modal.Title>
            </Modal.Header>

            <Modal.Body className="flex-fill">

                {error &&
                    <div>{error}</div>
                }
                {loading &&
                    <Loading/>
                }
                <Tabs activeKey={key} onSelect={(k) => setKey(k || 'signature')} className="mb-3 w-100"
                      variant={'pills'}>
                    <Tab eventKey="signature" title={<span>Handtekening {getTabIcon('signature')}</span>}
                         tabClassName={'p-5'}>
                        <div className={'d-flex align-items-center justify-content-center'}>
                            <div className={'text-center'}>
                                <h1>Handtekening</h1>
                                <SignatureCanvas
                                    title={'Handtekening voor ontvangst'}
                                    fileCategory={api.FileCategory.AfleverHandtekening}
                                    altText={""}
                                    required={true}
                                    description={""}
                                    saveFileId={setSignatureFileId}
                                />
                            </div>
                        </div>


                    </Tab>
                    <Tab eventKey="photo" title={<span>Afleverfoto {getTabIcon('photo')}</span>} tabClassName={'p-5'}>
                        <div className={'d-flex align-items-center justify-content-center'}>
                            <PhotoCapture
                                title={"Afleverfoto"}
                                handleSkipped={handleSkipped}
                                required={false}
                                altText={""}
                                description={""}
                                isActive={key === 'photo'}
                                fileCategory={api.FileCategory.AfleverFoto}
                                saveFileIds={setPhotoFileIds}
                            />
                        </div>

                    </Tab>
                    <Tab eventKey="afleveren" title={<span>Afleveren {getTabIcon('afleveren')}</span>}
                         tabClassName={'p-5'}>

                        <div className={'d-flex justify-content-center align-items-center h-auto mt-5'}>
                            <SpinnerButtonV2 className="btn btn-success btn-lg p-10"
                                             disabled={!(photoFileIds || isPhotoSkipped) && (signatureFileId)}
                                             onClick={() => deliver()}>
                                {orders.length} order(s) afleveren
                            </SpinnerButtonV2>
                        </div>

                    </Tab>
                </Tabs>

            </Modal.Body>

            <Modal.Footer>
                <div className={'d-flex align-items-center'}>

                    <Button variant="link" className="mx-4" onClick={() => navigate(-1)}>
                        Sluit
                    </Button>

                </div>

            </Modal.Footer>
        </Modal>

    );

}
export default DeliverOrders
