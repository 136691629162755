/* eslint-disable jsx-a11y/anchor-is-valid */
import {useParams} from 'react-router-dom'
import {EkoCard, EkoCardBody} from '../../../_metronic/helpers'
import {PageTitle} from "../../../_metronic/layout/core";
import React, {ReactElement, useState} from "react";
import {useGetOrder} from "../../backoffice/modules/sm/orders/hooks/use-get-order";
import {EditOrderModal} from "../../backoffice/modules/sm/orders/edit-order/components/EditOrderModal";
import {EditOrderToolbar} from "../../backoffice/modules/sm/orders/edit-order/components/EditOrderToolbar";
import {OrderLineTable} from "../components/OrderLineTable";
import {ShippingDetails} from "../components/ShippingDetails";
import {OrderRemarks} from "../components/OrderRemarks";
import {OrderActions} from "../components/OrderActions";
import {ExpeditionStatus} from "../../shared/components/ExpeditionStatus";
import {Button} from "react-bootstrap";
import {DateTime} from "luxon";
import {
    ProofOfDeliveryOverview
} from "../../backoffice/modules/sm/orders/edit-order/components/ProofOfDeliveryOverview";
import {OrderDocument} from "../../backoffice/modules/sm/orders/components/OrderDocument";
import {ExpeditionStatusCodes} from "../../modules/api-client/generated";
type ProofOfDelivery = {
    fileId: string;
    blobName: string;
    fileUrl: string;
    proofOfDeliveryType: string;
    creationDate: string;
    creationUsername: string;
}
export function Order() {
    const {id} = useParams<{ id: string }>();
    const {data: order} = useGetOrder(id);
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [modalFullscreen, setModalFullscreen] = useState<true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'>('lg-down')
    const [modalTitle, setModalTitle] = useState<string | undefined | null>(null);
    const [modalComponent, setModalComponent] = useState<ReactElement | undefined | null>(null);
    var viewProofOfDelivery = (pod: ProofOfDelivery) => {
        setModalComponent(() =>
            <ProofOfDeliveryOverview url={pod.fileUrl} date={pod.creationDate} username={pod.creationUsername}/>
        );
        setModalTitle('Bewijs van ontvangst');
        setShowEditModal(true);
    }

    var openPakbon = () => {
        setModalComponent(() => <OrderDocument handleClose={() => setShowEditModal(false)} id={order!.id}
                                               orderNbr={order?.code} type={'packingslip'}/>)
        setModalTitle('Pakbon')
        setShowEditModal(true)
    }

    return (
        <>
            {order?.id &&
                <>
                    <EditOrderModal show={showEditModal} handleClose={() => setShowEditModal(false)}
                                    title={modalTitle}
                                    size={'xl'}
                                    fullscreen={modalFullscreen}
                                    component={modalComponent}
                    />
                    <PageTitle toolbar={
                        <EditOrderToolbar
                            setModalTitle={setModalTitle}
                            setModalComponent={setModalComponent}
                            setShowEditModal={setShowEditModal}
                            delivered={order.expedition?.status == ExpeditionStatusCodes.Delivered && order.expedition?.proofOfDeliveries != null && order.expedition?.proofOfDeliveries.length > 0}
                            orderId={order.id}
                            status={order.status}
                            orderNbr={order.code}
                        />
                    }>{`Order ${order.code}`}</PageTitle>

                    <div className="row">
                        <div className={'col-12 col-lg-9 order-last order-lg-first'}>
                            <div className={'row mb-5'}>
                                <div className={'col col-lg-7'}>

                                    <ShippingDetails status={order.status}
                                                     contact={order.orderContact}
                                                     customerId={order.customer.id}
                                                     reference={order.expedition?.reference}
                                                     person={order.expedition?.contactPerson}
                                                     deliverycondition={order.expedition?.deliveryCondition}
                                                     preferredExpeditionDate={order.expedition?.preferredExpeditionDate ? new Date(order.expedition?.preferredExpeditionDate) : null}
                                                     plannedExpeditionDate={order.expedition?.plannedExpeditionDate ? new Date(order.expedition?.plannedExpeditionDate) : null}
                                                     deliveredDate={order.expedition?.delivered?.date ? new Date(order.expedition.delivered.date) : null}
                                                     address={order.expedition?.deliveryAddress}
                                                     orderId={order.id}
                                                     expeditionStatus={order.expedition?.status}
                                                     setModalTitle={setModalTitle}
                                                     setModalComponent={setModalComponent}
                                                     setShowEditModal={setShowEditModal}
                                    />

                                </div>
                                <div className={'col'}>
                                    {/*<BillingDetails paymentStatus={order.financial.status}*/}
                                    {/*                priceList={order.financial.priceList}*/}
                                    {/*                address={order.financial.invoiceAddress}*/}
                                    {/*                person={order.financial.contactPerson}*/}
                                    {/*                projectDiscount={order.financial.projectDiscount ? order.financial.projectDiscount : 0.00}*/}
                                    {/*                paymentCondition={order.financial.paymentCondition}*/}
                                    {/*                status={order.status}*/}
                                    {/*                orderId={order.id}*/}
                                    {/*                setModalTitle={setModalTitle}*/}
                                    {/*                setModalComponent={setModalComponent}*/}
                                    {/*                setShowEditModal={setShowEditModal}*/}
                                    {/*/>*/}
                                    <OrderRemarks remarks={order.remarks}
                                                  orderId={order.id}

                                                  setModalTitle={setModalTitle}
                                                  setModalComponent={setModalComponent}
                                                  setShowEditModal={setShowEditModal}
                                    />

                                </div>

                            </div>
                            <div className="d-flex flex-column gap-5" style={{overflowAnchor: "auto"}}>
                                <EkoCard className={'py-4 flex-row-fluid overflow-hidden'} flush={true}>
                                    <EkoCardBody className={'pt-0'}>

                                        <OrderLineTable
                                            orderId={order.id}
                                            customerId={order.customer.id}
                                            pricelistId={order.financial.priceList.id}
                                            catalogId={order.catalog.id}
                                            lines={order.lines}
                                            projectDiscount={order.financial.projectDiscount}
                                            totalPriceGross={order.financial.totalPriceGross}
                                            totalPriceNet={order.financial.totalPriceNet}
                                            status={order.status}
                                            setModalTitle={setModalTitle}
                                            setModalComponent={setModalComponent}
                                            setModalFullscreen={setModalFullscreen}
                                            setShowEditModal={setShowEditModal}
                                            isOrderEdit={true}
                                        />

                                    </EkoCardBody>
                                </EkoCard>
                            </div>
                        </div>

                        <div className="col">
                            <OrderActions
                                order={order}
                                setModalTitle={setModalTitle}
                                setModalComponent={setModalComponent}
                                setShowEditModal={setShowEditModal}
                            />


                            <EkoCard className={'py-4 flex-row-fluid overflow-hidden'} flush={true}>
                                <div className="card-header">
                                    <div
                                        className="card-title d-flex flex-column flex-xxl-row justify-content-between align-items-center w-100">
                                        <h4>Levering</h4>
                                        <h2>
                                            <ExpeditionStatus status={order.expedition?.status} className={'fs-4'}/>
                                        </h2>
                                    </div>
                                </div>
                                <EkoCardBody className={''}>
                                    <div className={'mb-4'}>
                                        <div className={'fs-6 text-muted'}>Geplande leverdatum:</div>
                                        {order.expedition?.plannedExpeditionDate ? DateTime.fromISO(order.expedition?.plannedExpeditionDate).toFormat('dd-MM-yyyy') : 'Onbekend'}<br/>
                                    </div>

                                    {order.expedition?.status == ExpeditionStatusCodes.Delivered && order.expedition?.delivered &&
                                        <>
                                            <div className={'mb-4'}>
                                                <div className={'fs-6 text-muted'}>Afgeleverd op:</div>
                                                {DateTime.fromISO(order.expedition?.delivered.date).toFormat('dd-MM-yyyy HH:mm')}<br/>
                                            </div>
                                            <div className={'mb-4'}>

                                            {order.expedition?.proofOfDeliveries  && (
                                                order.expedition?.proofOfDeliveries.map(pod =>
                                                    <a href="#" onClick={() => viewProofOfDelivery(pod)}>
                                                    <img src={pod.fileUrl} alt="Afleverbewijs" className={'img-fluid rounded'} />
                                                    </a>
                                                ))}
                                            </div>
                                            {order.expedition?.proofOfDeliveries != null && order.expedition?.proofOfDeliveries.length > 0 &&
                                            <Button onClick={() => openPakbon()} size={'sm'}
                                                    variant={"primary"}>Bekijk pakbon</Button>
                                            }
                                        </>

                                    }
                                </EkoCardBody>
                            </EkoCard>


                        </div>
                    </div>
                </>
            }
        </>
    )
}
