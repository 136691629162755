/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactElement} from 'react'
import {
    DeliveryConditions,
    OrderAddressRepresentation,
    OrderContactPersonRepresentation, OrderStatusCodes
} from "../../modules/api-client/generated";
import {PutContactForm} from "../../backoffice/modules/sm/orders/edit-order/components/forms/PutContactForm";
import {OrderContactTypes} from "../../backoffice/modules/sm/orders/hooks/use-put-contact";
import {
    PutExpeditionConditionForm
} from "../../backoffice/modules/sm/orders/edit-order/components/forms/PutExpeditionConditionForm";
import {PutAddressForm} from "../../backoffice/modules/sm/orders/edit-order/components/forms/PutAddressForm";
import {OrderAddressTypes} from "../../backoffice/modules/sm/orders/hooks/use-put-address";
import {
    PutExpeditionDateForm
} from "../../backoffice/modules/sm/orders/edit-order/components/forms/PutExpeditionDateForm";
import {EkoCard,EkoCardBody} from "../../shared/components/card";
import {formatDate} from "../../shared/components/date";
import {Address} from "../../shared/components/Address";
import {Person} from "../../backoffice/modules/sm/orders/edit-order/components/Person";
import {useEkosietAuth0} from "../../modules/auth0/core/useEkosietAuth0";
import {PutReferenceForm} from "../../backoffice/modules/sm/orders/edit-order/components/forms/PutReferenceForm";
import {ExpeditionStatus} from "../../shared/components/ExpeditionStatus";


type Props = {
    deliverycondition?: DeliveryConditions
    preferredExpeditionDate?: Date | null
    plannedExpeditionDate?: Date | null
    deliveredDate?: Date | null
    address?: OrderAddressRepresentation
    person?: OrderContactPersonRepresentation
    contact?: OrderContactPersonRepresentation
    reference?: string|null
    status?: string
    customerId: string
    expeditionStatus?: string | null
    orderId?: string
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
}
const ShippingDetails: React.FC<Props> = ({
                                              person,
                                              preferredExpeditionDate,
                                              plannedExpeditionDate,
                                              deliveredDate,
    reference,
    contact,
                                              address,
                                              deliverycondition,
                                              status,
                                              setShowEditModal,
                                              setModalTitle,
                                              setModalComponent,
                                              orderId,customerId,
                                              expeditionStatus
                                          }) => {
    const {isMedewerker} = useEkosietAuth0();

    if (!orderId) return (<></>)

    var editContact = () => {
        setModalComponent(() => <PutContactForm handleClose={() => setShowEditModal(false)} customerId={customerId}
                                                type={OrderContactTypes.expedition} contact={person} id={orderId}/>)
        setModalTitle('Wijzig contactpersoon voor expeditie')
        setShowEditModal(true)
    }
    var editExpeditionCondition = () => {
        setModalComponent(() => <PutExpeditionConditionForm handleClose={() => setShowEditModal(false)}
                                                            expeditionCondition={deliverycondition} id={orderId}/>)
        setModalTitle('Wijzig leveringsconditie')
        setShowEditModal(true)
    }
    var editExpeditionAddress = () => {
        setModalComponent(() => <PutAddressForm handleClose={() => setShowEditModal(false)}
                                                type={OrderAddressTypes.expedition} address={address} id={orderId}/>)
        setModalTitle('Wijzig afleveradres')
        setShowEditModal(true)
    }
    var editExpeditionDate = () => {
        setModalComponent(() => <PutExpeditionDateForm handleClose={() => setShowEditModal(false)}
                                                       expeditionDate={preferredExpeditionDate} id={orderId}/>)
        setModalTitle('Wijzig leveringsdatum')
        setShowEditModal(true)
    }

    var editOrderContact = () => {
        setModalComponent(()=><PutContactForm handleClose={()=>setShowEditModal(false)} contact={contact} id={orderId} type={OrderContactTypes.order} customerId={customerId}/>)
        setModalTitle('Wijzig order contact')
        setShowEditModal(true)
    }
    var editReference = () => {
        setModalComponent(()=><PutReferenceForm handleClose={()=>setShowEditModal(false)} reference={reference} id={orderId}/>)
        setModalTitle('Wijzig order referentie')
        setShowEditModal(true)
    }

    return (
        <>

            <EkoCard className={'flex-row-fluid mb-5'}  flush={true}>
                <EkoCardBody>
                    <div className={'row'}>
                        <div className={'col'}>
                            <div className={'mb-5'}>
                                <div className={'fs-6 text-muted'}>Referentie</div>
                                {status === OrderStatusCodes.Draft || status === OrderStatusCodes.Placed ?
                                    <a href='#'
                                       onClick={() => editReference()}>
                                        {reference ?
                                            <>{reference}</>
                                            :
                                            <>Voeg een referentie toe</>
                                        }
                                    </a>
                                    :
                                    <>{reference}</>
                                }
                            </div>
                            <div className={''}>
                                <div className={'fs-6 text-muted'}>Contactpersoon</div>
                                {status === OrderStatusCodes.Draft || status === OrderStatusCodes.Placed ?
                                    <a href='#'
                                       onClick={() => editOrderContact()}>
                                        {contact ?
                                            <Person person={contact}/>
                                            :
                                            <div className={'text-danger'}>
                                                <i className={'fas fa-exclamation-triangle'}></i> Voeg
                                                contactpersoon toe
                                            </div>
                                        }
                                    </a>
                                    :
                                    <Person person={contact}/>
                                }
                            </div>
                        </div>
                        <div className={'col'}>
                            <div className={'mb-5'}>
                                <div className={'fs-6 text-muted'}>Leveringsadres</div>

                                {status === OrderStatusCodes.Draft || status === OrderStatusCodes.Placed ?
                                    <a href='#'
                                       onClick={() => editContact()}>
                                        {person ?
                                            <Person person={person}/>
                                            :
                                            <div className={'text-danger'}>
                                                <i className={'fas fa-exclamation-triangle'}></i> Voeg
                                                contactpersoon toe
                                            </div>
                                        }
                                    </a>
                                    :
                                    <Person person={person}/>
                                }
                                {deliverycondition === DeliveryConditions.Bezorging &&
                                    <div className={'mt-3'}>
                                        {status === OrderStatusCodes.Draft || status === OrderStatusCodes.Placed ?
                                            <a href='#'
                                               onClick={() => editExpeditionAddress()}>
                                                {address ?
                                                    <Address address={address}/>
                                                    :
                                                    <div className={'text-danger'}>
                                                        <i className={'fas fa-exclamation-triangle'}></i> Voeg adres
                                                        toe
                                                    </div>
                                                }

                                            </a>
                                            :
                                            <>
                                                <Address address={address}/>
                                            </>

                                        }
                                    </div>
                                }
                            </div>
                            <div className={'fs-6 text-muted'}>Gewenste levering</div>
                            {(status === OrderStatusCodes.Draft || status === OrderStatusCodes.Placed) && isMedewerker ?
                                <a href='#' className={''}
                                   onClick={() => editExpeditionDate()}>

                                    {!preferredExpeditionDate &&
                                        <>Zo spoedig mogelijk</>
                                    }

                                    {preferredExpeditionDate &&
                                        formatDate(preferredExpeditionDate)
                                    }
                                </a>
                                :
                                <>
                                    {!preferredExpeditionDate &&
                                        <>Zo spoedig mogelijk</>
                                    }
                                    {preferredExpeditionDate &&
                                        formatDate(preferredExpeditionDate)
                                    }
                                </>

                            }
                            <div className={'fs-6 mt-5 text-muted'}>Leveringsconditie</div>

                            {status === OrderStatusCodes.Draft || status === OrderStatusCodes.Placed ?
                                <a href='#' className={''}
                                   onClick={() => editExpeditionCondition()}>
                                    <div className={''}>
                                        {deliverycondition === DeliveryConditions.Bezorging ?
                                            <>
                                                Bezorgen
                                            </>
                                            :
                                            <>
                                                Af Fabriek
                                            </>
                                        }
                                    </div>
                                </a>
                                :
                                <>
                                    <div className={'h4'}>
                                        {deliverycondition === DeliveryConditions.Bezorging ?
                                            <>
                                                Bezorgen
                                            </>
                                            :
                                            <>
                                                Af Fabriek
                                            </>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </EkoCardBody>
            </EkoCard>
        </>
    )
}

export {
    ShippingDetails
}
