/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {usePrinterContext} from '../../../context/PrinterContext';
import clsx from 'clsx';
import Select from 'react-select';
import {PrinterProtocolOptions} from '../PrinterConstants';

type ZebraPrinterInfoProps = {
	setPrinterHostEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

const ZebraPrinterSettings: React.FC<ZebraPrinterInfoProps> = ({setPrinterHostEdit}) => {
	const printerContext = usePrinterContext();

	const [zebraPrinterHostName, setZebraPrinterHostName] = useState<string | null | undefined>(printerContext?.zebraPrinterHost?.name);
	const [zebraPrinterHostPort, setZebraPrinterHostPort] = useState<string | null | undefined>(printerContext?.zebraPrinterHost?.port);
	const [zebraPrinterHostProtocol, setZebraPrinterHostProtocol] = useState<'http' | 'https' | null | undefined>(printerContext?.zebraPrinterHost?.protocol);

	let printerProtocolSelectedValue = PrinterProtocolOptions.find((option: any) => option.value === zebraPrinterHostProtocol) || PrinterProtocolOptions[0];

	useEffect(() => {
		if (printerContext?.zebraPrinterHost && printerContext?.zebraPrinterHost?.name) {
			setZebraPrinterHostName(printerContext?.zebraPrinterHost.name);
			setZebraPrinterHostPort(printerContext?.zebraPrinterHost.port);
			setZebraPrinterHostProtocol(printerContext?.zebraPrinterHost.protocol || 'http');
		} else {
			setZebraPrinterHostProtocol(null);
			setZebraPrinterHostName(null);
			setZebraPrinterHostPort(null);
		}
	}, [printerContext?.zebraPrinterHost]);

	if (!printerContext) {
		return (
			<span className="alert alert-danger" role="alert">
				Geen printer context
			</span>
		);
	}

	return (
		<div className="mt-5">
			<label className="form-label" htmlFor="cboPrinterHostProtocol">
				Protocol
			</label>

			<Select
				className={clsx('w-100')}
				styles={{
					// Fixes the overlapping problem of the component
					menu: (provided) => ({...provided, zIndex: 9999}),
				}}
				classNamePrefix="select"
				defaultValue={printerProtocolSelectedValue}
				value={printerProtocolSelectedValue}
				isClearable={false}
				isSearchable={false}
				name={'cboPrinterHostProtocol'}
				options={PrinterProtocolOptions}
				onChange={(newValue) => {
					setZebraPrinterHostProtocol(newValue?.value);
				}}
			/>

			<label className="form-label" htmlFor="txtPrinterHost">
				Host
			</label>

			<input className="form-control" type="text" name="txtPrinterHost" value={zebraPrinterHostName || ''} onChange={(e) => setZebraPrinterHostName(e.target.value)} />

			<label className="form-label" htmlFor="txtPrinterPort">
				Port
			</label>

			<input className="form-control" type="number" name="txtPrinterPort" value={zebraPrinterHostPort || ''} onChange={(e) => setZebraPrinterHostPort(e.target.value)} />

			<button
				type="submit"
				className="btn btn-primary mt-5"
				onClick={(event) => {
					event.preventDefault();
					if (zebraPrinterHostName) {
						printerContext?.setZebraPrinterHost({
							name: zebraPrinterHostName,
							port: zebraPrinterHostPort || '',
							protocol: zebraPrinterHostProtocol || 'http',
						});
					}

					setPrinterHostEdit(false);
				}}
			>
				<span>Opslaan</span>
			</button>

			<button
				type="submit"
				className="btn btn-secondary mt-5 ms-5"
				onClick={(event) => {
					event.preventDefault();

					setZebraPrinterHostName('localhost');
					setZebraPrinterHostPort('446');
					setZebraPrinterHostProtocol('https');

					printerContext?.setZebraPrinterHost({
						name: 'localhost',
						port: '446',
						protocol: 'https',
					});

					setPrinterHostEdit(false);
				}}
			>
				<span>https:Lokaal:446</span>
			</button>

			<button
				type="submit"
				className="btn btn-secondary mt-5 ms-5"
				onClick={(event) => {
					event.preventDefault();

					setZebraPrinterHostName('localhost');
					setZebraPrinterHostPort('9100');
					setZebraPrinterHostProtocol('http');

					printerContext?.setZebraPrinterHost({
						name: 'localhost',
						port: '9100',
						protocol: 'http',
					});

					setPrinterHostEdit(false);
				}}
			>
				<span>https:Lokaal:9100</span>
			</button>		
		</div>
	);
};

export {ZebraPrinterSettings};
