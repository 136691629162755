/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {DymoPrinterDevice, PrinterBrand, PrinterDevice, usePrinterContext} from '../../../context/PrinterContext';
import clsx from 'clsx';
import { DymoTestPrintLabel } from '../LabelPrinterTemplates';
import { printerTags } from '../PrinterConstants';

type DymoPrinterInfoProps = {
	device: DymoPrinterDevice;
};

const DymoPrinterDeviceInfo: React.FC<DymoPrinterInfoProps> = ({device}) => {
	const printerContext = usePrinterContext();
	if (!printerContext) {
		return (
			<span className="alert alert-danger" role="alert">
				Geen printer context
			</span>
		);
	}

	return (
		<div key={`${device.name}_${device.host?.name}_${device.host?.port}`} className={clsx('m-2', 'card', 'border', device.isConnected ? 'border-primary' : 'border-danger')}>
			<div className="card-body">
				<div className="row mb-4">
					<div className="col-11">
						<strong>{device.id}</strong>{' '}
						{device === printerContext.manualSelectedPrinter ? (
							<span className="badge badge-success">{device.isSetByUser ? 'Geselecteerd door gebruiker' : 'Geselecteerd door systeem'}</span>
						) : (
							``
						)}
					</div>
					<div className="col-1 text-end">
						<span className="badge badge-secondary ms-1">Dymo</span>
					</div>
				</div>
				Naam: {device.name} <br />
				Model: {device.model} <br />
				Lokaal: {device.isLocal ? 'Ja' : 'Nee'} <br />
				Verbonden: {device.isConnected ? 'Ja' : 'Nee'} <br />
				Host: {device.host?.name}				
				{device.host?.port ? `:${device.host.port}` : ''} <br />
				{printerTags.has(device.id) ? <>Tags: {printerTags.get(device.id)?.map((tag: string) => <span className="badge badge-secondary ms-1">{tag}</span>)}<br></br></>: '' }
				{device.isConnected && (
					<>
						<button
							onClick={() =>
								printerContext?.setManualPrinter({
									id: device.id,
									name: device.name,
									brand: PrinterBrand.Dymo,
									isSetByUser: true,
								})
							}
							className="btn btn-sm btn-primary mt-5 me-2"
						>
							<span className="indicator-label">
								<i className={'fa fa-check pe-0'}></i>&nbsp;Kies printer
							</span>
						</button>
					</>
				)}
				{device.isConnected && (
					<>
						<button
							onClick={() => {
								printerContext?.printByFunction(DymoTestPrintLabel, null, device);
							}}
							className="btn btn-sm btn-secondary mt-5 me-2"
						>
							<span className="indicator-label">
								<i className={'fa fa-print pe-0'}></i>&nbsp;Voorbeeld afdruk
							</span>
						</button>
					</>
				)}
			</div>
		</div>
	);
};

export {DymoPrinterDeviceInfo};
