/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {EkoTable, TableHeader} from '../../components/table';
import {formatDate} from '../../components/date';
import {useEkosietFilter} from '../../context/FilterContext';
import {Pagination} from 'react-bootstrap';
import {useListImportsOdata} from '../hooks/use-list-import-odata';
import {ImportListView, ImportStatus} from '../../../modules/api-client/generated';
import {ImportRoutePath} from '../routes/ImportRoutes';
import ImportStatusBadge from './ImportStatusBadge';
import ImportTypeBadge from './ImportTypeBadge';
import {ImportDownloadButton} from './ImportDownloadButton';
import Loading from '../../components/Loading';
import {CustomerRoutePath} from '../../../customer-portal/routing/RoutePath';
import {SmRoutePath} from '../../../backoffice/modules/sm/SmRoutes';
import {useEkosietAuth0} from '../../../modules/auth0/core/useEkosietAuth0';
import {ConfirmDialog} from '../../components/ConfirmDialog';
import {useArchiveImport} from '../hooks/use-archive-import';

const ImportList: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [pageSize] = useState(150);
	const [currentPage, setPage] = useState(1);
	const [importCount, setImportCount] = useState(0);
	const [pageCount, setPageCount] = useState(1);
	const skip = (currentPage - 1) * pageSize;
	const {filterQuery} = useEkosietFilter();
	const {isKlant} = useEkosietAuth0();
	const {isInitialLoading, data: importList, isError} = useListImportsOdata(pageSize, skip, 'ImportDate desc', filterQuery, true);

	useEffect(() => {
		const page = searchParams.get('page');
		const pageParamNumber = parseInt(page || '1', 10); // comes in as a string, convert to int

		if (pageParamNumber > 0 && pageParamNumber <= pageCount) {
			setPage(pageParamNumber);
		}
	}, [pageCount, searchParams]);

	useEffect(() => {
		if (importList && importList['@odata.count']) {
			setImportCount(importList['@odata.count']!);
		}
	}, [importList]);

	useEffect(() => {
		if (importCount > 0) {
			setPageCount(Math.ceil(importCount / pageSize));
		}
	}, [importCount, pageSize]);

	const paginationItems = useMemo(() => {
		let items = [];
		for (let number = 1; number <= pageCount; number++) {
			items.push(
				<Pagination.Item
					key={number}
					active={number === currentPage}
					onClick={() => {
						setSearchParams(`?${new URLSearchParams({page: number.toString()})}`);
					}}
				>
					{number}
				</Pagination.Item>
			);
		}
		return items;
	}, [pageCount, currentPage, setSearchParams]);

	if (isInitialLoading) {
		return <Loading />;
	}

	if (isError) {
		return <>Fout bij het ophalen van de imports!</>;
	}

	return (
		<>
			{(!importList || !importList.value) && <>Geen imports gevonden</>}

			<EkoTable className={'card-xxl-stretch mb-5 mb-xxl-8'}>
				<TableHeader>
					<th className="min-w-100px">Klant</th>
					<th className="min-w-100px">Bestandsnaam</th>
					<th className="min-w-40px text-end">Type</th>
					<th className="min-w-100px text-end">Status</th>
					<th className="min-w-50px text-end">Acties</th>
					<th />
				</TableHeader>
				<tbody>
					{importList &&
						importList.value &&
						importList.value.map((item: ImportListView, index) => (
							<tr key={item.id} className={item.isArchived ? 'text-muted' : ''}>
								<td>
									<div className={item.isArchived ? 'text-muted text-decoration-line-through' : 'text-dark d-block fs-6'}>{item.customerName}</div>
									<div className={'text-muted'}>{item.title}</div>
									<div className={'text-muted'}>{item.importDate ? formatDate(item.importDate, 'dd-MM-yyyy HH:mm:ss') : 'Onbekend'}</div>
									<div className={'text-muted'}> {item.importUser}</div>
								</td>
								<td className={'text-nowrap'}>
									{item.fileName}&nbsp;
									<ImportDownloadButton importId={item.id} />
								</td>
								<td className="text-end">
									<ImportTypeBadge type={item.importType} archived={item.isArchived} />
								</td>
								<td className="text-end">
									<ImportStatusBadge status={item.status} archived={item.isArchived} />
								</td>
								<td className="text-end">
									{item.edsOrderId ? (
										<>
											{isKlant ? (
												<Link
													to={CustomerRoutePath.link(CustomerRoutePath.orderLink(item.edsOrderId.toString()!))}
													relative="path"
													className="btn btn-inline-block btn-primary btn-sm"
												>
													{item.edsOrderCode}
													<i className={'fa fa-caret-right '}></i>
												</Link>
											) : (
												<Link to={SmRoutePath.link(SmRoutePath.orderEditLink(item.edsOrderId.toString()!))} relative="path" className="btn btn-inline-block btn-primary btn-sm">
													{item.edsOrderCode}
													<i className={'fa fa-caret-right '}></i>
												</Link>
											)}
										</>
									) : (
										<Link to={'../' + ImportRoutePath.importLink(item.id)} className="btn btn-sm btn-light me-2" title="Open Groeneveld Order">
											Naar import <i className="la la-eye"></i>
										</Link>
									)}
								</td>
								<td>
									<DeleteButton disabled={item.isArchived} id={item.id} />
								</td>
							</tr>
						))}
				</tbody>
			</EkoTable>

			<div>
				{importList && importList.value && importList.value.length !== undefined && (
					<>
						<span className={`text-muted`}>
							{importList.value.length}/{importList['@odata.count']} imports
						</span>
						<Pagination className="justify-content-start mt-3">{paginationItems}</Pagination>
					</>
				)}
			</div>
		</>
	);
};

const DeleteButton: React.FC<{id: string; disabled: boolean}> = ({id, disabled}) => {
	const openArchiveDialog = useRef<(show: boolean) => void>();

	const archiveMutation = useArchiveImport();
	const archiveCallback = useCallback(async () => {
		await archiveMutation.mutateAsync(id!);
	}, [archiveMutation, id]);
	return (
		<>
			<button type="button" className="btn btn-light btn-sm" disabled={disabled} onClick={() => openArchiveDialog.current?.call(this, true)}>
				<i className={'fas fa-trash'}></i>
			</button>
			<ConfirmDialog show={openArchiveDialog} onConfirm={archiveCallback} dialogStyle="danger" title="Archiveren" message="Wil je doorgaan?" confirmText="Archiveren" />
		</>
	);
};

export {ImportList};
