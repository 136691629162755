import React, {createContext, useCallback} from "react";
import {useQuery} from "@tanstack/react-query";
import {
    AssemblyMethod,
    ConfiguratorData, ConfiguratorMountingOption,
    GetConfiguratorResponse,
    SillConfiguration,
    SillType, ZijlichtConfiguration,
} from "../../../modules/api-client/generated";
import ApiClient from "../../../modules/api-client/ApiClient";
import {AxiosResponse} from "axios";
import {ValidationErrors} from "../../../modules/api-client/Responses";
import {Form, Formik} from "formik";
import Loading from "../Loading";
import Error from "../Error";
import {ConfigurationForm} from "./ConfigurationForm";

export type ExtendedSillConfiguration = SillConfiguration & { fastenersIncluded: boolean | undefined };

export type ConfiguratorContextValue = {
    isLoading: boolean;
    isError: boolean;
    initialValues: SillConfiguration | ZijlichtConfiguration | ExtendedSillConfiguration;
    configuratorData: ConfiguratorData;
    // validateAsync: (sillConfiguration: SillConfiguration) => Promise<ValidationErrors>;
    formikElement: React.ReactNode;
    customerId: string | undefined;
}

export type ConfiguratorContextProps = {
    sillId: string | null;
    configurationId: string | null;
    orderLinedId: string;
    customerId: string | undefined;
    onConfirm: (configuration: SillConfiguration | ZijlichtConfiguration) => Promise<void>;
    children?: ConfiguratorContextChildren;
}

export type ConfiguratorContextChildren =
    React.ReactNode
    | React.ReactNode[]
    | ((options: ChildrenCallbackOptions) => (React.ReactNode | React.ReactNode[]));

export type ChildrenCallbackOptions = {
    isSubmitting: boolean;
    submit: () => Promise<void>;
    formElement: React.ReactNode;
    configuratorData: ConfiguratorData;
};


export const ConfiguratorContext = createContext<ConfiguratorContextValue>(undefined!);

export const ConfiguratorContextProvider: React.FC<ConfiguratorContextProps> = (props) => {

    const value = useConfiguratorContextValue(props);

    const {formikElement} = value;

    return <ConfiguratorContext.Provider value={value}>
        {formikElement}
    </ConfiguratorContext.Provider>
};

export function useConfiguratorContextValue({
                                                sillId,
                                                configurationId,
                                                orderLinedId,
                                                customerId,
                                                onConfirm,
                                                children
                                            }: ConfiguratorContextProps): ConfiguratorContextValue {

    const [state, setState] = React.useState<{
        configuratorData: ConfiguratorData;
        initialValues: SillConfiguration | ZijlichtConfiguration;
    } | undefined>(undefined);

    const { data: fastenersIncluded } = useQuery<boolean, unknown, boolean>(
        ['ApiClient.Pim.Configurator.determineIfFastenersAreIncluded', sillId, configurationId],
        () => ApiClient.Pim.Configurator.determineIfFastenersAreIncluded(orderLinedId, customerId, undefined)
            .then(response => {
                return response;
            })
            .then((response: AxiosResponse<boolean>) => response.data));     

    const {isLoading, isError, isFetching} = useQuery<GetConfiguratorResponse, unknown, GetConfiguratorResponse>(
        ['ApiClient.Pim. this.data.getConfigurator', sillId, configurationId],
        () => ApiClient.Pim.Configurator.getConfigurator(sillId ?? undefined, configurationId ?? undefined, customerId, undefined)
            .then(response => {
                return response;
            })
            .then((response: AxiosResponse<GetConfiguratorResponse>) => response.data),
        {
            enabled: !!sillId || !!configurationId,
            refetchOnWindowFocus: false,
            onSuccess: async (response) => {
                const initialValues = createInitialValues(response.configuratorData, response.configuration);

                let values = initialValues as ExtendedSillConfiguration;
                values.fastenersIncluded = fastenersIncluded

                setState({
                    configuratorData: response.configuratorData,
                    initialValues: values,
                });
            }
        },
    );

    const validateAsync = useCallback(async (configuration: SillConfiguration | ZijlichtConfiguration): Promise<ValidationErrors> => {
        await ApiClient.Pim.Configurator.validateConfiguration(undefined, undefined, undefined, configuration);
        return await new Promise<ValidationErrors>((resolve) => setTimeout(resolve, 200));
    }, []);

    const renderFormikElement = useCallback(() => {
        if (isLoading || isFetching) return <Loading/>
        if (isError) return <Error/>
        if (!state) return <div className='alert alert-danger'>Configurator niet beschikbaar.</div>;

        const {initialValues, configuratorData} = state;

        return <>
            {configuratorData &&

                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validate={validateAsync}
                    onSubmit={values => onConfirm(values)}
                    validateOnChange={false}
                    validateOnBlur={false}>
                    {({submitForm, isSubmitting}) => {

                        const formElement: React.ReactNode = <Form>
                            <ConfigurationForm/>
                        </Form>;

                        if (!children)
                            return formElement;

                        if (typeof children === 'function') {
                            return children({
                                submit: submitForm,
                                isSubmitting: isSubmitting,
                                formElement: formElement,
                                configuratorData: configuratorData
                            });
                        }

                        return children;
                    }}

                </Formik>
            }
        </>
    }, [state]);

    const formikElement = renderFormikElement();

    return {
        isLoading: isLoading || isFetching,
        isError,
        configuratorData: state?.configuratorData!,
        initialValues: state?.initialValues!,
        formikElement,
        customerId
    }
}

function createInitialValues(data: ConfiguratorData, configuration: SillConfiguration | ZijlichtConfiguration | undefined): SillConfiguration | ZijlichtConfiguration {
    let initialValues: SillConfiguration | ZijlichtConfiguration | undefined;

    initialValues = configuration ?? {} as SillConfiguration;

    if (data.type !== SillType.Zijlicht) {

        const c = initialValues as SillConfiguration;

        if (!c.assemblyMethod && data) {
            const preAssembly = data.assemblyOptions[AssemblyMethod.PreAssembly];
            const postAssembly = data.assemblyOptions[AssemblyMethod.PostAssembly];

            const preAssemblyMountingOptions = (preAssembly?.mountingOptions?.length ?? 0) > 0 ? preAssembly?.mountingOptions : undefined;
            const postAssemblyMountingOptions = (postAssembly?.mountingOptions?.length ?? 0) > 0 ? postAssembly?.mountingOptions : undefined;

            let mountingOptions: ConfiguratorMountingOption[] | undefined;

            if (preAssemblyMountingOptions && !postAssemblyMountingOptions) {
                c.assemblyMethod = AssemblyMethod.PreAssembly;
                mountingOptions = preAssembly?.mountingOptions;
            } else if (!preAssemblyMountingOptions && postAssemblyMountingOptions) {
                c.assemblyMethod = AssemblyMethod.PostAssembly;
                mountingOptions = postAssembly?.mountingOptions;
            }

            if (mountingOptions && mountingOptions.length === 1) {
                c.mountingOptionId = mountingOptions[0].id;
            }
        }
    }

    return initialValues;
}