import React from 'react';
import {ImportType} from '../../../modules/api-client/generated';

export interface Props {
	type?: ImportType;
	archived: boolean;
}

const ImportTypeBadge: React.FC<Props> = ({type, archived}) => {
	let label;
	let color;
	switch (type) {
		case ImportType.Groeneveld: {
			label = 'Groeneveld';
			color = 'light';
			break;
		}
		case ImportType.Matrix: {
			label = 'Matrix';
			color = 'primary';
			break;
		}
		case ImportType.Unknown: {
			label = 'Onbekend';
			color = 'danger';
			break;
		}
		default: {
			color = 'light';
			label = 'Onbekend';
		}
	}

	color = archived ? 'light' : color;

	return <span className={`p-2 bg-${color} text-inverse-${color} text-nowrap`}>{label}</span>;
};

export default ImportTypeBadge;
