import {useQuery, useQueryClient} from "@tanstack/react-query";
import {
    CreateMatrixConfigurationLine,
    MatrixConfigurationLineRepresentation, UpdateMatrixConfigurationLine,
} from "../../../../modules/api-client/generated";
import {AxiosError} from "axios";
import ApiClient from "../../../../modules/api-client/ApiClient";
import {toast} from "react-hot-toast";
import {useEkosietMutation} from "../../../hooks/useEkosietMutation";
import {NotFound} from "../../../../modules/api-client/Responses";

export const useGetMatrixConfigurationLines = (enabled: boolean = true) => {
    const {data, error, ...rest} = useQuery<
        Array<MatrixConfigurationLineRepresentation>,
        AxiosError,
        Array<MatrixConfigurationLineRepresentation>
    >(
        ["matrixConfigurationLines"],
        () => ApiClient.Acl.MatrixConfiguration.getMatrixConfigurationLines().then((res) => res.data),
        {
            useErrorBoundary: true,
            enabled,
        }
    );

    if (error) {
        toast.error(error.message);
    }

    return {data, error, ...rest};
};

export const useGetMatrixConfigurationLine = (id: string | undefined, enabled: boolean = true) => {
    const { data, error, ...rest } = useQuery<
        MatrixConfigurationLineRepresentation,
        AxiosError,
        MatrixConfigurationLineRepresentation
    >(
        ["matrixConfigurationLine", id],
        () => ApiClient.Acl.MatrixConfiguration.getMatrixConfigurationLine(id!).then((res) => res.data),
        {
            useErrorBoundary: true,
            enabled: enabled && !!id,
        }
    );

    if (error) {
        toast.error(error.message);
    }

    return { data, error, ...rest };
};

export const useDeleteLine = () => {
    const queryClient = useQueryClient();
    return useEkosietMutation<void, NotFound, string>(
        (id) => {
            return ApiClient.Acl.MatrixConfiguration.deleteMatrixConfigurationLine(id).then((response: any) => response.data);
        },
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries(['matrixConfigurationLines']);
                toast.success(`Configuratie regel verwijderd`);
            },
            onError: () => {
                toast.error('Verwijderen van configuratie regel mislukt');
            },
        }
    );
};

export const useCreateLine = () => {
    const queryClient = useQueryClient();
    return useEkosietMutation<CreateMatrixConfigurationLine, NotFound, MatrixConfigurationLineRepresentation>(
        (newLine) => {
            return ApiClient.Acl.MatrixConfiguration.createMatrixConfigurationLine(undefined, newLine).then((response: any) => response.data);
        },
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries(['matrixConfigurationLines']);
                toast.success(`Configuratie regel aangemaakt`);
            },
            onError: () => {
                toast.error('Aanmaken van configuratie regel mislukt');
            },
        }
    );
};

export const useUpdateLine = () => {
    const queryClient = useQueryClient();
    return useEkosietMutation<UpdateMatrixConfigurationLine, NotFound, MatrixConfigurationLineRepresentation>(
        (updatedLine) => {
            return ApiClient.Acl.MatrixConfiguration.updateMatrixConfigurationLine(undefined, updatedLine).then((response: any) => response.data);
        },
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries(['matrixConfigurationLines']);
                toast.success(`Configuratie regel bijgewerkt`);
            },
            onError: () => {
                toast.error('Bijwerken van configuratie regel mislukt');
            },
        }
    );
};