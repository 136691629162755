import {FC, useCallback} from "react";
import {useZijlichtEditorModal, ZijlichtEditorValue} from "./hooks/use-zijlicht-editor-modal";
import {useFormikContext} from "formik";
import {ZijlichtConfiguration} from "../../../modules/api-client/generated";

export const ConfigurationZijlichtEditButton: FC = () => {

    const {values, setValues} = useFormikContext<ZijlichtConfiguration>();

    const onConfirm = useCallback(async (value: ZijlichtEditorValue) => {

        await setValues((current) => {
            return {...current, length: value.length}
        });
    }, []);


    const {open, formikElement} = useZijlichtEditorModal({
        value: {length: values.length ?? 900},
        onConfirm
    });

    return <>
        <button type='button' className='btn btn-primary mx-1'
                onClick={() => open()}>
            Indeling
        </button>
        {formikElement}
    </>;
};