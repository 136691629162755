import ApiClient from '../../../../../modules/api-client/ApiClient';
import {toast} from "react-hot-toast";
import {useEkosietMutation} from "../../../../../shared/hooks/useEkosietMutation";
import {NotFound, ValidationFailed} from "../../../../../modules/api-client/Responses";

export const usePostProofOfDelivery = () => {

    return useEkosietMutation<void, NotFound | ValidationFailed, ProofOfDeliveryParams>((params) => {
            return ApiClient.Em.Expedition.addProofOfDelivery(params.orderIds, params.signatureFileIds, params.photoFileIds).then();
        },
        {
            onSuccess: (data, variables) => {
                toast.success("Handtekening en foto succesvol opgeslagen");
            },
            onError: () => {
                toast.error("Foto en/of handtekening kon niet worden opgeslagen");
            }
        }
    );
};

type ProofOfDeliveryParams  = {
    orderIds: string[];
    signatureFileIds: string[];
    photoFileIds: string[];
}

type AddSignatureParams  = {
    orderIds: string[];
    fileId: string;
}
type AddPhotosParams  = {
    orderIds: string[];
    fileIds: string[];
}
export const usePostSignature = () => {

    return useEkosietMutation<void, NotFound | ValidationFailed, AddSignatureParams>((params) => {
            return ApiClient.Em.Expedition.addSignature(params.orderIds, params.fileId).then();
        },
        {
            onSuccess: (data, variables) => {
                toast.success("Handtekening opgeslagen");
            },
            onError: () => {
                toast.error("Handtekening kon niet worden opgeslagen");
            }
        }
    );
};

export const usePostPhotos = () => {

    return useEkosietMutation<void, NotFound | ValidationFailed, AddPhotosParams>((params) => {
            return ApiClient.Em.Expedition.addPhotos(params.orderIds,params.fileIds).then();
        },
        {
            onSuccess: (data, variables) => {
                toast.success("Foto(s) opgeslagen");
            },
            onError: () => {
                toast.error("Foto(s) kon(den) niet worden opgeslagen");
            }
        }
    );
};