/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import ApiClient from "../../../../../modules/api-client/ApiClient";
import { AxiosResponse } from "axios";
import { PostPackingSlipsPdf } from '../../../../../modules/api-client/generated';
import Loading from "../../../../../shared/components/Loading";

type Props = {
    orderIds: string[]
    fileName: string
}

const PackingSlipsDocument: FC<Props> = ({ orderIds, fileName }) => {
    const [post, setPost] = React.useState<string>();

    React.useEffect(() => {
        if (!post) {
            var payload: PostPackingSlipsPdf = {
                filename: fileName,
                orderIds: orderIds
            }

            ApiClient.Sm.Print.emExpeditionPrintPdfPackingslipsPost(undefined, payload, { responseType: "blob" })
                .then((d: AxiosResponse<File>) => {
                    const file = new Blob(
                        [d.data],
                        { type: 'application/pdf' });
                    setPost(URL.createObjectURL(file));
                });
        }
    })


    if (!post) return <Loading/>;

    return (
        <>
            <div className={'d-flex justify-content-end mb-3'}>
                <a href={post} className={'btn btn-primary me-3'} target="_blank" rel="noopener">Download</a>
            </div>

            <object width="100%" height="1000" data={post} type="application/pdf">Document</object>
        </>
    )
}

export { PackingSlipsDocument }