import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {toast} from "react-hot-toast";

export interface ImportFileDropzoneProps {
    onSelect: (selected: File) => void;
    disabled?: boolean;
}

const ImportFileDropzone: React.FC<ImportFileDropzoneProps> = ({ onSelect, disabled }) => {
    const [file, setFile] = useState<File | null>(null);
    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
        maxFiles: 1,
        multiple: false,
        accept: {
            'text/plain': ['.csv']
        },
        disabled: disabled,
        onDropAccepted: acceptedFiles => {
            if (acceptedFiles.length > 0) {
                setFile(acceptedFiles[0]);
            }
        },
        onDrop: () => {
        },
        onDropRejected: (fileRejections) => {
            const rejection = fileRejections[0];

            const message = rejection.errors[0].code === 'file-invalid-type'
                ? 'Momenteel worden alleen Groenveld .csv files toegestaan.'
                : rejection.errors[0].message;
toast.error(message);
            console.error(message);
        },
        onError: e => {
            // setScanError(e.message);
        }
    });

    useEffect(() => {
        if (file) {
            onSelect(file)
        }
    }, [file]);

    return (
        <>
            {!disabled &&
                <div {...getRootProps({
                    className: 'dropzone  h-100 d-flex justify-content-center align-items-center'
                })}>
                    <div className="p-5">
                        <input {...getInputProps()} />
                        <i className={'fas fa-file-upload mb-10 '} style={{fontSize: '4rem'}}></i>
                        {acceptedFiles.length === 0 &&
                            <>
                                <div className="h3">
                                    Sleep een bestand of dubbel klik om een bestand te selecteren
                                </div>
                            </>
                        }

                        <h3>
                            {acceptedFiles.map(file => (
                                <span key={file.name}>
                                {file.name} - {file.size} bytes
                            </span>
                            ))}
                        </h3>
                    </div>
                </div>
            }
        </>
    );
};

export default ImportFileDropzone;