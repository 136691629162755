import React from 'react';
import {FieldProps} from 'formik';
import { InsulatedText } from './InsulatedText';

export const InsulatedField: React.FC<React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>> = (props) => {
	props = {...props, value: props.value ?? '', className: 'form-select'};

	return (
		<>
			<select {...props}>
				<option value={''}>
					<InsulatedText value={null} />
				</option>
				<option value={'true'}>
					<InsulatedText value={true} />
				</option>
				<option value={'false'}>
					<InsulatedText value={false} />
				</option>
			</select>
		</>
	);
};

export const InsulatedComponent = (): React.ComponentType<FieldProps<string>> => {
	return ({field, form, ...props}) => {
		return <InsulatedField {...field} {...props} />;
	};
};