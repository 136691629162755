import React from "react";
import {
    ConfiguratorContextChildren,
    ConfiguratorContextProvider
} from "./ConfiguratorContextProvider";
import {SillConfiguration, ZijlichtConfiguration} from "../../../modules/api-client/generated";

export type ConfiguratorComponentProps = {
    sillId: string | null;
    configurationId: string | null;
    orderLineId: string | null;
    customerId: string | undefined;
    onConfirm: (configuration: SillConfiguration | ZijlichtConfiguration) => Promise<void>;
    children?: ConfiguratorContextChildren;
}

export const ConfiguratorComponent: React.FC<ConfiguratorComponentProps> = ({
                                                                                sillId,
                                                                                configurationId,
                                                                                orderLineId,
                                                                                customerId,
                                                                                onConfirm,
                                                                                children
                                                                            }) => {

    return <ConfiguratorContextProvider sillId={sillId}
                                        configurationId={configurationId}
                                        orderLinedId={orderLineId ?? ''}
                                        customerId={customerId}
                                        onConfirm={onConfirm}>{children}</ConfiguratorContextProvider>;
};