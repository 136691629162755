/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {PrinterBrand, usePrinterContext, ZebraPrinterDevice} from '../../../context/PrinterContext';
import clsx from 'clsx';
import {ZebraTestPrintLabel} from '../LabelPrinterTemplates';
import { printerTags } from '../PrinterConstants';

type ZebraPrinterInfoProps = {
	device: ZebraPrinterDevice;
};

const ZebraPrinterDeviceInfo: React.FC<ZebraPrinterInfoProps> = ({device}) => {
	const printerContext = usePrinterContext();
	if (!printerContext) {
		return (
			<span className="alert alert-danger" role="alert">
				Geen printer context
			</span>
		);
	}

	return (
		<div key={`${device.name}_${device.host?.name}_${device.host?.port}`} className={clsx('m-2', 'card', 'border', 'border-primary')}>
			<div className="card-body">
				<div className="row mb-4">
					<div className="col-11">
						<strong>{device.id}</strong>{' '}
						{device === printerContext.manualSelectedPrinter ? (
							<span className="badge badge-success">{device.isSetByUser ? 'Geselecteerd door gebruiker' : 'Geselecteerd door systeem'}</span>
						) : (
							``
						)}
					</div>
					<div className="col-1 text-end">
						<span className="badge badge-primary ms-1">Zebra</span>
					</div>
				</div>
				Naam: {device.name} <br />
				Host: {device.host?.name}
				{device.host?.port ? `:${device.host.port}` : ''} <br />
				{printerTags.has(device.id) ? <>Tags: {printerTags.get(device.id)?.map((tag: string) => <span className="badge badge-secondary ms-1">{tag}</span>)}<br></br></>: '' }
				<button
					onClick={() =>
						printerContext?.setManualPrinter({
							id: device.id,
							name: device.name,
							brand: PrinterBrand.Zebra,
							isSetByUser: true,
						})
					}
					className="btn btn-sm btn-primary mt-5 me-2"
				>
					<span className="indicator-label">
						<i className={'fa fa-check pe-0'}></i>&nbsp;Kies printer
					</span>
				</button>
				<button
					onClick={() => {
						printerContext?.printByFunction(ZebraTestPrintLabel, null, device);
					}}
					className="btn btn-sm btn-secondary mt-5 me-2"
				>
					<span className="indicator-label">
						<i className={'fa fa-print pe-0'}></i>&nbsp;Voorbeeld afdruk
					</span>
				</button>
			</div>
		</div>
	);
};

export {ZebraPrinterDeviceInfo};
