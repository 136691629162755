import React, {useEffect, useState} from 'react';
import {PageTitle} from '../../../../../_metronic/layout/core';
import BarcodeReader from 'react-barcode-reader';
import {useProductionItemGetByBarCode} from '../hooks/use-get-production-item';
import {toast} from 'react-hot-toast';
import {useNavigate, useParams} from 'react-router-dom';
import {PmRoutePath} from '../PmRoutes';
import {ProductionItemAfmelden} from '../components/ProductionItemAfmelden';
import {usePrinterContext} from '../../../../shared/context/PrinterContext';
import {ProductionItemPrinterLabelData} from '../../../../shared/components/printer/LabelPrinterTemplates';
import {PrinterSelectors} from '../../../../shared/components/printer/PrinterConstants';

const AfmeldenLanding: React.FC = () => {
	const {printById, usePrinterDevice} = usePrinterContext();
	const usedPrinterDevice = usePrinterDevice(PrinterSelectors.ZebraExpeditie);
	const {code} = useParams<{code: string | undefined}>();
	const [barCode, setBarCode] = useState<string | undefined>(code);
	const {data: pi, isError} = useProductionItemGetByBarCode(barCode);
	const nagivate = useNavigate();

	const handleScan = async (code: string) => {
		var reg = /^\d+$/;
		if (!reg.test(code) || code.length !== 9) {
			handleScanError('Scan een EDS barcode!');
			return;
		}
		if (code.length === 9) {
			handleEdsBarCodeScan(code);
			return;
		}
		handleScanError('Scan een EDS barcode!');
		return;
	};

	const handleEdsBarCodeScan = async (code: string) => {
		setBarCode(code);
	};

	const handleScanError = (err: string) => {
		toast.error(`Scan error: '${err}'`, {
			duration: 3000,
		});
	};

	useEffect(() => {
		if (pi && usedPrinterDevice) {
			const data: ProductionItemPrinterLabelData = {
				pi: pi,
				plannedExpeditionDate: null,
			};

			printById('productionitem', data, usedPrinterDevice);

			nagivate(PmRoutePath.link(PmRoutePath.afmeldenLink(pi.id)));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pi, usedPrinterDevice]);

	return (
		<>
			<PageTitle>Dorpel afmelden</PageTitle>

			<BarcodeReader onError={handleScanError} onScan={handleScan} />
			{!pi && (
				<>
					Scan een EDS barcode om een dorpel af te melden. <br />
					<br />
				</>
			)}
			{isError && <>Fout bij het ophalen van gegevens voor barcode {barCode}</>}
			<ProductionItemAfmelden scope={[`Status:Started`]} />
		</>
	);
};

export default AfmeldenLanding;
