/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactElement} from 'react'
import {OrderStatusCodes} from "../../../../../../modules/api-client/generated";
import {CopyOrderForm} from "./forms/CopyOrderForm";
import {OrderDocument} from "../../components/OrderDocument";
import ApiClient from "../../../../../../modules/api-client/ApiClient";
import {toast} from "react-hot-toast";
import {useEkosietAuth0} from "../../../../../../modules/auth0/core/useEkosietAuth0";
import {DownloadBmhButton} from "../../components/DownloadBmhButton";

type Props = {
    orderId?: string
    status?: string
    delivered: boolean
    orderContact?: string
    orderNbr?: string
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
}

const EditOrderToolbar: React.FC<Props> = ({
                                               setShowEditModal,
                                               setModalTitle,
                                               setModalComponent,
                                               orderId,
                                               orderContact,
                                               orderNbr,
                                               status,
                                               delivered
                                           }) => {

    const {isMedewerker} = useEkosietAuth0();

    if (!orderId) return (<></>)

    var copyOrder = () => {
        setModalComponent(() => <CopyOrderForm handleClose={() => setShowEditModal(false)} id={orderId}/>)
        setModalTitle('Kopieer order')
        setShowEditModal(true)
    }

    var sendEmail = (type: string) => {
        if (type === "confirmation") {
            ApiClient.Sm.Email.smOrderIdEmailOrderConfirmationPost(orderId, undefined)
                .then((d) => {
                    toast.success("Email verstuurd.");
                });
        } else {
            ApiClient.Sm.Email.smOrderIdEmailQuoteIssuedPost(orderId, undefined)
                .then((d) => {
                    toast.success("Email verstuurd.");
                });
        }
    }

    var openDocument = (type: string) => {
        setModalComponent(() => <OrderDocument handleClose={() => setShowEditModal(false)} id={orderId}
                                               orderNbr={orderNbr} type={type}/>)
        setModalTitle('Document')
        setShowEditModal(true)
    }

    return (
        <>
            <div className={'d-flex align-items-center'}>
                <div className="dropdown me-3">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                        Email
                    </button>
                    <ul className="dropdown-menu">
                        {status !== OrderStatusCodes.Draft && status !== OrderStatusCodes.Cancelled && status !== OrderStatusCodes.QuoteIssued ?
                            <li><a className="dropdown-item btn" href='#' onClick={() => sendEmail('confirmation')}>Verstuur
                                orderbevestiging <br/>
                                <span className={'text-muted'}>{orderContact}</span></a></li>
                            :
                            <li><a className="dropdown-item btn disabled" href='#'
                                   onClick={() => sendEmail('confirmation')}>Verstuur orderbevestiging</a></li>
                        }
                        {status === OrderStatusCodes.QuoteIssued ?
                            <li><a className="dropdown-item btn" href='#' onClick={() => sendEmail('quote')}>Verstuur
                                offerte<br/>
                                <span className={'text-muted'}>{orderContact}</span></a></li>
                            :
                            <li><a className="dropdown-item btn disabled" href="#" onClick={() => sendEmail('quote')}>Verstuur
                                Offerte</a></li>

                        }
                    </ul>
                </div>
                <div className="dropdown me-3">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                        Documenten
                    </button>
                    <ul className="dropdown-menu">
                        {status !== OrderStatusCodes.Draft && status !== OrderStatusCodes.Cancelled && status !== OrderStatusCodes.QuoteIssued ?
                            <>
                            <li><a className="dropdown-item btn" href='#'
                                   onClick={() => openDocument('confirmation')}>Orderbevestiging</a></li>
                            {(delivered || isMedewerker) &&
                            <li><a className="dropdown-item btn" href='#'
                                   onClick={() => openDocument('packingslip')}>Pakbon</a></li>
                            }

                            </>
                            :
                            <li><a className="dropdown-item btn disabled" href='#'
                                   onClick={() => openDocument('confirmation')}>Orderbevestiging</a></li>
                        }
                        {status === OrderStatusCodes.QuoteIssued ?
                            <li><a className="dropdown-item btn" href='#'
                                   onClick={() => openDocument('quote')}>Offerte</a></li>
                            :
                            <li><a className="dropdown-item btn disabled" href="#"
                                   onClick={() => openDocument('quote')}>Offerte</a></li>
                        }




                    </ul>
                </div>
                {(status === OrderStatusCodes.Accepted || status === OrderStatusCodes.Completed) &&
                    <DownloadBmhButton title='Download BMH XML' filename={`${orderNbr}-bmh.xml`} orderid={orderId} />
                }
                <a href='#' className='btn fw-bold btn-secondary' onClick={() => copyOrder()}>
                    <i className="fas fa-copy"></i> Order kopieren
                </a>
            </div>
        </>
    )
}

export {EditOrderToolbar}
