import {SillDetails, UpdateSillVariantsRequest} from '../../../../../modules/api-client/generated';
import {EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar} from '../../../../../shared/components/card';
import React, {FC, useState} from 'react';
import {useSillUpdateVariantsMutation} from '../hooks/use-sill-update-varaints-mutation';
import {EkoFormModal} from '../../../../../shared/components';
import EkoFormField from '../../../../../shared/components/EkoFormField';
import {FieldProps} from 'formik/dist/Field';
import {Link} from 'react-router-dom';
import {PimRoutePath} from '../../PimRoutes';
import {SillProductsPicker} from '../../catalogitem/CatalogItemPicker';

export type SillVariantsCardProps = {
	sill: SillDetails;
	onChanged: () => Promise<void> | void;
};

export const SillVariantsCard: FC<SillVariantsCardProps> = ({sill}) => {
	const [isEditModalOpen, setEditModalOpen] = useState(false);

	const updateMutation = useSillUpdateVariantsMutation(sill.id);

	const values: UpdateSillVariantsRequest = {
		uninsulatedCatalogItem: sill.catalogItemUninsulated?.id,
		insulatedCatalogItem: sill.catalogItemInsulated?.id,
	};

	return (
		<>
			<EkoCard className={'mb-3'}>
				<EkoCardHeader title={'Artikel varianten'}>
					<EkoCardToolbar>
						<button type="button" className="btn btn-primary" onClick={() => setEditModalOpen(true)}>
							Wijzigen
						</button>
					</EkoCardToolbar>
				</EkoCardHeader>
				<EkoCardBody>
					<div className="row mb-4">
						<div className="col-xxl-2 col-6">
							<label className="form-label">Ongeïsoleerd</label>
						</div>
						<div className="col">
							{(sill.catalogItemUninsulated && (
								<Link to={PimRoutePath.path(PimRoutePath.catalogEdit, {id: sill.catalogItemUninsulated.id})}>{sill.catalogItemUninsulated.title}</Link>
							)) || <span className="text-muted">geen</span>}
						</div>
					</div>

					<div className="row mb-4">
						<div className="col-xxl-2 col-6">
							<label className="form-label">Geïsoleerd</label>
						</div>
						<div className="col">
							{(sill.catalogItemInsulated && <Link to={PimRoutePath.path(PimRoutePath.catalogEdit, {id: sill.catalogItemInsulated.id})}>{sill.catalogItemInsulated.title}</Link>) || (
								<span className="text-muted">geen</span>
							)}
						</div>
					</div>
				</EkoCardBody>
			</EkoCard>

			<EkoFormModal
				title="Artikel variant wijzigen"
				values={values}
				onConfirm={async (update) => await updateMutation.mutateAsync(update)}
				isOpen={isEditModalOpen}
				onClose={() => setEditModalOpen(false)}
				modal={{size: 'lg'}}
			>
				<SillVariantsEditForm />
			</EkoFormModal>
		</>
	);
};

const SillVariantsEditForm: FC = () => {
	return (
		<>
			<EkoFormField
				label="Ongeïsoleerd"
				row={true}
				field={{
					name: 'uninsulatedCatalogItem',
					children: (props: FieldProps<string>) => <SillProductsPicker {...props.field} />,
				}}
			/>

			<EkoFormField
				label="Geïsoleerd"
				row={true}
				field={{
					name: 'insulatedCatalogItem',
					children: (props: FieldProps<string>) => <SillProductsPicker {...props.field} />,
				}}
			/>
		</>
	);
};

export type CatalogItemPickerProps = React.InputHTMLAttributes<HTMLInputElement>;

export const CatalogItemPicker: FC<CatalogItemPickerProps> = (props) => {
	return (
		<div className="input-group">
			<input {...props} type="text" className="form-control" />
			<button type="button" className="btn">
				...
			</button>
		</div>
	);
};

// export const CatalogItemPickerDialog: FC<CatalogItemPickerProps> = (props) => {
// 	return (
// 		<EkoFormModal
// 			title="Artikel variant wijzigen"
// 			values={values}
// 			onSubmit={async (update) => await updateMutation.mutateAsync(update)}
// 			isOpen={isEditModalOpen}
// 			onClose={() => setEditModalOpen(false)}
// 		>
// 			<SillVariantsEditForm />
// 		</EkoFormModal>
// 	);
// };
