import {useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-hot-toast';
import ApiClient from '../../../../../modules/api-client/ApiClient';
import {PutPlannedExpeditionDateRepresentation, PutPlannedProductionDateRepresentation} from '../../../../../modules/api-client/generated';
import {useEkosietMutation} from '../../../../../shared/hooks/useEkosietMutation';
import {NotFound, ValidationFailed} from '../../../../../modules/api-client/Responses';

export const usePutPlannedProductionAndPlannedExpeditionDate = (id: string) => {
	const queryClient = useQueryClient();

	return useEkosietMutation<void, NotFound | ValidationFailed, {production: PutPlannedProductionDateRepresentation; expedition: PutPlannedExpeditionDateRepresentation}>(
		async ({production, expedition}) => {
			await ApiClient.Sm.Order.smOrderIdProductionPlannedProductionDatePut(id, undefined, production);
			await ApiClient.Em.Expedition.emExpeditionOrderIdPlannedExpeditionDatePut(id, undefined, expedition);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['smOrderIdGet', id]);
				queryClient.invalidateQueries(['OrderActionLog', id]);
				queryClient.invalidateQueries(['smOrderListOdataGet']);

				queryClient.invalidateQueries(['emExpeditionOrderIdGet', id]);
				queryClient.invalidateQueries(['emExpeditionListOdataGet']);
				queryClient.invalidateQueries(['emExpeditionTransportPlanningByWeekGet']);
				queryClient.invalidateQueries(['emExpeditionTransportPlanningByOrderGet']);
			},
			onError: () => {
				toast.error('Geplande productie- en/of expeditiedatum wijzigen mislukt!');
			},
		}
	);
};
