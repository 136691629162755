import {EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar} from '../../../../../shared/components/card';
import {SillAerationAndDrainageOptions, SillDetails, UpsertAerationAndDrainageOptionsRequest} from '../../../../../modules/api-client/generated/api';
import React, {FC, useEffect, useState} from 'react';
import {EkoFormModal} from '../../../../../shared/components';
import EkoFormField from '../../../../../shared/components/EkoFormField';
import {ErrorMessage, FieldProps} from 'formik';
import {useEkosietMutation} from '../../../../../shared/hooks/useEkosietMutation';
import {NotFound, ValidationFailed} from '../../../../../modules/api-client/Responses';
import ApiClient from '../../../../../modules/api-client/ApiClient';
import {useQueryClient} from '@tanstack/react-query';
import * as Yup from 'yup';

export interface HoleSettingsProps {
	sillId: string;
	options: SillAerationAndDrainageOptions | null;
}

export const SillAerationAndDrainageCard: React.FC<HoleSettingsProps> = ({options, sillId}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [switchEnabled, setSwitchEnabled] = useState(!!options);
	const [editButtonEnabled, setEditButtonEnabled] = useState(!!options);
	const [showSpinner, setShowSpinner] = useState(false);
	const [aerationAndDrainageOptions, setAerationAndDrainageOptions] = useState<UpsertAerationAndDrainageOptionsRequest | null>();
	const queryClient = useQueryClient();

	const validationSchema = Yup.object().shape({
		holeTop: Yup.object()
			.nullable()
			.shape({
				y: Yup.number().nullable(),
				depth: Yup.number().nullable(),
				diameter: Yup.number().nullable(),
			})
			.test('all-or-none', "Alle velden van 'Gat bovenkant' moeten ingevuld of leeg zijn", (value) => {
				if (!value) return true; // Geen waarde betekent geen foutmelding

				// Tel het aantal ingevulde velden
				const filledFields = ['y', 'depth', 'diameter'].filter((key) => value[key] !== null && value[key] !== undefined).length;

				// Geen foutmelding als:
				// - Alle velden leeg zijn (filledFields === 0)
				// - Alle velden ingevuld zijn (filledFields === 3)
				return filledFields === 0 || filledFields === 3;
			}),
	});

	useEffect(() => {
		setAerationAndDrainageOptions(options);
	}, [aerationAndDrainageOptions, options]);

	const handleSwitch = () => {
		if (!switchEnabled) {
			setSwitchEnabled(true);
			setIsModalOpen(true);
			setEditButtonEnabled(true);
		} else {
			setShowSpinner(true);
			setSwitchEnabled(false);
			setEditButtonEnabled(false);
			updateMutation.mutate(null!);
		}
	};

	const updateMutation = useEkosietMutation<void, NotFound | ValidationFailed, UpsertAerationAndDrainageOptionsRequest>(
		async (body?: UpsertAerationAndDrainageOptionsRequest) => {
			console.log('Request body:', body);
			await ApiClient.Pim.Sill.putAerationAndDrainageOptions(sillId, undefined, body);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['ApiClient.Pim.Sill.getSill', sillId]).then();
				setShowSpinner(false);
			},
		}
	);

	const handleModalCancel = () => {
		setIsModalOpen(false);

		if (!aerationAndDrainageOptions) {
			setEditButtonEnabled(false);
			setSwitchEnabled(false);
		}
	};

	const initialValues: UpsertAerationAndDrainageOptionsRequest = {
		maxSpacing: 500,
		holeTop: {
			y: 56.5,
			depth: 32,
			diameter: 8,
		},
		holeFront: {
			y: 9,
			depth: 22,
			diameter: 8,
		},
	};

	return (
		<>
			<EkoCard className={'mb-3'}>
				<EkoCardHeader title={'Afwaterings/ontluchtingsgaten'}>
					<EkoCardToolbar>
						<button type="button" className="btn btn-primary" disabled={!editButtonEnabled} onClick={() => setIsModalOpen(true)}>
							Wijzigen
						</button>
					</EkoCardToolbar>
				</EkoCardHeader>
				{options !== null ? (
					<EkoCardBody>
						<div className="row mb-4">
							<div className="col-3">
								<label className="form-label">Afwaterings/ontluchtingsgaten aanwezig?</label>
							</div>
							<div className="col form-check form-switch ms-2">
								<input className="form-check-input" type="checkbox" role="switch" checked={switchEnabled} onChange={handleSwitch} />
								{showSpinner ? <div className="ms-2 spinner-border text-primary" role="status" /> : null}
							</div>
						</div>
						<div className="row mb-4">
							<div className="col-3">Maximale tussenafstand</div>
							<div className="col">{(options.maxSpacing && <>{options.maxSpacing}mm</>) || <>-</>}</div>
						</div>

						<strong>Gat bovenkant</strong>
						<div className="row mb-4">
							<div className="col-3">
								<label className="form-label">Y-positie</label>
							</div>
							<div className="col">{(options.holeTop?.y && <>{options.holeTop.y}mm</>) || <>-</>}</div>
						</div>
						<div className="row mb-4">
							<div className="col-3">
								<label className="form-label">Diepte</label>
							</div>
							<div className="col">{(options.holeTop?.depth && <>{options.holeTop.depth}mm</>) || <>-</>}</div>
						</div>
						<div className="row mb-4">
							<div className="col-3">
								<label className="form-label">Diameter</label>
							</div>
							<div className="col">{(options.holeTop?.diameter && <>{options.holeTop.diameter}mm</>) || <>-</>}</div>
						</div>

						<strong>Gat buitenkant</strong>
						<div className="row mb-4">
							<div className="col-3">
								<label className="form-label">Y-positie</label>
							</div>
							<div className="col">{(options.holeFront?.y && <>{options.holeFront.y}mm</>) || <>-</>}</div>
						</div>
						<div className="row mb-4">
							<div className="col-3">
								<label className="form-label">Diepte</label>
							</div>
							<div className="col">{(options.holeFront?.depth && <>{options.holeFront.depth}mm</>) || <>-</>}</div>
						</div>
						<div className="row mb-4">
							<div className="col-3">
								<label className="form-label">Diameter</label>
							</div>
							<div className="col">{(options.holeFront?.diameter && <>{options.holeFront.diameter}mm</>) || <>-</>}</div>
						</div>
					</EkoCardBody>
				) : (
					<EkoCardBody>
						<div className="row mb-4">
							<div className="col-3">
								<label className="form-label">Afwaterings/ontluchtingsgaten aanwezig?</label>
							</div>
							<div className="col form-check form-switch ms-2">
								<input className="form-check-input" type="checkbox" role="switch" checked={switchEnabled} onChange={handleSwitch} />
							</div>
						</div>
					</EkoCardBody>
				)}
			</EkoCard>

			<EkoFormModal
				title="Afwaterings/ontluchtingsgaten wijzigen"
				values={aerationAndDrainageOptions || initialValues}
				onConfirm={async (update) => {
					let holeTop =
						update.holeTop === null ||
						update.holeTop?.y === '' ||
						update.holeTop.y === undefined ||
						update.holeTop.depth === '' ||
						update.holeTop.depth === undefined ||
						update.holeTop.diameter === '' ||
						update.holeTop.diameter === undefined
							? null
							: update.holeTop;

					await updateMutation.mutateAsync({
						maxSpacing: update.maxSpacing,
						holeTop: holeTop,
						holeFront: {
							y: update.holeFront.y,
							depth: update.holeFront.depth,
							diameter: update.holeFront.diameter,
						},
					});
				}}
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				onCancel={async () => {
					handleModalCancel();
				}}
				modal={{size: 'lg'}}
				formikConfig={{validationSchema}}
			>
				<OptionsEditForm />
			</EkoFormModal>
		</>
	);
};

const OptionsEditForm: FC = () => {
	return (
		<>
			<EkoFormField
				label="Maximale tussenafstand"
				row={true}
				field={{
					name: 'maxSpacing',
					children: (props: FieldProps<number>) => (
						<>
							<div className="input-group">
								<input {...props.field} type="number" className="form-control" placeholder="Lengte" required min={1} /> <span className="input-group-text">mm</span>
							</div>
						</>
					),
				}}
			/>

			<strong>Gat bovenkant</strong>
			<ErrorMessage name="holeTop" component="div" className="text-danger" />
			<EkoFormField
				label="Y-positie"
				row={true}
				field={{
					name: 'holeTop.y',
					children: (props: FieldProps<number>) => (
						<>
							<div className="input-group">
								<input {...props.field} type="number" className="form-control" placeholder="Y-positie" min={1} /> <span className="input-group-text">mm</span>
							</div>
						</>
					),
				}}
			/>

			<EkoFormField
				label="Diepte"
				row={true}
				field={{
					name: 'holeTop.depth',
					children: (props: FieldProps<number>) => (
						<>
							<div className="input-group">
								<input {...props.field} type="number" className="form-control" placeholder="Diepte" min={1} /> <span className="input-group-text">mm</span>
							</div>
						</>
					),
				}}
			/>

			<EkoFormField
				label="Diameter"
				row={true}
				field={{
					name: 'holeTop.diameter',
					children: (props: FieldProps<number>) => (
						<>
							<div className="input-group">
								<input {...props.field} type="number" className="form-control" placeholder="Diameter" min={1} /> <span className="input-group-text">mm</span>
							</div>
						</>
					),
				}}
			/>

			<strong>Gat buitenkant</strong>
			<EkoFormField
				label="Y-positie"
				row={true}
				field={{
					name: 'holeFront.y',
					required: true,
					children: (props: FieldProps<number>) => (
						<>
							<div className="input-group">
								<input {...props.field} type="number" className="form-control" placeholder="Y-positie" required min={1} /> <span className="input-group-text">mm</span>
							</div>
						</>
					),
				}}
			/>

			<EkoFormField
				label="Diepte"
				row={true}
				field={{
					name: 'holeFront.depth',
					required: true,
					children: (props: FieldProps<number>) => (
						<>
							<div className="input-group">
								<input {...props.field} type="number" className="form-control" placeholder="Diepte" required min={1} /> <span className="input-group-text">mm</span>
							</div>
						</>
					),
				}}
			/>

			<EkoFormField
				label="Diameter"
				row={true}
				field={{
					name: 'holeFront.diameter',
					required: true,
					children: (props: FieldProps<number>) => (
						<>
							<div className="input-group">
								<input {...props.field} type="number" className="form-control" placeholder="Diameter" required min={1} /> <span className="input-group-text">mm</span>
							</div>
						</>
					),
				}}
			/>
		</>
	);
};
