/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {usePrinterContext} from '../../../context/PrinterContext';
import clsx from 'clsx';
import Select from 'react-select';
import {PrinterProtocolOptions} from '../PrinterConstants';

type DymoPrinterInfoProps = {
	setPrinterHostEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

const DymoPrinterSettings: React.FC<DymoPrinterInfoProps> = ({setPrinterHostEdit}) => {
	const printerContext = usePrinterContext();
	const [dymoPrinterHostName, setDymoPrinterHostName] = useState<string | null | undefined>(printerContext?.dymoPrinterHost?.name);
	const [dymoPrinterHostPort, setDymoPrinterHostPort] = useState<string | null | undefined>(printerContext?.dymoPrinterHost?.port);
	const [dymoPrinterHostProtocol, setDymoPrinterHostProtocol] = useState<'http' | 'https' | null | undefined>(printerContext?.dymoPrinterHost?.protocol);

	let printerProtocolSelectedValue = PrinterProtocolOptions.find((option: any) => option.value === dymoPrinterHostProtocol) || PrinterProtocolOptions[0];

	useEffect(() => {
		if (printerContext?.dymoPrinterHost && printerContext?.dymoPrinterHost?.name) {
			setDymoPrinterHostName(printerContext?.dymoPrinterHost.name);
			setDymoPrinterHostPort(printerContext?.dymoPrinterHost.port);
			setDymoPrinterHostProtocol(printerContext?.dymoPrinterHost.protocol || 'http');
		} else {
			setDymoPrinterHostProtocol(null);
			setDymoPrinterHostName(null);
			setDymoPrinterHostPort(null);
		}
	}, [printerContext?.dymoPrinterHost]);

	if (!printerContext) {
		return (
			<span className="alert alert-danger" role="alert">
				Geen printer context
			</span>
		);
	}

	return (
		<div className="mt-5">
			<label className="form-label" htmlFor="cboPrinterHostProtocol">
				Protocol
			</label>

			<Select
				className={clsx('w-100')}
				styles={{
					// Fixes the overlapping problem of the component
					menu: (provided) => ({...provided, zIndex: 9999}),
				}}
				classNamePrefix="select"
				defaultValue={printerProtocolSelectedValue}
				value={printerProtocolSelectedValue}
				isClearable={false}
				isSearchable={false}
				name={'cboPrinterHostProtocol'}
				options={PrinterProtocolOptions}
				onChange={(newValue) => {
					setDymoPrinterHostProtocol(newValue?.value);
				}}
			/>

			<label className="form-label" htmlFor="txtPrinterHost">
				Host
			</label>

			<input className="form-control" type="text" name="txtPrinterHost" value={dymoPrinterHostName || ''} onChange={(e) => setDymoPrinterHostName(e.target.value)} />

			<label className="form-label" htmlFor="txtPrinterPort">
				Port
			</label>

			<input className="form-control" type="number" name="txtPrinterPort" value={dymoPrinterHostPort || ''} onChange={(e) => setDymoPrinterHostPort(e.target.value)} />

			<button
				type="submit"
				className="btn btn-primary mt-5"
				onClick={(event) => {
					event.preventDefault();
					if (dymoPrinterHostName) {
						printerContext?.setDymoPrinterHost({
							name: dymoPrinterHostName,
							port: dymoPrinterHostPort || '',
							protocol: dymoPrinterHostProtocol || 'http',
						});
					}

					setPrinterHostEdit(false);
				}}
			>
				<span>Opslaan</span>
			</button>

			<button
				type="submit"
				className="btn btn-secondary mt-5 ms-5"
				onClick={(event) => {
					event.preventDefault();

					setDymoPrinterHostName('localhost');
					setDymoPrinterHostPort('41951');
					setDymoPrinterHostProtocol('https');

					printerContext?.setDymoPrinterHost({
						name: 'localhost',
						port: '41951',
						protocol: 'https',
					});

					setPrinterHostEdit(false);
				}}
			>
				<span>https:Lokaal:41951</span>
			</button>

			<button
				type="submit"
				className="btn btn-secondary mt-5 ms-5"
				onClick={(event) => {
					event.preventDefault();

					setDymoPrinterHostName('localhost');
					setDymoPrinterHostPort('444');
					setDymoPrinterHostProtocol('https');

					printerContext?.setDymoPrinterHost({
						name: 'localhost',
						port: '444',
						protocol: 'https',
					});

					setPrinterHostEdit(false);
				}}
			>
				<span>https:Lokaal:444</span>
			</button>

			<button
				type="submit"
				className="btn btn-secondary mt-5 ms-5"
				onClick={(event) => {
					event.preventDefault();

					setDymoPrinterHostName('localhost');
					setDymoPrinterHostPort('8080');
					setDymoPrinterHostProtocol('http');

					printerContext?.setDymoPrinterHost({
						name: 'localhost',
						port: '8080',
						protocol: 'http',
					});

					setPrinterHostEdit(false);
				}}
			>
				<span>http:Lokaal:8080</span>
			</button>
		</div>
	);
};

export {DymoPrinterSettings};
