import React, {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useDymoFetchPrinters} from '../utils/react-dymo';
import {useLocalStorage} from 'react-use';
import {PrintersModal} from '../components/printer/PrintersModal';
import {localStore} from '../utils/react-dymo/storage';
import {PrinterLabel, PrinterLabelId, PrinterMode, PrinterNames, printLabelMap} from '../components/printer/PrinterConstants';
import axios, {AxiosResponse} from 'axios';
import {WS_SVC_PATH} from '../utils/react-dymo/constants';
import toast from 'react-hot-toast';
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper-extended';
import Timeout from 'await-timeout';
import {Device} from 'zebra-browser-print-wrapper-extended/lib/types';
import {ZEBRA_SVC_HOST, ZEBRA_SVC_HOST_PORT, ZEBRA_SVC_HOST_PROTOCOL} from '../components/printer/zebra/ZebraConstants';

const defaultZebraPrinterHost: PrinterHost | null = {
	name: ZEBRA_SVC_HOST,
	port: ZEBRA_SVC_HOST_PORT,
	protocol: ZEBRA_SVC_HOST_PROTOCOL || 'http',
};

export type PrinterHost = {
	protocol: 'http' | 'https';
	name: string;
	port: string;
};

export enum PrinterBrand {
	Dymo,
	Zebra,
}

export type PrinterSelector = {
	id: string;
	brand: PrinterBrand;
};

export type ZebraPrinterDevice = {} & PrinterDevice & Device;

export type DymoPrinterDevice = {
	model?: string;
	isLocal?: boolean;
	isConnected?: boolean;
} & PrinterDevice;

export type PrinterDevice = {
	id: string;
	name: string;
	brand: PrinterBrand;
	isSetByUser: boolean;
	host?: PrinterHost;
};

export type PrinterContextProviderProps = {
	children: React.ReactNode;
};

export interface PrinterContextStore {
	mode: string;
	setMode: React.Dispatch<React.SetStateAction<string>>;
	status: string | undefined | null;
	dymoPrinterHost: PrinterHost | null;
	setDymoPrinterHost: (value: PrinterHost) => void;
	zebraPrinterHost: PrinterHost | null;
	setZebraPrinterHost: (value: PrinterHost) => void;
	printers: Array<PrinterDevice> | undefined | null;
	hasPrinter: (selector: PrinterSelector) => boolean;
	hasPrinterDevice: (device: PrinterDevice) => boolean;
	getPrinter: (selector: PrinterSelector) => PrinterDevice | undefined;
	getPrinters: () => void;
	setManualPrinter: (printerDevice: PrinterDevice | null | undefined) => void;
	manualSelectedPrinter: PrinterDevice | null | undefined;
	showPrinterModal: () => void;
	resetContext: () => void;
	dymoPrint: (xml: string, device: PrinterDevice | undefined | null) => Promise<boolean>;
	zebraPrint: (zpl: string, device: PrinterDevice | undefined | null) => Promise<boolean>;
	print: (label: string, device: PrinterDevice | undefined | null) => Promise<boolean>;
	printById: (labelId: PrinterLabelId, data: any, device: PrinterDevice | undefined | null) => Promise<boolean>;
	printByLabel: (label: PrinterLabel, data: any, device: PrinterDevice | undefined | null) => Promise<boolean>;
	printByFunction: (label: (data: any) => string, data: any, device: PrinterDevice | undefined | null) => Promise<boolean>;
	usePrinterDevice: (selector: PrinterSelector) => PrinterDevice | null | undefined;
}

export const PrinterContext = React.createContext({} as PrinterContextStore);

const dymoPostPrintLabel = (printerDevice: PrinterDevice, xml: string): Promise<AxiosResponse<any, any>> => {
	const url = `${printerDevice.host?.protocol || 'https'}://${printerDevice.host?.name}:${printerDevice.host?.port}/${WS_SVC_PATH}/PrintLabel`;

	var response = axios.post(url, `printerName=${encodeURIComponent(printerDevice.name)}&printParamsXml=&labelXml=${encodeURIComponent(xml)}`);

	return response;
};

const usePrinterDevice = (selector: PrinterSelector) => {
	const {printers, manualSelectedPrinter, mode, getPrinter} = usePrinterContext();
	const [usedPrinterDevice, setUsedPrinterDevice] = useState<PrinterDevice | null | undefined>(null);

	useEffect(() => {
		if (printers) {
			if (mode === PrinterMode.Manual && manualSelectedPrinter) {
				setUsedPrinterDevice(manualSelectedPrinter);
			} else {
				const printerDevice = getPrinter(selector);
				if (printerDevice) {
					setUsedPrinterDevice(printerDevice);
				}
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [printers, mode, manualSelectedPrinter, getPrinter]);

	return usedPrinterDevice;
};

export const usePrinterContext = () => {
	const context = useContext(PrinterContext);

	if (context === undefined) {
		console.warn('useEkosietPrinter must be used within a PrinterContextProvider');
	}

	return context;
};

export const PrinterContextProvider: FC<PrinterContextProviderProps> = ({children}) => {
	const [showPrinterModalflag, setShowPrinterModalFlag] = useState<boolean>(false);
	const [isInitialized, setIsInitialized] = useState<boolean>(false);
	const [savedSelectedPrinter, saveSelectedPrinter] = useLocalStorage<PrinterDevice | null>('ekosiet.printer.selected', null);
	const [savedZebraPrinterHost, saveZebraPrinterHost] = useLocalStorage<PrinterHost | null>('ekosiet.printer.zebra.printer.host', null);
	const [printerDevices, setPrinterDevices] = useState<Array<PrinterDevice> | null | undefined>(null);
	const [dymoPrinterDevices, setDymoPrinterDevices] = useState<Array<DymoPrinterDevice> | null | undefined>(null);
	const [zebraPrinterDevices, setZebraPrinterDevices] = useState<Array<ZebraPrinterDevice> | null | undefined>(null);
	const [manualSelectedPrinterDevice, setManualSelectedPrinterDevice] = useState<PrinterDevice | null | undefined>(null);
	const [status, setStatus] = useState<string | null | undefined>(null);
	const [dymoPrinterHost, setDymoPrinterHost] = useState<PrinterHost | null>(null);
	const [zebraPrinterHost, setZebraPrinterHost] = useState<PrinterHost>(savedZebraPrinterHost || defaultZebraPrinterHost);
	const [zebraBrowserPrint, setZebraBrowserPrint] = useState<ZebraBrowserPrintWrapper>(
		new ZebraBrowserPrintWrapper(`${zebraPrinterHost?.protocol || 'http'}://${zebraPrinterHost?.name}:${zebraPrinterHost?.port}/`)
	);
	const [isZebraError, setIsZebraError] = useState<boolean>(false);
	const [isZebraFetchingPrinters, setIsZebraFetchingPrinters] = useState<boolean>(false);
	const [isResetting, setIsResetting] = useState<boolean>(false);
	const [savedMode, saveMode] = useLocalStorage<string | null>('ekosiet.printer.mode', null);
	const [mode, setMode] = useState<string>(savedMode || PrinterMode.Automatic);
	const prevModeRef = useRef<string>(mode);
	const prevZebraPrinterHostRef = useRef<PrinterHost | null>(zebraPrinterHost);

	const {
		statusFetchPrinters: dymoStatusFetchPrinters,
		printers: dymoPrinters,
		getPrinters: getDymoPrinters,
		host: dymoHost,
		port: dymoPort,
		protocol: dymoProtocol,
	} = useDymoFetchPrinters('success');

	useEffect(() => {
		console.log('zebra browser print changed');
		getZebraPrinters();
	}, [zebraBrowserPrint]);

	useEffect(() => {
		console.log('setting printer devices');
		setPrinterDevices([...(zebraPrinterDevices || []), ...(dymoPrinterDevices || [])]);
	}, [dymoPrinterDevices, zebraPrinterDevices]);

	const showPrinterModal = useCallback(() => {
		setShowPrinterModalFlag(true);
	}, [setShowPrinterModalFlag]);

	const hasPrinter = useCallback(
		(selector: PrinterSelector) => {
			if (!printerDevices) return false;
			return printerDevices.some((pd) => pd.id === selector.id && pd.brand === selector.brand);
		},
		[printerDevices]
	);

	const getZebraPrintersWithTimeout = useCallback(
		async function () {
			const promise = zebraBrowserPrint.getAvailablePrinters();
			return Timeout.wrap(promise, 6000, 'Zebra Timeout');
		},
		[zebraBrowserPrint]
	);

	const getZebraPrinters = useCallback(
		async function () {
			try {
				setIsZebraFetchingPrinters(true);

				const printers = await getZebraPrintersWithTimeout();

				var zebraPrinters: Array<ZebraPrinterDevice> = printers.map((printer: any): ZebraPrinterDevice => {
					const pd: ZebraPrinterDevice = {
						id: printer.uid,
						name: printer.name,
						connection: printer.connection,
						deviceType: printer.deviceType,
						manufacturer: printer.manufacturer,
						provider: printer.provider,
						uid: printer.uid,
						version: printer.version,
						brand: PrinterBrand.Zebra,
						isSetByUser: false,
						host: {
							name: zebraPrinterHost?.name!,
							port: zebraPrinterHost?.port!,
							protocol: zebraPrinterHost?.protocol!,
						},
					};
					return pd;
				});

				console.log('fetched zebra printers');
				setIsZebraFetchingPrinters(false);
				setZebraPrinterDevices(zebraPrinters);
			} catch (e) {
				setIsZebraFetchingPrinters(false);
				setIsZebraError(true);
			}
		},
		[setZebraPrinterDevices, zebraPrinterHost, zebraBrowserPrint, setIsZebraFetchingPrinters]
	);

	const hasPrinterDevice = useCallback(
		(device: PrinterDevice) => {
			if (!device) return false;
			if (!printerDevices) return false;

			return printerDevices.some((pd) => pd === device);
		},
		[printerDevices]
	);

	const getPrinter = useCallback(
		(selector: PrinterSelector) => {
			if (!selector) return undefined;
			if (!printerDevices) return undefined;
			return printerDevices.find((pd) => pd.id === selector.id && pd.brand === selector.brand);
		},
		[printerDevices]
	);

	const hidePrinterModal = useCallback(() => {
		setShowPrinterModalFlag(false);
	}, [setShowPrinterModalFlag]);

	const getPrintersInternal = useCallback(async () => {
		setPrinterDevices(null);
		setZebraPrinterDevices(null);
		setDymoPrinterDevices(null);
		setManualSelectedPrinterDevice(null);
		setIsInitialized(false);
		setIsZebraFetchingPrinters(false);
		setIsZebraError(false);
		await Promise.all([getDymoPrinters(), getZebraPrinters()]);
	}, [getDymoPrinters, getZebraPrinters]);

	const resetContext = useCallback(async () => {
		setIsResetting(true);
		console.log('resetting printer context');
		window.localStorage.removeItem('ekosiet.printer.dymo.ws.request.params');
		window.localStorage.removeItem('ekosiet.printer.zebra.printer.host');
		setMode(PrinterMode.Automatic);
		prevModeRef.current = PrinterMode.Automatic;
		setPrinterDevices(null);
		setManualSelectedPrinterDevice(null);
		saveSelectedPrinter(null);
		setDymoPrinterHost(null);
		setZebraPrinterHost(defaultZebraPrinterHost);
		prevZebraPrinterHostRef.current = defaultZebraPrinterHost;
		setStatus(null);
		setIsInitialized(false);
		setIsZebraFetchingPrinters(false);
		setIsZebraError(false);
		getPrintersInternal();
		setIsResetting(false);
	}, [getPrintersInternal, saveSelectedPrinter]);

	useEffect(() => {
		if (dymoPrinters) {
			const devices: Array<PrinterDevice> = dymoPrinters.map((printer: any): PrinterDevice => {
				const pd: DymoPrinterDevice = {
					id: printer.name,
					name: printer.name,
					brand: PrinterBrand.Dymo,
					model: printer.modelName,
					isLocal: printer.isLocal === 'True' ? true : false,
					isConnected: printer.isConnected === 'True' ? true : false,
					isSetByUser: false,
					host: {
						protocol: printer.protocol,
						name: printer.host,
						port: printer.port,
					},
				};
				return pd;
			});
			setDymoPrinterDevices(devices);
		}
	}, [dymoPrinters]);

	const setPrinter = useCallback(
		(printerDevice: PrinterDevice | null | undefined) => {
			if (printerDevice && (!printerDevices || !printerDevices.length)) {
				console.log('Unable to set printer. reason: no printer devices available yet.');
				return;
			}

			console.log(`setting printer '${printerDevice !== null ? printerDevice?.id : printerDevice}' using '${printerDevices ? printerDevices.length : printerDevices}' fetched printers`);

			let foundPrinter = false;
			let foundPrinterDevice: PrinterDevice | null = null;

			if (printerDevice && printerDevices) {
				for (let i = 0; i < printerDevices.length; i++) {
					if (
						printerDevice.id === printerDevices[i].id &&
						printerDevice.brand === printerDevices[i].brand &&
						(printerDevice.host ? printerDevice.host.name === printerDevices[i].host?.name && printerDevice.host.port === printerDevices[i].host?.port : true)
					) {
						foundPrinter = true;
						foundPrinterDevice = printerDevices[i];
						break;
					}
				}
			}

			if (printerDevice && !foundPrinter) {
				console.log(
					`no printer '${printerDevice?.id}' with name '${printerDevice?.name}' found for host '${printerDevice?.host?.name}${
						printerDevice?.host?.port ? ':' + printerDevice?.host?.port : ''
					}'`
				);

				if (!printerDevice.isSetByUser) {
					toast.error(`Printer '${printerDevice.name}' niet gevonden. Selecteer zelf een printer!`, {
						duration: 5000,
					});
					saveSelectedPrinter(() => null);
					setManualSelectedPrinterDevice(null);
					return;
				}
			}

			if (foundPrinter) {
				console.log(
					`printer '${printerDevice?.id}' with name '${printerDevice?.name}' found on host '${foundPrinterDevice?.host?.name}${
						foundPrinterDevice?.host?.port ? ':' + foundPrinterDevice?.host?.port : ''
					}'`
				);

				if (foundPrinterDevice && printerDevice && printerDevice.isSetByUser) {
					for (let i = 0; i < printerDevices!.length; i++) {
						printerDevices![i].isSetByUser = false;
					}
					foundPrinterDevice.isSetByUser = true;
					setMode(PrinterMode.Manual);
					saveSelectedPrinter(() => foundPrinterDevice);
				}

				console.log('set selected device to ' + foundPrinterDevice?.id);
				setManualSelectedPrinterDevice(foundPrinterDevice);
			} else {
				//no printer found
				if (mode === PrinterMode.Manual) {
					console.log(`manual selected printer '${printerDevice ? printerDevice.id : printerDevice}' not found resetting back to automatic.`);
					resetContext();
				} else {
					console.log('no printer selected because mode is automatic');
					saveSelectedPrinter(() => null);
					setManualSelectedPrinterDevice(null);
				}
			}
		},
		[resetContext, setManualSelectedPrinterDevice, saveSelectedPrinter, zebraPrinterDevices, dymoPrinterDevices, printerDevices, mode]
	);

	useEffect(() => {
		if (isInitialized === false && !isResetting) {
			if (savedSelectedPrinter) {
				const devices = printerDevices || [];

				if (
					(savedSelectedPrinter.brand === PrinterBrand.Zebra && devices?.filter((x) => x.brand == PrinterBrand.Zebra).length) ||
					(savedSelectedPrinter.brand === PrinterBrand.Dymo && devices?.filter((x) => x.brand == PrinterBrand.Dymo).length)
				) {
					console.log(
						`trying to restore previous saved printer '${savedSelectedPrinter.id}' on host '${savedSelectedPrinter?.host?.name}${
							savedSelectedPrinter?.host?.port ? ':' + savedSelectedPrinter?.host?.port : ''
						}'`
					);
					setPrinter(savedSelectedPrinter);
					setIsInitialized(true);
				}

				if ((savedSelectedPrinter.brand === PrinterBrand.Zebra && isZebraError) || (savedSelectedPrinter.brand === PrinterBrand.Dymo && dymoStatusFetchPrinters === 'error')) {
					console.log(
						`failed to restore previous saved printer because printers could not be fetched '${savedSelectedPrinter.id}' on host '${savedSelectedPrinter?.host?.name}${
							savedSelectedPrinter?.host?.port ? ':' + savedSelectedPrinter?.host?.port : ''
						}'`
					);
					resetContext();
				}
			} else {
				console.log(`no previous saved printer found`);
				setPrinter(null);
				setIsInitialized(true);
			}
		}
	}, [printerDevices, savedSelectedPrinter, setIsInitialized, isInitialized, setPrinter, isZebraError, dymoStatusFetchPrinters]);

	useEffect(() => {
		if (dymoHost) {
			console.log(`setting dymo printer host to '${dymoHost}${dymoPort ? ':' + dymoPort : ''}'`);

			const printerHost: PrinterHost = {
				name: dymoHost,
				port: dymoPort,
				protocol: dymoProtocol,
			};

			setDymoPrinterHost(printerHost);
		}
	}, [dymoHost, dymoPort, dymoProtocol]);

	useEffect(() => {
		if (dymoPrinterHost && dymoPrinterHost.name && dymoHost && (dymoPrinterHost?.name !== dymoHost || dymoPrinterHost?.port !== dymoPort || dymoPrinterHost?.protocol !== dymoProtocol)) {
			console.log(
				`dymo printer host changed, fetching printers from '${dymoPrinterHost?.protocol ? dymoPrinterHost?.protocol : ''}:${dymoPrinterHost?.name}${
					dymoPrinterHost?.port ? ':' + dymoPrinterHost?.port : ''
				}'`
			);

			localStore('ekosiet.printer.dymo.ws.request.params', {activeHost: dymoPrinterHost?.name, activePort: dymoPrinterHost?.port, activeProtocol: dymoPrinterHost?.protocol});

			setDymoPrinterDevices(null);

			if (manualSelectedPrinterDevice && manualSelectedPrinterDevice.brand === PrinterBrand.Dymo) {
				setManualSelectedPrinterDevice(null);
				saveSelectedPrinter(null);
			}

			getDymoPrinters();
		}
	}, [dymoPrinterHost, getDymoPrinters, dymoHost, dymoPort, dymoProtocol, saveSelectedPrinter, manualSelectedPrinterDevice]);

	const setDymoPrinterHostInternal: (value: PrinterHost) => void = (host) => {
		setDymoPrinterHost(host);
		if (dymoPrinterHost && host && dymoPrinterHost.name === host.name && dymoPrinterHost.port === host.port && dymoPrinterHost.protocol === host.protocol) {
			getDymoPrinters();
		}
	};

	const setZebraPrinterHostInternal: (value: PrinterHost) => void = (host) => {
		setZebraPrinterHost(host);
		if (zebraPrinterHost && host && zebraPrinterHost.name === host.name && zebraPrinterHost.port === host.port && zebraPrinterHost.protocol === host.protocol) {
			setZebraPrinterDevices(null);
			getZebraPrinters();
		}
	};

	useEffect(() => {
		if (zebraPrinterHost && zebraPrinterHost.name && prevZebraPrinterHostRef.current !== zebraPrinterHost) {
			if (manualSelectedPrinterDevice && manualSelectedPrinterDevice.brand === PrinterBrand.Zebra) {
				setManualSelectedPrinterDevice(null);
				saveSelectedPrinter(null);
			}

			saveZebraPrinterHost(zebraPrinterHost);

			prevZebraPrinterHostRef.current = zebraPrinterHost;

			var newApiUrl = `${zebraPrinterHost?.protocol || 'http'}://${zebraPrinterHost?.name}:${zebraPrinterHost?.port}/`;

			if (zebraBrowserPrint.apiUrl !== newApiUrl) {
				console.log(
					`zebra printer host changed, fetching printers from '${zebraPrinterHost?.protocol ? zebraPrinterHost?.protocol : ''}:${zebraPrinterHost?.name}${
						zebraPrinterHost?.port ? ':' + zebraPrinterHost?.port : ''
					}'`
				);

				setZebraPrinterDevices(null);
				setZebraBrowserPrint(new ZebraBrowserPrintWrapper(newApiUrl));
			}
		}
	}, [zebraPrinterHost, saveSelectedPrinter, manualSelectedPrinterDevice, zebraBrowserPrint]);

	useEffect(() => {
		if (mode === PrinterMode.Manual && manualSelectedPrinterDevice && manualSelectedPrinterDevice.brand == PrinterBrand.Zebra) {
			console.log('mode is manual and selected printer is of brand zebra so we need to save zebra printer host');
			saveZebraPrinterHost(zebraPrinterHost);
		}
	}, [mode, zebraPrinterHost, manualSelectedPrinterDevice]);

	useEffect(() => {
		if (prevModeRef.current !== mode && mode === PrinterMode.Automatic) {
			console.log(`printer mode set to automatic, searching for printers.`);
			window.localStorage.removeItem('ekosiet.printer.dymo.ws.request.params');
			window.localStorage.removeItem('ekosiet.printer.zebra.printer.host');
			setDymoPrinterDevices(null);
			setZebraPrinterDevices(null);
			setPrinterDevices(null);
			setManualSelectedPrinterDevice(null);
			saveSelectedPrinter(null);
			setDymoPrinterHost(null);
			setZebraPrinterHost(savedZebraPrinterHost || defaultZebraPrinterHost);
			prevZebraPrinterHostRef.current = savedZebraPrinterHost || defaultZebraPrinterHost;
			setIsInitialized(false);
			setIsZebraFetchingPrinters(false);
			setIsZebraError(false);
			getPrintersInternal();
		}

		prevModeRef.current = mode;
	}, [mode, getPrintersInternal]);

	useEffect(() => {
		if (mode) {
			console.log(`printer mode changed to '${mode}'`);
			saveMode(mode);
		}
	}, [mode, saveMode]);

	useEffect(() => {
		if (dymoStatusFetchPrinters === 'loading' || isZebraFetchingPrinters) {
			setStatus('loading');
			return;
		}

		if (dymoStatusFetchPrinters === 'error' || isZebraError) {
			setStatus('error');
			return;
		}

		if (mode === PrinterMode.Manual && !manualSelectedPrinterDevice) {
			setStatus('error');
			return;
		}

		setStatus('success');
	}, [dymoStatusFetchPrinters, mode, manualSelectedPrinterDevice, setStatus, isZebraError, isZebraFetchingPrinters]);

	useEffect(() => {
		if (mode === PrinterMode.Automatic) {
			window.localStorage.removeItem('ekosiet.printer.dymo.ws.request.params');
			window.localStorage.removeItem('ekosiet.printer.zebra.printer.host');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); //run once

	const dymoPrint = useCallback(
		async (xml: string, device: PrinterDevice | undefined | null) => {
			let printerDevice = device;

			if (manualSelectedPrinterDevice) {
				printerDevice = manualSelectedPrinterDevice;
			}

			if (!printerDevice) {
				console.log('label not printed, no printer to print too. Either select printer or provide as argument.');

				toast.error(`Geen printer geselecteerd. Kies een printer.`, {
					duration: 2000,
				});

				return false;
			}

			try {
				var response = await dymoPostPrintLabel(printerDevice, xml);

				if (response.status === 200) {
					console.log(`Label printed on printer '${printerDevice.name}'`);
					return true;
				} else {
					console.log(`Label not printed on printer '${printerDevice.name}'`);
					toast.error(`Label niet geprint op '${printerDevice.name}'`, {
						duration: 2000,
					});

					return false;
				}
			} catch {
				console.log(`Label not printed on printer '${printerDevice.name}'`);
				toast.error(`Label niet geprint op '${printerDevice.name}'`, {
					duration: 2000,
				});

				return false;
			}
		},
		[manualSelectedPrinterDevice]
	);

	const zebraPrint = useCallback(
		async (zpl: string, device: PrinterDevice | undefined | null) => {
			let printerDevice = device;

			if (manualSelectedPrinterDevice) {
				printerDevice = manualSelectedPrinterDevice;
			}

			if (!printerDevice) {
				console.log('label not printed, no printer to print too. Either select printer or provide as argument.');

				toast.error(`Geen printer geselecteerd. Kies een printer.`, {
					duration: 2000,
				});

				return false;
			}

			try {
				const zebraDevice = printerDevice as ZebraPrinterDevice;
				const device = zebraDevice as Device;
				if (!device) {
					console.log(`Gekozen printer '${printerDevice.name}' is geen zebra printer.`);
					toast.error(`Gekozen printer '${printerDevice.name}' is geen zebra printer.`, {
						duration: 1500,
					});

					return false;
				}

				const browserPrint = new ZebraBrowserPrintWrapper(zebraBrowserPrint.apiUrl);

				browserPrint.setPrinter(device);

				browserPrint.print(zpl);

				console.log(`Label printed on printer '${printerDevice.name}'`);

				return true;
			} catch {
				console.log(`Label not printed on printer '${printerDevice.name}'`);
				toast.error(`Label niet geprint op '${printerDevice.name}'`, {
					duration: 2000,
				});

				return false;
			}
		},
		[manualSelectedPrinterDevice, zebraBrowserPrint]
	);

	const print = useCallback(
		async (label: string, device: PrinterDevice | undefined | null) => {
			let printerDevice = device;

			if (manualSelectedPrinterDevice) {
				printerDevice = manualSelectedPrinterDevice;
			}

			if (!printerDevice) {
				console.log('label not printed, no printer to print too. Either select printer or provide as argument.');

				toast.error(`Geen printer geselecteerd. Kies een printer.`, {
					duration: 2000,
				});

				return false;
			}

			if (printerDevice.brand === PrinterBrand.Dymo) {
				return dymoPrint(label, device);
			}

			if (printerDevice.brand === PrinterBrand.Zebra) {
				return zebraPrint(label, device);
			}

			return false;
		},
		[manualSelectedPrinterDevice, dymoPrint, zebraPrint]
	);

	const printByLabel = useCallback(
		async (label: PrinterLabel, data: any, device: PrinterDevice | undefined | null) => {
			if (label) {
				let printerDevice = device;

				if (manualSelectedPrinterDevice) {
					printerDevice = manualSelectedPrinterDevice;
				}

				if (!printerDevice) {
					console.log('label not printed, no printer to print too. Either select printer or provide as argument.');

					toast.error(`Geen printer geselecteerd. Kies een printer.`, {
						duration: 2000,
					});

					return false;
				}

				let labelValue = null;

				if (printerDevice.brand === PrinterBrand.Dymo) {
					if (label.dymo) {
						labelValue = label.dymo(data);
					} else {
						toast.error(`Geen Dymo label gevonden voor label id '${label.id}'`, {
							duration: 2000,
						});
					}
				}

				if (printerDevice.brand === PrinterBrand.Zebra) {
					if (label.zebra) {
						labelValue = label.zebra(data);
					} else {
						toast.error(`Geen Zebra label gevonden voor label id '${label.id}'`, {
							duration: 2000,
						});
					}
				}

				if (labelValue) {
					return print(labelValue, device);
				}

				return false;
			}

			toast.error(`Geen printer label gespecificeerd.`, {
				duration: 2000,
			});

			return false;
		},
		[print]
	);

	const printByFunction = useCallback(
		async (label: (data: any) => string, data: any, device: PrinterDevice | undefined | null) => {
			if (label) {
				let labelValue = label(data);
				if (labelValue) {
					return print(labelValue, device);
				}

				toast.error(`printer label functie heeft geen label.`, {
					duration: 2000,
				});

				return false;
			}

			toast.error(`Geen printer label functie gespecificeerd.`, {
				duration: 2000,
			});

			return false;
		},
		[print]
	);

	const printById = useCallback(
		async (labelId: PrinterLabelId, data: any, device: PrinterDevice | undefined | null) => {
			var hasPrinterLabel = printLabelMap.has(labelId);

			if (hasPrinterLabel) {
				var printerLabel = printLabelMap.get(labelId);

				if (printerLabel) {
					return printByLabel(printerLabel, data, device);
				}
			}

			toast.error(`Geen printer label voor id '${labelId}'.`, {
				duration: 2000,
			});

			return false;
		},
		[printByLabel]
	);

	const store: PrinterContextStore = {
		mode: mode,
		setMode: setMode,
		dymoPrinterHost: dymoPrinterHost,
		setDymoPrinterHost: setDymoPrinterHostInternal,
		zebraPrinterHost: zebraPrinterHost,
		setZebraPrinterHost: setZebraPrinterHostInternal,
		printers: printerDevices,
		hasPrinter: hasPrinter,
		hasPrinterDevice: hasPrinterDevice,
		getPrinter: getPrinter,
		getPrinters: getPrintersInternal,
		setManualPrinter: setPrinter,
		manualSelectedPrinter: manualSelectedPrinterDevice,
		status: status,
		showPrinterModal: showPrinterModal,
		resetContext: resetContext,
		dymoPrint: dymoPrint,
		zebraPrint: zebraPrint,
		print: print,
		printById: printById,
		printByLabel: printByLabel,
		printByFunction: printByFunction,
		usePrinterDevice: usePrinterDevice,
	};

	return (
		<PrinterContext.Provider value={store}>
			<PrintersModal fullscreen="lg-down" size="xl" show={showPrinterModalflag} onHide={hidePrinterModal} />
			{children}
		</PrinterContext.Provider>
	);
};
