import {DymoProductionItemLabel, DymoProductionItemZonderBarcodeLabel, DymoTestPrintLabel, ZebraProductionItemLabel, ZebraProductionItemZonderBarcodeLabel, ZebraTestPrintLabel} from './LabelPrinterTemplates';
import {PrinterBrand, PrinterSelector} from '../../context/PrinterContext';

export const PrinterMode = {
	Automatic: 'Automatisch',
	Manual: 'Handmatig',
} as const;

export const PrinterNames = {
	ZebraExpeditie: '192.168.178.145',
	ZebraWerkvoorbereding: '192.168.178.253',
	DYMOLWW12C1DA: 'DYMO LabelWriter Wireless on DYMOLWW12C1DA',
	LabelwriterFabriek2: 'LabelwriterFabriek2',
	DYMOLabelWriter550Turbo: 'DYMO LabelWriter 550 Turbo',
	ZDesignerZD230dash203dpiZPL: 'ZDesigner ZD230-203dpi ZPL'
} as const;

export const PrinterHostProtocols = {
	Https: 'https',
	Http: 'http',
} as const;

export const PrinterSelectors = {
	DYMOLWW12C1DA: {
		id: PrinterNames.DYMOLWW12C1DA,
		brand: PrinterBrand.Dymo,
	} as PrinterSelector,
	LabelwriterFabriek2: {
		id: PrinterNames.LabelwriterFabriek2,
		brand: PrinterBrand.Dymo,
	} as PrinterSelector,
	DYMOLabelWriter550Turbo: {
		id: PrinterNames.DYMOLabelWriter550Turbo,
		brand: PrinterBrand.Dymo,
	} as PrinterSelector,
	ZDesignerZD230dash203dpiZPL: {
		id: PrinterNames.ZDesignerZD230dash203dpiZPL,
		brand: PrinterBrand.Zebra,
	} as PrinterSelector,
	ZebraExpeditie: {
		id: PrinterNames.ZebraExpeditie,
		brand: PrinterBrand.Zebra,
	} as PrinterSelector,
	ZebraWerkVoorbereiding: {
		id: PrinterNames.ZebraWerkvoorbereding,
		brand: PrinterBrand.Zebra,
	} as PrinterSelector,
} as const;

export const PrinterModeOptions: any[] = [
	{value: PrinterMode.Automatic, label: 'Automatisch'},
	{value: PrinterMode.Manual, label: 'Handmatig'},
];

export const PrinterProtocolOptions: any[] = [
	{value: PrinterHostProtocols.Http, label: PrinterHostProtocols.Http},
	{value: PrinterHostProtocols.Https, label: PrinterHostProtocols.Https},
];

export type PrinterLabel = {
	id: PrinterLabelId;
	dymo: ((data: any) => string) | null | undefined;
	zebra: ((data: any) => string) | null | undefined;
};

export type PrinterLabelId = 'testprint' | 'productionitem' | 'productionitemZonderBarcode';

const testPrintLabel: PrinterLabel = {
	id: 'testprint',
	dymo: DymoTestPrintLabel,
	zebra: ZebraTestPrintLabel,
};

const productionItemLabel: PrinterLabel = {
	id: 'productionitem',
	dymo: DymoProductionItemLabel,
	zebra: ZebraProductionItemLabel,
};

const productionItemZonderBarcodeLabel: PrinterLabel = {
	id: 'productionitemZonderBarcode',
	dymo: DymoProductionItemZonderBarcodeLabel,
	zebra: ZebraProductionItemZonderBarcodeLabel,
};

export const printLabelMap = new Map<PrinterLabelId, PrinterLabel>([
	['testprint', testPrintLabel],
	['productionitem', productionItemLabel],
	['productionitemZonderBarcode',  productionItemZonderBarcodeLabel]
]);

export const printerTags = new Map<string, Array<string>>([
		[PrinterNames.ZebraExpeditie, ["Expeditie", "Afmelden"]],
		[PrinterNames.ZebraWerkvoorbereding, ["Werkvoorbereiding"]],
		[PrinterNames.DYMOLWW12C1DA, ["Expeditie", "Afmelden"]],
]);