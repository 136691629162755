import {EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar} from '../card';
import {FC, ReactNode} from 'react';
import SpinnerButtonV2 from '../SpinnerButtonV2';
import {useCustomerPreferencesOverview} from './useCustomerPreferencesOverview';
import {Mm} from '../Mm';
import {HefSchuifSlidingRailTypeText} from '../configurator/HefSchuifRailTypeText';
import {EkoFormRow} from '../EkoFormRow';
import {AssemblyMethodText} from '../configurator/AssemblyMethodText';
import {InsulatedText} from '../configurator/InsulatedText';

export type CustomerPreferencesOverviewProps = {
	customerId: string | undefined;
};

export const CustomerPreferencesOverview: FC<CustomerPreferencesOverviewProps> = (props) => {
	const {
		preferences,
		component,
		editPreferencesHefSchuif,
		editPreferencesVastglasBinnen,
		editPreferencesVastglasBuiten,
		editPreferencesDraaiKiep,
		editPreferencesDeurBinnendraaiend,
		editPreferencesCommon,
	} = useCustomerPreferencesOverview(props.customerId);

	if (!preferences) return <></>;

	return (
		<>
			<div className="alert alert-info">
				De voorkeuren die je hier instelt, worden automatisch toegepast bij het configureren van nieuwe dorpels en kunnen tijdens het configureren op elk moment worden aangepast.
			</div>

			<EkoCard className="mb-5">
				<EkoCardHeader title={'Algemeen'}>
					<EkoCardToolbar>
						<SpinnerButtonV2 type="button" className="btn btn-primary" onClick={() => editPreferencesCommon(preferences.common)} dotdotdot={false}>
							Wijzigen
						</SpinnerButtonV2>
					</EkoCardToolbar>
				</EkoCardHeader>
				<EkoCardBody>
					<EkoFormRow>
						<div className="col">
							<h6>Montagemethode</h6>
							<p>Standaard montagemethode.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							<PrefValueDisplay isSet={preferences.common?.assemblyMethod?.isSet ?? false} label={<AssemblyMethodText value={preferences.common?.assemblyMethod?.value} />} />
						</div>
					</EkoFormRow>
					<EkoFormRow>
						<div className="col">
							<h6>Isolatiemethode</h6>
							<p>Standaard isolatiemethode.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							<PrefValueDisplay isSet={preferences.common?.insulated?.isSet ?? false} label={<InsulatedText value={preferences.common?.insulated?.value ?? false} />} />
						</div>
					</EkoFormRow>
					{/*<EkoFormRow>*/}
					{/*	<div className="col">*/}
					{/*		<h6>Bevestigingsmiddelen</h6>*/}
					{/*		<p>Standaard bevestigingsmiddelen meeleveren.</p>*/}
					{/*	</div>*/}
					{/*	<div className="col-2 d-flex align-items-center">*/}
					{/*		<PrefValueDisplay*/}
					{/*			isSet={preferences.common?.fastenersIncluded?.isSet ?? false}*/}
					{/*			label={<FastenersIncludedText value={preferences.common?.fastenersIncluded?.value ?? undefined} />}*/}
					{/*		/>*/}
					{/*	</div>*/}
					{/*</EkoFormRow>*/}
				</EkoCardBody>
			</EkoCard>

			<EkoCard className="mb-5">
				<EkoCardHeader title={'Deur binnendraaiend'}>
					<EkoCardToolbar>
						<SpinnerButtonV2 type="button" className="btn btn-primary" onClick={() => editPreferencesDeurBinnendraaiend(preferences.deurBinnendraaiend)} dotdotdot={false}>
							Wijzigen
						</SpinnerButtonV2>
					</EkoCardToolbar>
				</EkoCardHeader>
				<EkoCardBody>
					<EkoFormRow>
						<div className="col">
							<h6>Aanslag profiel</h6>
							<p>Standaard aanslagprofiel toepassen indien van toepassing.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							<PrefValueDisplay isSet={preferences.deurBinnendraaiend?.stopProfileId.isSet ?? false} label={preferences.deurBinnendraaiendDetails?.stopProfile?.name} />
						</div>
					</EkoFormRow>
				</EkoCardBody>
			</EkoCard>

			<EkoCard className="mb-5">
				<EkoCardHeader title={'Draaikiep'}>
					<EkoCardToolbar>
						<SpinnerButtonV2 type="button" className="btn btn-primary" onClick={() => editPreferencesDraaiKiep(preferences.draaiKiep)} dotdotdot={false}>
							Wijzigen
						</SpinnerButtonV2>
					</EkoCardToolbar>
				</EkoCardHeader>
				<EkoCardBody>
					<EkoFormRow>
						<div className="col">
							<h6>Aanslag profiel</h6>
							<p>Standaard aanslagprofiel toepassen indien van toepassing.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							<PrefValueDisplay isSet={preferences.draaiKiep?.stopProfileId.isSet ?? false} label={preferences.draaiKiepDetails?.stopProfile?.name} />
						</div>
					</EkoFormRow>

					<EkoFormRow>
						<div className="col">
							<h6>Aanslagsponning</h6>
							<p>Standaard aanslagsponning toepassen.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							<PrefValueDisplay
								isSet={preferences.draaiKiep?.stopRabbetDepth.isSet ?? false}
								label={
									preferences.draaiKiepDetails?.stopRabbetDepth === null ? 'Geen aanslagsponning' : <AanslagsponningDisplay value={preferences.draaiKiepDetails?.stopRabbetDepth} />
								}
							/>
						</div>
					</EkoFormRow>
				</EkoCardBody>
			</EkoCard>

			<EkoCard className="mb-5">
				<EkoCardHeader title={'Vastglas binnen'}>
					<EkoCardToolbar>
						<SpinnerButtonV2 type="button" className="btn btn-primary" onClick={() => editPreferencesVastglasBinnen(preferences.vastglasBinnen)} dotdotdot={false}>
							Wijzigen
						</SpinnerButtonV2>
					</EkoCardToolbar>
				</EkoCardHeader>
				<EkoCardBody>
					<EkoFormRow>
						<div className="col">
							<h6>Glaslat</h6>
							<p>Standaard glaslat toepassen indien van toepassing.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							<PrefValueDisplay isSet={preferences.vastglasBinnen?.glazingBarId.isSet ?? false} label={preferences.vastglasBinnenDetails?.glazingBar?.name} />
						</div>
					</EkoFormRow>
					<EkoFormRow>
						<div className="col">
							<h6>Beglazingsprofiel</h6>
							<p>Standaard beglazingsprofiel toepassen indien van toepassing.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							<PrefValueDisplay isSet={preferences.vastglasBinnen?.glazingProfileId.isSet ?? false} label={preferences.vastglasBinnenDetails?.glazingProfile?.name} />
						</div>
					</EkoFormRow>
				</EkoCardBody>
			</EkoCard>

			<EkoCard className="mb-5">
				<EkoCardHeader title={'Vastglas buiten'}>
					<EkoCardToolbar>
						<SpinnerButtonV2 type="button" className="btn btn-primary" onClick={() => editPreferencesVastglasBuiten(preferences.vastglasBuiten)} dotdotdot={false}>
							Wijzigen
						</SpinnerButtonV2>
					</EkoCardToolbar>
				</EkoCardHeader>
				<EkoCardBody>
					<EkoFormRow>
						<div className="col">
							<h6>Glaslat</h6>
							<p>Standaard glaslat toepassen indien van toepassing.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							<PrefValueDisplay isSet={preferences.vastglasBuiten?.glazingBarId.isSet ?? false} label={preferences.vastglasBuitenDetails?.glazingBar?.name} />
						</div>
					</EkoFormRow>
				</EkoCardBody>
			</EkoCard>

			<EkoCard className="mb-5">
				<EkoCardHeader title={'Hef-/schuif'}>
					<EkoCardToolbar>
						<SpinnerButtonV2 type="button" className="btn btn-primary" onClick={() => editPreferencesHefSchuif(preferences.hefSchuif)} dotdotdot={false}>
							Wijzigen
						</SpinnerButtonV2>
					</EkoCardToolbar>
				</EkoCardHeader>
				<EkoCardBody>
					<EkoFormRow>
						<div className="col">
							<h6>Looprail</h6>
							<p>Standaard looprail type.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							<PrefValueDisplay
								isSet={preferences.hefSchuif?.slidingRailType.isSet ?? false}
								label={<HefSchuifSlidingRailTypeText value={preferences.hefSchuifDetails?.slidingRailType} />}
							/>
						</div>
					</EkoFormRow>

					<EkoFormRow>
						<div className="col">
							<h6>Rubberblok</h6>
							<p>Standaard een uitsparing voor een rubberblok toepassen in hef-/schuifvakken.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							<PrefValueDisplay
								isSet={preferences.hefSchuif?.doorBarCutout.isSet ?? false}
								label={
									preferences.hefSchuif?.doorBarCutout.value !== null
										? `${preferences.hefSchuifDetails?.doorBarCutout?.length}x${preferences.hefSchuifDetails?.doorBarCutout?.width}mm`
										: 'Geen rubberblok'
								}
							/>
						</div>
					</EkoFormRow>

					<EkoFormRow>
						<div className="col">
							<h6>Lat vastedeur inspringen</h6>
							<p>Standaard de lat vastedeur ingespringen ten opzichte van de vaste deur.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							<PrefValueDisplay isSet={preferences.hefSchuif?.fixedDoorBarInset.isSet ?? false} label={<Mm value={preferences.hefSchuifDetails?.fixedDoorBarInset} />} />
						</div>
					</EkoFormRow>
				</EkoCardBody>
			</EkoCard>
			{component}
		</>
	);
};

const PrefValueDisplay: FC<{isSet: boolean; label: string | ReactNode}> = (props) => {
	return <div>{props.isSet ? props.label : <span className="text-muted">Niet ingesteld</span>}</div>;
};

const AanslagsponningDisplay: FC<{value?: number}> = (props) => {
	return <>{props.value === null ? 'Geen aanslagsponning' : <Mm value={props.value} />}</>;
};
