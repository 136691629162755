import React from 'react';

interface StatStickerProps {
    value: string | number;
    label: string;
    iconClass: string;
    variant?: 'success' | 'warning' | 'info' | 'danger';
}

const StatSticker: React.FC<StatStickerProps> = ({ value, label, iconClass, variant }) => {
    const variantClass = variant ? `bg-${variant} text-inverse-${variant} border-light-${variant}` : 'bg-light';
    return (
        <div className={`flex-column overflow-hidden p-1 py-lg-2 px-lg-2 me-1 me-lg-6 text-center border ${variantClass} rounded`}>
            <div className={`fs-lg-3 fs-7`}>
                {value}
            </div>
            <span className="nav-text fs-7 text-nowrap">
                <i className={`${iconClass} ${variant ? `text-inverse-${variant}` : ''}`}></i> {label}
            </span>
        </div>
    );
};

export default StatSticker;