import axios from "axios";
import XMLParser from "react-xml-parser";

import {
  WS_SVC_PATH,
  WS_ACTIONS,
  WS_SVC_HOST_LOCAL,
  WS_SVC_HOST_LOCAL_PORT,
  WS_SVC_HOST_SERVER,
  WS_SVC_HOST_SERVER_PORT,
  WS_SVC_HOST_SERVER_PROTOCOL,
  WS_SVC_HOST_LOCAL_PROTOCOL
} from "./constants";
import { localRetrieve, localStore } from "./storage";

export const dymoPrinterHostList = [
  {
    protocol: WS_SVC_HOST_SERVER_PROTOCOL,
    host: WS_SVC_HOST_SERVER,
    port: WS_SVC_HOST_SERVER_PORT,
  },
  {
    protocol: WS_SVC_HOST_LOCAL_PROTOCOL,
    host: WS_SVC_HOST_LOCAL,
    port: WS_SVC_HOST_LOCAL_PORT,
  }
];

async function storeDymoRequestParamsEkosiet() {
  for (let currentHostIndex = 0; currentHostIndex < dymoPrinterHostList.length; currentHostIndex++) {
    try {
      
      var host = dymoPrinterHostList[currentHostIndex];
      
      const hostName = host.host;
      const hostPort = host.port;
      const hostProtocol = host.protocol;

      const response = await axios.get(
        dymoUrlBuilder(hostProtocol, hostName, hostPort, WS_SVC_PATH, "status")
      );
      
      const [successRequestHost, successRequestPort] = response.config.url.split("/")[2].split(":");

      localStore("ekosiet.printer.dymo.ws.request.params", 
        { activeHost: successRequestHost, activePort: successRequestPort, activeProtocol: hostProtocol }
      );
      
      break;
    } catch (error) {
    }
  }
}

// async function storeDymoRequestParams() {
//   //let activeHost, activePort;
//   // if (localRetrieve("ekosiet.printer.dymo.ws.request.params")) {
//   //   activeHost = localRetrieve("ekosiet.printer.dymo.ws.request.params").activeHost;
//   //   activePort = localRetrieve("ekosiet.printer.dymo.ws.request.params").activePort;
//   //   window.localStorage.removeItem("ekosiet.printer.dymo.ws.request.params");
//   // }
//   const hostList = [WS_SVC_HOST, WS_SVC_HOST_LEGACY];

//   loop1: for (let currentHostIndex = 0; currentHostIndex < hostList.length; currentHostIndex++) {
//     loop2: for (let currentPort = WS_START_PORT; currentPort <= WS_END_PORT; currentPort++) {
//       // if (hostList[currentHostIndex] === activeHost && currentPort === Number.parseInt(activePort)) {
//       //   continue loop2;
//       // }
//       try {
//         const response = await axios.get(
//           dymoUrlBuilder(WS_PROTOCOL, hostList[currentHostIndex], currentPort, WS_SVC_PATH, "status")
//         );
//         const [successRequestHost, successRequestPort] = response.config.url.split("/")[2].split(":");
//         localStore("ekosiet.printer.dymo.ws.request.params", { activeHost: successRequestHost, activePort: successRequestPort });
//         break loop1;
//       } catch (error) { }
//     }
//   }
// }

export async function dymoRequestBuilder({  
  wsPath = WS_SVC_PATH,
  wsAction,
  method,
  cancelToken,
  axiosOtherParams = {}
}) {  
  if (!localRetrieve("ekosiet.printer.dymo.ws.request.params")) {    
    await storeDymoRequestParamsEkosiet();
  }
  const { activeHost, activePort, activeProtocol } = localRetrieve("ekosiet.printer.dymo.ws.request.params");

  const dymoAxiosInstance = axios.create();

  dymoAxiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (axios.isCancel(error) || error?.response?.status === 500 || (error.toJSON && error.toJSON().message === 'Network Error')) {
        return Promise.reject(error);
      }

      // await storeDymoRequestParams();
      await storeDymoRequestParamsEkosiet();

      if (!localRetrieve("ekosiet.printer.dymo.ws.request.params")) {
        return Promise.reject(error);
      }
      try {
        const { activeHost, activePort, activeProtocol } = localRetrieve("ekosiet.printer.dymo.ws.request.params");

        const response = await axios.request({
          url: dymoUrlBuilder(activeProtocol, activeHost, activePort, wsPath, wsAction),
          method: method,
          cancelToken: cancelToken,
          activeHost: activeHost,
          activePort: activePort,
          activeProtocol: activeProtocol,
          ...axiosOtherParams,
        });
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    }
  );

  const dymoUrl = dymoUrlBuilder(activeProtocol, activeHost, activePort, wsPath, wsAction);

  console.log(`getting Dymo data from '${dymoUrl}'.`)

  const request = await dymoAxiosInstance.request({
    url: dymoUrl,
    method,
    cancelToken,
    activeHost: activeHost,
    activePort: activePort,
    activeProtocol: activeProtocol,
    ...axiosOtherParams,
  });
  return request;
}

export function dymoUrlBuilder(wsProtocol, wsHost, wsPort, wsPath, wsAction) {  
  var x = `${wsProtocol}://${wsHost}:${wsPort}/${wsPath}/${WS_ACTIONS[wsAction]}`;
  return x;
}

// Converts the XML response thrown by the dymo service for GetPrinters in a object with the following structure
// Only return the printers type => "LabelWriterPrinters"
//   {
//      name: "",
//      modelName: "",
//      isLocal: "",
//      isTwinTurbo: "",
//      isConnected: "",
//   }
//
export function getDymoPrintersFromXml(xml, modelPrinter, host, port, protocol) {
  const xmlParse = new XMLParser().parseFromString(xml);
  const labelWritersPrinters = xmlParse.getElementsByTagName(modelPrinter);
  var printers = [];
  labelWritersPrinters.map((printer) => {

    const printerDetails = {};

    printer.children.map((item) => {
      printerDetails[item.name.charAt(0).toLowerCase() + item.name.slice(1)] = item.value;
      return item;
    });

    printerDetails.protocol = protocol;
    printerDetails.host = host;
    printerDetails.port = port;
    
    printers.push(printerDetails);

    return printerDetails;
  });
  return printers;
}

/**
 * Print dymo labels
 *
 * @param {string} printerName - The Dymo Printer to print on
 * @param {string} labelXml - Label XML parsed to string
 * @param {string} labelSetXml - LabelSet to print. LabelSet is used to print multiple labels with same layout but different data, e.g. multiple addresses.
 * @returns AxiosResponse
 */
export function printLabel(printerName, labelXml, labelSetXml) {
  return dymoRequestBuilder({
    method: "POST",
    wsAction: "printLabel",
    axiosOtherParams: {
      data: `printerName=${encodeURIComponent(printerName)}&printParamsXml=&labelXml=${encodeURIComponent(labelXml)}&labelSetXml=${labelSetXml || ""}`,
    },
  });
}

export async function dymoEkosietRequestBuilder({  
  wsPath = WS_SVC_PATH,
  wsAction,
  method,
  cancelToken,
  axiosOtherParams = {}
}) {
  if (!localRetrieve("ekosiet.printer.dymo.ws.request.params")) {    
    await storeDymoRequestParamsEkosiet();
  }

  const { activeHost, activePort, activeProtocol } = localRetrieve("ekosiet.printer.dymo.ws.request.params");
    
  const dymoUrl = dymoUrlBuilder(activeProtocol, activeHost, activePort, wsPath, wsAction);

  console.log(`posting data to '${dymoUrl}'.`)

  const response = await axios.post({
    url: dymoUrl,
    method,
    cancelToken,
    activeHost: activeHost,
    activePort: activePort,
    activeProtocol: activeProtocol,
    ...axiosOtherParams,
  });

  return response;
}