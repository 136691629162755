import React, {useLayoutEffect} from 'react';
import {FieldProps, Formik, FormikHelpers, useFormikContext} from 'formik';
import {Link, useNavigate, useParams} from 'react-router-dom';
import SubmitButton from '../../../../shared/components/SubmitButton';
import {ValidationErrors} from '../../../../modules/api-client/Responses';
import * as api from '../../../../modules/api-client/generated';
import SillTypeText from './SillTypeText';
import AbsLink from '../../../../shared/components/RouteHelpers';
import {BackofficeRoutePath} from '../../../RoutePath';
import {PimRoutePath} from '../PimRoutes';
import Loading from '../../../../shared/components/Loading';
import {SillAssemblyOptions} from '../../../../modules/api-client/generated';
import {SillProductsPicker, StandardProductPicker} from '../catalogitem/CatalogItemPicker';
import {useSillUpsertMutation} from './hooks/use-sill-upsert-mutation';
import {useSillQuery} from './hooks/use-sill-query';
import * as Yup from 'yup';

export interface SillFormValues {
	code: string;
	active: boolean;
	name: string;
	type: api.SillType | '';
	width: number | '';
	rabbetWidth: number | '';
	description: string;
	assemblyOptions: SillAssemblyOptions | undefined;
	baseSillId?: string;
	extenderId?: string | null;
	catalogItemUninsulated?: string | null;
	catalogItemInsulated?: string | null;
}

const schema = Yup.object()
	.shape({
		catalogItemUninsulated: Yup.string().nullable(),
		catalogItemInsulated: Yup.string()
			.nullable()
			.when('catalogItemUninsulated', {
				is: (val: string | null) => !val,
				then: Yup.string().required('Geïsoleerd product is verplicht als Ongeïsoleerd product niet is ingevuld.'),
			}),
	})
	.test('one-of-two-required', 'Ongeïsoleerd of Geïsoleerd product moet ingevuld zijn.', function (values) {
		return !!values.catalogItemUninsulated || !!values.catalogItemInsulated;
	});

const SillUpsert: React.FC = () => {
	const {id} = useParams<{id: string}>();

	const {mutate, serverValidationErrors} = useSillUpsertMutation(id);
	const navigate = useNavigate();

	const onSubmit = (values: SillFormValues, {setSubmitting}: FormikHelpers<SillFormValues>) => {
		return new Promise<void>((resolve, reject) => {
			mutate(values, {
				onSuccess: () => {
					navigate(PimRoutePath.link(PimRoutePath.sills));
					resolve();
				},
				onError: () => {
					reject();
				},
			});
		}).finally(() => setSubmitting(false));
	};

	const {data: sill, isInitialLoading, isError} = useSillQuery(id);

	if (isInitialLoading) return <Loading />;
	if (isError) return <>Error...</>;
	if (id && !sill) return <>Dorpelmodel '{id}' niet gevonden.</>;

	const initialValues: SillFormValues = {
		code: sill?.code ?? '',
		active: sill?.active ?? false,
		name: sill?.name ?? '',
		type: sill?.type ?? '',
		width: sill?.width ?? '',
		rabbetWidth: sill?.rabbetWidth ?? '',
		description: sill?.description ?? '',
		assemblyOptions:
			sill?.assemblyOptions !== undefined
				? {
						preAssemblyEnabled: !!sill?.assemblyOptions.preAssembly,
						preAssembly: sill?.assemblyOptions.preAssembly
							? {
									wrap: sill.assemblyOptions.preAssembly.wrap,
									mountingOptions: sill.assemblyOptions.preAssembly.mountingOptions,
							  }
							: undefined,
						postAssembly: {
							wrap: sill.assemblyOptions.postAssembly.wrap,
							mountingOptions: sill.assemblyOptions.postAssembly.mountingOptions,
						},
				  }
				: undefined,
		catalogItemUninsulated: sill?.catalogItemUninsulated?.id,
		catalogItemInsulated: sill?.catalogItemInsulated?.id,
	};

	return (
		<>
			<Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
				<SillUpsertForm id={id} serverValidationErrors={serverValidationErrors} />
			</Formik>
		</>
	);
};

interface SillUpsertFormProps {
	id: string | undefined;
	serverValidationErrors: ValidationErrors | null;
}

export const SillUpsertForm: React.FC<SillUpsertFormProps> = (props) => {
	const {values, handleSubmit, handleChange, isSubmitting, errors, setErrors} = useFormikContext<SillFormValues>();
	const mergeServerErrors = () => {
		if (props.serverValidationErrors) {
			setErrors({...errors, ...props.serverValidationErrors});
		}
	};
	useLayoutEffect(mergeServerErrors, [props.serverValidationErrors, errors, setErrors]);
	return (
		<form onSubmit={handleSubmit}>
			<div className={'row'}>
				<div className="col-12 col-xxl-6">
					<div className="card">
						<div className="card-header">
							<h3 className="card-title">
								{props.id && 'Dorpelmodel wijzigen'}
								{!props.id && 'Dorpelmodel toevoegen'}
							</h3>
							<div className="card-toolbar"></div>
						</div>
						<div className="card-body">
							<div className="row mb-4">
								<div className="col-4">
									<label className="form-label">Code</label>
								</div>
								<div className="col-8">
									<input
										type="text"
										className={`form-control ${errors.code ? 'is-invalid' : ''}`}
										name="code"
										required
										autoComplete="off"
										placeholder="Unieke code voor dorpelmodel"
										onChange={handleChange}
										value={values.code}
										autoFocus
									/>

									{errors.code && (
										<div className="invalid-feedback">
											<div>{errors.code}</div>
										</div>
									)}
								</div>
							</div>

							<div className="row mb-4">
								<div className="col-4">
									<label className="form-label">Naam</label>
								</div>
								<div className="col-8">
									<input type="text" className="form-control" name="name" required placeholder="" onChange={handleChange} value={values.name} />
								</div>
							</div>

							<div className="row mb-4">
								<div className="col-4">
									<label className="form-label" htmlFor="type">
										Dorpeltype
									</label>
								</div>
								<div className="col-8">
									<select id="type" className="form-select" name="type" required onChange={handleChange} value={values.type}>
										<option value="">Selecteer dorpeltype</option>
										<option value={api.SillType.Binnensponning}>
											<SillTypeText code={api.SillType.Binnensponning} />
										</option>
										<option value={api.SillType.Buitensponning}>
											<SillTypeText code={api.SillType.Buitensponning} />
										</option>
										<option value={api.SillType.HefSchuif}>
											<SillTypeText code={api.SillType.HefSchuif} />
										</option>
										<option value={api.SillType.Vouwwand}>
											<SillTypeText code={api.SillType.Vouwwand} />
										</option>
										<option value={api.SillType.Zijlicht}>
											<SillTypeText code={api.SillType.Zijlicht} />
										</option>
									</select>
								</div>
							</div>

							<div className="row mb-4">
								<div className="col-4">
									<label className="form-label">Sponningsbreedte</label>
								</div>
								<div className="col-4">
									<div className="input-group">
										<input type="number" className="form-control" name="rabbetWidth" step="0.1" min="0" required onChange={handleChange} value={values.rabbetWidth} />
										<span className="input-group-text">mm</span>
									</div>
								</div>
							</div>

							<div className="row mb-4">
								<div className="col-4">
									<label className="form-label">Breedte</label>
								</div>
								<div className="col-4">
									<div className="input-group">
										<input type="number" className="form-control" name="width" step="0.1" min="0" required onChange={handleChange} value={values.width} />
										<span className="input-group-text">mm</span>
									</div>
								</div>
							</div>

							<div className="row mb-4">
								<div className="col-4">
									<label className="form-label">Basis dorpelmodel</label>
								</div>
								<div className="col">{/*<SillProductsPicker name='baseSillId' required={true} />*/}</div>
							</div>
							<div className="row mb-4">
								<div className="col-4">
									<label className="form-label">Verbreder (optioneel)</label>
								</div>
								<div className="col">{/*<StandardProductPicker name='extenderId' catalogId={'01000000-1AA0-4EDA-C4DB-08DB2157B688'} category="" required={false} />*/}</div>
							</div>

							{!props.id && (
								<>
									<div className="row mb-4">
										<div className="col-4">
											<label className="form-label">Ongeïsoleerd</label>
										</div>
										<div className="col">
											<SillProductsPicker catalogId={values.catalogItemUninsulated ?? ''} name="catalogItemUninsulated" />
										</div>
									</div>

									<div className="row mb-4">
										<div className="col-4">
											<label className="form-label">Geïsoleerd</label>
										</div>
										<div className="col">
											<SillProductsPicker catalogId={values.catalogItemInsulated ?? ''} name="catalogItemInsulated" />
										</div>
									</div>
								</>
							)}

							{/*<div className='row mb-4'>*/}
							{/*	<div className='col-4'>*/}
							{/*		<label className='form-label' htmlFor='type'>Inpakbaar</label>*/}
							{/*	</div>*/}
							{/*	<div className='col-8'>*/}
							{/*		<div className='form-check form-switch'>*/}
							{/*			<input className='form-check-input' type='checkbox' role='switch'*/}
							{/*				   name='wrappable'*/}
							{/*				   value='true'*/}
							{/*				   onChange={handleChange}*/}
							{/*				   checked={values.wrappable}*/}
							{/*				   id='active' />*/}
							{/*			<label className='form-check-label' htmlFor='active'>Inpakbaar</label>*/}
							{/*		</div>*/}
							{/*	</div>*/}
							{/*</div>*/}

							{props.id && (
								<div className="row mb-4">
									<div className="col-4">
										<label className="form-label">Omschrijving</label>
									</div>
									<div className="col-8">
										<textarea rows={5} className="form-control" name="description" placeholder="" onChange={handleChange} value={values.description}></textarea>
									</div>
								</div>
							)}

							<div className="row mb-4">
								<div className="col-4">
									<label className="form-label" htmlFor="type">
										Actief
									</label>
								</div>
								<div className="col-8">
									<div className="form-check form-switch">
										<input className="form-check-input" type="checkbox" role="switch" name="active" value="true" onChange={handleChange} checked={values.active} id="active" />
										<label className="form-check-label" htmlFor="active">
											Actief
										</label>
									</div>
								</div>
							</div>

							<div className="row pt-5">
								<div className="offset-4 col-8 d-flex justify-content-end">
									<Link className="btn btn-link me-5" to={AbsLink(BackofficeRoutePath.pim, PimRoutePath.sills)}>
										Annuleren
									</Link>
									<SubmitButton type="submit" className="btn btn-primary" isSubmitting={isSubmitting}>
										Opslaan
									</SubmitButton>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export default SillUpsert;
