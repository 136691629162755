import {FC} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'

type Props = {
  className?: string
}

const EkoCardToolbar: FC<Props & WithChildren> = ({className, children}) => {
  return (
      <div className={`card-toolbar ${className || ''}`}>
          {children}
      </div>
  )
}

export {EkoCardToolbar}
