/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useGetCustomerSluitpotMappings} from '../../../hooks/use-get-customer-sluitpot-mappings';
import {ImportSluitpotMappingRepresentation} from '../../../../../modules/api-client/generated';
import {EkoTable, TableHeader} from '../../../../components/table';
import {useEkosietAuth0} from '../../../../../modules/auth0/core/useEkosietAuth0';
import SpinnerButton from '../../../../components/SpinnerButton';
import {useDeleteImportSluitpotMapping} from '../../../hooks/use-delete-import-sluitpot-mapping';
import ImportSluitpotMappingUpsert from '../../../components/mapping/ImportSluitpotMappingUpsert';
import {Button} from 'react-bootstrap';
import {ImportModal} from '../../../components/ImportModal';
import {EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar} from '../../../../components/card';
import Loading from '../../../../components/Loading';

const CustomerImportSluitpotMappingPage: React.FC = () => {
	const {id} = useParams<{id: string}>();
	const {isKlant, customerId} = useEkosietAuth0();

	const {isInitialLoading, data: mappings, isError} = useGetCustomerSluitpotMappings(isKlant ? customerId : id);
	const [selectedMapping, setSelectedMapping] = useState<ImportSluitpotMappingRepresentation | undefined>(undefined);
	const [showModal, setShowModal] = useState<boolean>(false);
	var mutationDeleteImportMapping = useDeleteImportSluitpotMapping();

	if (isInitialLoading) {
		return <Loading />;
	}

	if (isError) {
		return <>Fout bij het ophalen van de sluitpot koppelingen!</>;
	}

	return (
		<>
			<ImportModal
				show={showModal}
				handleClose={() => setShowModal(false)}
				title={'Mapping toevoegen of aanpassen'}
				size={'lg'}
				component={
					<ImportSluitpotMappingUpsert
						id={selectedMapping?.id}
						customerId={selectedMapping?.customer.id ?? isKlant ? customerId : id}
						handleSucces={() => {
							setSelectedMapping(undefined);
							setShowModal(false);
						}}
					/>
				}
				fullscreen={'md-down'}
			/>
			<EkoCard>
				<EkoCardHeader title={'Sluitpot koppelingen'}>
					<EkoCardToolbar>
						<Button className={'btn btn-primary'} onClick={() => setShowModal(true)}>
							Toevoegen
						</Button>
					</EkoCardToolbar>
				</EkoCardHeader>
				<EkoCardBody>
					{!mappings ? (
						<div className={'alert alert-warning'}>Er zijn nog geen profielen geïmporteerd voor deze klant.</div>
					) : (
						<EkoTable>
							<TableHeader>
								<th>Externe code</th>
								<th></th>
								<th>Ekosiet sluitpot</th>
							</TableHeader>
							<tbody>
								{mappings.map((mapping) => (
									<tr key={mapping.id}>
										<td>
											<h2>{mapping.externalCode}</h2>
										</td>
										<td>
											<h1>
												<i className={'fa fa-arrow-right'}> </i>
											</h1>
										</td>
										<td>
											<h2>{mapping.sluitpotBeslag.name}</h2>
										</td>
										<td>
											<>
												<button
													type="button"
													className={`btn btn-secondary me-2 `}
													title="Wijzig Mapping"
													onClick={() => {
														setSelectedMapping(mapping);
														setShowModal(true);
													}}
												>
													<i className="fas fa-edit"></i>
												</button>
												<SpinnerButton
													className={`btn btn-danger`}
													title="Verwijder mapping"
													onClick={async () => {
														await mutationDeleteImportMapping.mutateAsync(mapping.id);
													}}
												>
													<i className="fas fa-trash"></i>
												</SpinnerButton>
											</>
										</td>
									</tr>
								))}
							</tbody>
						</EkoTable>
					)}
				</EkoCardBody>
			</EkoCard>
		</>
	);
};

export {CustomerImportSluitpotMappingPage};