import React, {FC, useEffect, useState} from 'react';
import {SillAssemblyOption, SillAssemblyWrapOption, UpdateSillPostAssemblyOptionsRequest, UpdateSillPreAssemblyOptionsRequest} from '../../../../../modules/api-client/generated';
import {EkoFormModal} from '../../../../../shared/components';
import {EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar} from '../../../../../shared/components/card';
import MountingOptionPicker from '../../../../../shared/components/configurator/MountingOptionPicker';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import ApiClient from '../../../../../modules/api-client/ApiClient';
import * as api from '../../../../../modules/api-client/generated';
import EkoFormField from '../../../../../shared/components/EkoFormField';
import {FieldProps} from 'formik';
import {useEkosietMutation} from '../../../../../shared/hooks/useEkosietMutation';
import {NotFound, ValidationFailed} from '../../../../../modules/api-client/Responses';
import {MountingOption, Options} from '../../../../../shared/components/configurator/model';
import WrappingOptionPicker from '../../../../../shared/components/configurator/WrappingOptionPicker';

export interface SillPostAssemblyOptionDetailsProps {
	sillId: string;
	assemblyOption: SillAssemblyOption | undefined;
}

export const SillPostAssemblyOptionDetails: React.FC<SillPostAssemblyOptionDetailsProps> = (props: SillPostAssemblyOptionDetailsProps) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const queryClient = useQueryClient();
	const [assemblyOptions, setAassemblyOptions] = useState<UpdateSillPostAssemblyOptionsRequest>();

	const updateMutation = useEkosietMutation<void, NotFound | ValidationFailed, UpdateSillPostAssemblyOptionsRequest>(
		async (body: UpdateSillPostAssemblyOptionsRequest) => {
			await ApiClient.Pim.Sill.putPostAssemblyOptions(props.sillId, undefined, body);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['ApiClient.Pim.Sill.getSill', props.sillId]).then();
			},
		}
	);

	useEffect(() => {
		if (props.assemblyOption) {
			setAassemblyOptions({
				wrap: props.assemblyOption?.wrap,
				mountingOptionId: props.assemblyOption?.mountingOptions[0]?.mountingOptionId,
			});
		}
	}, [assemblyOptions, props.assemblyOption]);

	return (
		<>
			<EkoCard className="h-100">
				<EkoCardHeader title={'Namontage'}>
					<EkoCardToolbar>
						<button type="button" className="btn btn-primary" onClick={() => setIsModalOpen(true)}>
							Wijzigen
						</button>
					</EkoCardToolbar>
				</EkoCardHeader>
				<EkoCardBody>
					<div className="row mb-4">
						<div className="col-3">
							<label className="form-label">Inpakken</label>
						</div>
						{props.assemblyOption?.wrap && (
							<div className="col">
								{props.assemblyOption?.wrap === SillAssemblyWrapOption.Yes ? 'Ja' : props.assemblyOption?.wrap === SillAssemblyWrapOption.No ? 'Nee' : 'Optioneel'}
							</div>
						)}
					</div>
					<div className="row mb-4">
						<div className="col-3">
							<label className="form-label">Bevestigingsopties</label>
						</div>
						<div className="col">{props.assemblyOption?.mountingOptions[0]?.name}</div>
					</div>
				</EkoCardBody>
			</EkoCard>

			<EkoFormModal
				title="Nabevestigingsopties wijzigen"
				values={assemblyOptions || {}}
				onConfirm={async (update) => await updateMutation.mutateAsync({mountingOptionId: update.mountingOptionId, wrap: update.wrap})}
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				modal={{size: 'lg'}}
			>
				<SillAssemblyOptionsEditForm />
			</EkoFormModal>
		</>
	);
};

export interface SillPreAssemblyOptionDetailsProps {
	sillId: string;
	assemblyOption: SillAssemblyOption | undefined;
	preAssemblyEnabled: boolean;
}

export const SillPreAssemblyOptionsDetails: React.FC<SillPreAssemblyOptionDetailsProps> = (props: SillPreAssemblyOptionDetailsProps) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [preAssemblyEnabled, setPreAssemblyEnabled] = useState(props.preAssemblyEnabled);
	const [assemblyOptions, setAassemblyOptions] = useState<UpdateSillPreAssemblyOptionsRequest>();
	const queryClient = useQueryClient();
	const [handlingSwitch, setHandlingSwitch] = useState(false);

	useEffect(() => {
		if (props.preAssemblyEnabled) {
			setAassemblyOptions({
				preAssemblyEnabled: props.preAssemblyEnabled,
				wrap: props.assemblyOption?.wrap,
				mountingOptionId: props.assemblyOption?.mountingOptions[0]?.mountingOptionId,
			});
		}
	}, [props.preAssemblyEnabled, assemblyOptions]);

	const handleSwitchChange = async (enabled: boolean) => {
		setHandlingSwitch(true);
		setPreAssemblyEnabled(!preAssemblyEnabled);
		enabled ? await ApiClient.Pim.Sill.enablePreAssembly(props.sillId) : await ApiClient.Pim.Sill.disablePreAssembly(props.sillId);
		queryClient.invalidateQueries(['ApiClient.Pim.Sill.getSill']);
		setHandlingSwitch(false);
	};

	const updateMutation = useEkosietMutation<void, NotFound | ValidationFailed, UpdateSillPreAssemblyOptionsRequest>(
		async (body: UpdateSillPreAssemblyOptionsRequest) => {
			await ApiClient.Pim.Sill.putPreAssemblyOptions(props.sillId, undefined, body);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['ApiClient.Pim.Sill.getSill', props.sillId]).then();
			},
		}
	);

	return (
		<>
			<EkoCard className="h-100">
				<EkoCardHeader title={'Voormontage'}>
					<EkoCardToolbar>
						<button type="button" className="btn btn-primary" onClick={() => setIsModalOpen(true)} disabled={!preAssemblyEnabled}>
							Wijzigen
						</button>
					</EkoCardToolbar>
				</EkoCardHeader>
				<EkoCardBody>
					<div className="row mb-4">
						<div className="col-3">
							<label className="form-label">Voormontage geactiveerd?</label>
						</div>
						<div className="col form-check form-switch ms-2">
							<input className="form-check-input" type="checkbox" role="switch" checked={preAssemblyEnabled} onChange={(event) => handleSwitchChange(event.target.checked)} />
							{handlingSwitch ? <div className="ms-2 spinner-border text-primary" role="status" /> : null}
						</div>
					</div>
					{props.assemblyOption && (
						<>
							<div className="row mb-4">
								<div className="col-3">
									<label className="form-label">Inpakken</label>
								</div>
								<div className="col">
									{props.assemblyOption.wrap === SillAssemblyWrapOption.Yes ? 'Ja' : props.assemblyOption.wrap === SillAssemblyWrapOption.No ? 'Nee' : 'Optioneel'}
								</div>
							</div>
							<div className="row mb-4">
								<div className="col-3">
									<label className="form-label">Bevestigingsopties</label>
								</div>
								<div className="col">{props.assemblyOption.mountingOptions[0]?.name}</div>
							</div>
						</>
					)}
				</EkoCardBody>
			</EkoCard>

			<EkoFormModal
				title="Voorbevestigingsopties wijzigen"
				values={assemblyOptions || {}}
				onConfirm={async (update) => await updateMutation.mutateAsync({preAssemblyEnabled: true, mountingOptionId: update.mountingOptionId, wrap: update.wrap})}
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				modal={{size: 'lg'}}
			>
				<SillAssemblyOptionsEditForm />
			</EkoFormModal>
		</>
	);
};

const SillAssemblyOptionsEditForm: FC = () => {
	let wrappingOptions: Options = {
		options: [
			{id: SillAssemblyWrapOption.Yes, name: 'Ja'},
			{id: SillAssemblyWrapOption.No, name: 'Nee'},
			{id: SillAssemblyWrapOption.Optional, name: 'Optioneel'},
		],
	};

	const {data: mountingOptionList} = useQuery<api.MountingOption[]>(['pimMountingOptionsGet'], () => ApiClient.Pim.MountingOption.pimMountingOptionsGet().then((res) => res.data));

	let mountingOptions: Options<MountingOption> = {options: []};
	if (mountingOptionList) {
		mountingOptions = {
			options: mountingOptionList.map((mountingOption) => {
				return {id: mountingOption.id!, name: mountingOption.name!};
			}),
		};
	}

	return (
		<>
			<EkoFormField
				label="Inpakken"
				row={true}
				field={{
					name: 'wrap',
					children: (props: FieldProps<string>) => <WrappingOptionPicker options={wrappingOptions} {...props.field} />,
				}}
			/>

			<EkoFormField
				label="Bevestigingsoptie"
				row={true}
				field={{
					name: 'mountingOptionId',
					children: (props: FieldProps<string>) => <MountingOptionPicker options={mountingOptions} {...props.field} />,
				}}
			/>
		</>
	);
};
