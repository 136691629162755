import React, {ChangeEvent, FC, useEffect} from 'react';
import {Field, FieldAttributes, useField} from 'formik';

export type PrefValueFieldProps = {
	field: FieldAttributes<any>;
};

export const PrefValueField: FC<PrefValueFieldProps> = (props) => {
	const [field, , helpers] = useField<{isSet: boolean; value: any}>(props.field.name);

	const isSet = typeof field.value === 'undefined' ? false : field.value.isSet;

	const fieldProps = {...props.field, name: props.field.name + '.value', disabled: !isSet};

	const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
		await helpers.setValue({isSet: e.target.checked, value: field.value?.value ?? null});
	};

	return (
		<>
			<div className="d-flex align-items-center justify-content-between">
				<div className="form-check form-switch flex-grow-0 me-2">
					<input className="form-check-input" type="checkbox" role="switch" checked={isSet} name={`${field.name}.isSet`} onChange={onChange} />
				</div>
				<div className="flex-grow-1">
					<Field {...fieldProps} />
				</div>
			</div>
		</>
	);
};
