import { FC, useEffect, useState } from 'react'
import {FilterParameter, useEkosietFilter} from "../../../../shared/context/FilterContext";

type Props = {
    fieldName: string;
    filterProperty?: string
}

export const ImportArchivedFilter: FC<Props> = (props: Props) => {
    const { setFilters, resetCount } = useEkosietFilter()
    const [archived, setArchived] = useState<boolean|undefined>(false);
    useEffect(() => {
        const filterProperty = props.filterProperty || 'isArchived';

        if (archived === true) {
            setFilters(props.fieldName, []);
        } else if (archived === false) {
            const expression = { [filterProperty]: { eq: false } };
            const filter: FilterParameter = {
                filterExpression: expression,
                filterName: props.fieldName,
                values: [false]
            };
            setFilters(props.fieldName, [filter]);
        }
    }, [archived])
    useEffect(() => {
        if (resetCount) {
            setArchived(false)
        }
    }, [resetCount])

    return (
        <>
            <div className='form-check form-switch'>
                <input className='form-check-input' type='checkbox' role='switch'
                       onChange={() => setArchived(archived === undefined ? true : !archived)}
                       checked={archived}
                       id={props.fieldName}/>
                <label className='form-check-label' htmlFor={props.fieldName}>Toon gearchiveerde imports</label>
            </div>
        </>
    )
}