/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {usePrinterContext} from '../../../context/PrinterContext';

const DymoPrinterHostInfo: React.FC = () => {
	
	const printerContext = usePrinterContext();

	if (!printerContext) {
		return null;
	}

	return (
		<>
			<strong>Dymo</strong> Host: <span className="badge badge-secondary">{printerContext.dymoPrinterHost?.name}</span>
			{printerContext.dymoPrinterHost?.port ? (
				<span className="ms-2">
					Port:<span className="badge badge-secondary ms-1">{printerContext.dymoPrinterHost?.port}</span>
				</span>
			) : (
				''
			)}
			{printerContext.dymoPrinterHost?.protocol ? (
				<span className="ms-2">
					Protocol:<span className="badge badge-secondary ms-1">{printerContext.dymoPrinterHost?.protocol}</span>
				</span>
			) : (
				''
			)}
		</>
	);
};

export {DymoPrinterHostInfo};
