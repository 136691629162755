import React, {useEffect, useState} from "react";
import SignaturePad from "signature_pad";
import {Button} from "react-bootstrap";
import {useFileUploadMutation} from "../../../../../shared/components/file-upload/useFileUploadMutation";
import {FormValues} from "../../../../../shared/components/file-upload/FileModal";
import * as api from "../../../../../modules/api-client/generated";
import {Form, Formik, FormikHelpers} from "formik";
import SubmitButton from "../../../../../shared/components/SubmitButton";
import SpinnerButtonV2 from "../../../../../shared/components/SpinnerButtonV2";

interface SignatureCanvasProps {
    title: string,
    altText: string,
    description: string,
    required: boolean,
    fileCategory: api.FileCategory,
    saveFileId: (id:string|null) => void;
}

const SignatureCanvas: React.FC<SignatureCanvasProps> = (props) => {
    const [signaturePad, setSignaturePad] = useState<SignaturePad | null>(null);
    const [file, setFile] = useState<File | null>(null);
    let {mutate, data: fileId} = useFileUploadMutation();
    const [isSignatureSaved, setSignatureSaved] = useState(false);

    const readyPad = () => {
        let wrapper = document.getElementById("signature-pad");
        let canvas = wrapper?.querySelector("canvas");
        if (canvas) {
            canvas.width = 600;
            canvas.height = 300;
            setSignaturePad(new SignaturePad(canvas, { backgroundColor: "rgb(255, 255, 255)" }));
        }
    };

    function base64ToPng(base64: string, fileName: string): File {
        const byteString = atob(base64.split(',')[1]);
        const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new File([new Blob([ab], { type: mimeString })], fileName, { type: mimeString });
    }

    const handleClearSignature = () => {
        setSignatureSaved(false);
        props.saveFileId(null);
        signaturePad?.clear();
    };

    const handleSkip = () => {
        props.saveFileId(null);
    };


    useEffect(() => {
        if(fileId && isSignatureSaved)
            props.saveFileId(fileId);
    }, [fileId, isSignatureSaved]);

    useEffect(() => {
        readyPad();
    }, []);

    const onSubmit = async (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {

        values.file = base64ToPng(signaturePad!.toDataURL(), 'signature.png');
        try {
            return await new Promise<void>((resolve, reject) => {
                mutate(values, {
                    onSuccess: () => setSignatureSaved(true),
                    onError: () => reject(),
                });
            });
        } finally {
            setSubmitting(false);
        }
    };

    const initialValues : FormValues = {
        file: file!,
        fileCategory: props.fileCategory,
        altText: props.altText,
        title: props.title,
        description: props.description,
    }

    return (
        <>

                <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
                    {({ handleSubmit, isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className="d-flex flex-column align-items-center pt-3 w-600px">
                                <div className="mt-2 d-flex justify-content-between align-items-center w-100">
                                    <div>
                                    <a href="#" className="me-3" onClick={handleClearSignature}>
                                        Reset
                                    </a>
                                        {!props.required &&
                                    <a href="#" className="me-3" onClick={handleSkip}>
                                        Overslaan
                                    </a>
                                        }
                                    </div>
                                    <SpinnerButtonV2 type="submit" className="btn btn-primary" disabled={isSignatureSaved}>
                                        {isSignatureSaved ? 'Opgeslagen' : 'Opslaan' }
                                    </SpinnerButtonV2>
                                </div>
                                <div id="signature-pad" className={'mt-3'}>
                                    <canvas className="signature-canvas border border-dark rounded-4"></canvas>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
        </>
    );
}

export {SignatureCanvas};


