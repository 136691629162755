/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {createPortal} from 'react-dom';
import {Modal, Tabs, Tab} from 'react-bootstrap';
import {DymoPrinterDevice, PrinterBrand, PrinterDevice, usePrinterContext, ZebraPrinterDevice} from '../../context/PrinterContext';
import Select from 'react-select';
import clsx from 'clsx';
import {useState} from 'react';
import {PrinterMode, PrinterModeOptions} from './PrinterConstants';
import {DymoPrinterDeviceInfo} from './dymo/DymoPrinterDeviceInfo';
import {ZebraPrinterDeviceInfo} from './zebra/ZebraPrinterDeviceInfo';
import {ZebraPrinterSettings} from './zebra/ZebraPrinterSettings';
import {DymoPrinterSettings} from './dymo/DymoPrinterSettings';
import React from 'react';
import {ZebraPrinterHostInfo} from './zebra/ZebraPrinterHostInfo';
import {DymoPrinterHostInfo} from './dymo/DymoPrinterHostInfo';
import { dymoPrinterHostList } from '../../utils/react-dymo/dymo_utils';

type Props = {
	show: boolean;
	fullscreen: true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down';
	size?: 'sm' | 'lg' | 'xl';
	onHide: () => void;
};

const modalsRoot = document.getElementById('root-modals') || document.body;

const RenderPrinterDevice = (device: PrinterDevice) => {
	switch (device.brand) {
		case PrinterBrand.Dymo:
			return <DymoPrinterDeviceInfo device={device as DymoPrinterDevice} key={device.id + device.brand} />;
		case PrinterBrand.Zebra:
			return <ZebraPrinterDeviceInfo device={device as ZebraPrinterDevice} key={device.id + device.brand} />;
		default:
			return <React.Fragment key={device.id + device.brand}>Onbekend printer device</React.Fragment>;
	}
};

const PrintersModal = ({show, onHide, size, fullscreen}: Props) => {
	const printerContext = usePrinterContext();
	const printers = printerContext?.printers;
	const zebraPrinterHost = printerContext?.zebraPrinterHost;
	const dymoPrinterHost = printerContext?.dymoPrinterHost;
	const [printerHostEdit, setPrinterHostEdit] = useState<boolean>(false);
	const printerModeSelectedValue = PrinterModeOptions.find((option: any) => option.value === printerContext?.mode) || PrinterModeOptions[0];
	const [settingsKey, setSettingsKey] = useState<string | undefined | number>('zebra');
	
	return createPortal(
		<Modal
			id="kt_printers_modal"
			tabIndex={-1}
			aria-hidden="true"
			dialogClassName={'modal-dialog modal-' + size}
			show={show}
			size={size}
			onHide={onHide}
			fullscreen={fullscreen}
			animation={true}
			backdrop={true}
		>
			<Modal.Header closeButton>
				<Modal.Title className={'text-nowrap'}>Printers ({printerContext.mode})</Modal.Title>
				<div className={'d-flex w-100 justify-content-end align-items-center'}>
					<button						
						type="button"
						className={'btn btn-secondary mx-1'}
						onClick={() => {							
							var dymoUrl = `${dymoPrinterHost?.protocol || dymoPrinterHostList[0].protocol}://${dymoPrinterHost?.name || dymoPrinterHostList[0].host}:${dymoPrinterHost?.port || dymoPrinterHostList[0].port}/DYMO/DLS/Printing/StatusConnected`;							
							window.open(dymoUrl, '_blank');							
						}}
					>
						Dymo TLS <i className="fa-solid fa-lock"></i>
					</button>
					<button
						disabled={zebraPrinterHost == null}
						type="button"
						className={'btn btn-secondary mx-1'}
						onClick={() => {							
							var zebraUrl = `${zebraPrinterHost?.protocol || 'http'}://${zebraPrinterHost?.name}:${zebraPrinterHost?.port}/available`;							
							window.open(zebraUrl, '_blank');
						}}
					>
						Zebra TLS <i className="fa-solid fa-lock"></i>
					</button>

					{printerContext.mode === PrinterMode.Manual && (
						<button
							type="button"
							className={'btn btn-secondary mx-1'}
							onClick={() => {
								setPrinterHostEdit(true);
								setSettingsKey('zebra');
							}}
						>
							Printer Host <i className="fa-solid fa-gear"></i>
						</button>
					)}

					<button
						type="button"
						className={'btn btn-secondary mx-1'}
						disabled={printerContext?.status === 'loading'}
						onClick={() => {
							printerContext.getPrinters();
						}}
					>
						<span className="indicator-label">
							Printers <i className={'fa fa-refresh pe-0'}></i>
						</span>
					</button>

					<button
						type="button"
						className={'btn btn-primary mx-3'}
						onClick={() => {
							printerContext?.resetContext();
						}}
					>
						Reset
					</button>
				</div>
			</Modal.Header>

			<Modal.Body>
				<span>Kies alleen een printer wanneer je de standaard gekozen printer wilt overrulen. De printer mode gaat dan naar handmatig.</span>
				<Select
					className={clsx('w-100 mt-2')}
					styles={{
						// Fixes the overlapping problem of the component
						menu: (provided) => ({...provided, zIndex: 9999}),
					}}
					classNamePrefix="select"
					defaultValue={printerModeSelectedValue}
					value={printerModeSelectedValue}
					isClearable={false}
					isSearchable={false}
					name={'cboPrinterMode'}
					options={PrinterModeOptions}
					onChange={(newValue) => {
						printerContext.setMode(newValue?.value);
					}}
				/>

				{printerContext.mode === PrinterMode.Manual && (
					<>
						{printerHostEdit && (
							<>
								<h4 className="mt-3">Printer Host Settings</h4>
								<Tabs activeKey={settingsKey} className="mb-3 mt-3" onSelect={(k) => setSettingsKey(k || undefined)}>
									<Tab eventKey="zebra" title="Zebra">
										<ZebraPrinterSettings setPrinterHostEdit={setPrinterHostEdit} />
										{/* todo also put setprinterhostedit in context */}
									</Tab>
									<Tab eventKey="dymo" title="Dymo">
										<DymoPrinterSettings setPrinterHostEdit={setPrinterHostEdit} />
										{/* todo also put setprinterhostedit in context */}
									</Tab>
								</Tabs>
							</>
						)}
					</>
				)}

				<div className="printers w-100 mt-5">
					{printerContext?.status === 'loading' && printerContext.mode === PrinterMode.Manual && <span>Printers worden geladen...</span>}

					{printerContext?.status === 'loading' && printerContext.mode === PrinterMode.Automatic && <span>Printers worden gezocht (dit kan even duren)...</span>}

					{printerContext?.status === 'error' && !(printerContext.mode === PrinterMode.Manual && !printerContext.manualSelectedPrinter) && (
						<div className="alert alert-danger" role="alert">
							Fout bij ophalen Dymo of Zebra printers. Controleer of de gekozen host goed staat en of de printer webservice nog werkt.
						</div>
					)}

					{(printerContext?.status === 'success' || (printerContext?.status === 'error' && printerContext?.printers && printerContext?.printers?.length > 0)) && (
						<div className="mt-2">
							Gevonden printers: {printers ? <span className="badge badge-secondary">{printers.length}</span> : <span className="badge badge-secondary ms-2">0</span>}{' '}
							{printerContext.mode === PrinterMode.Manual && !printerContext.manualSelectedPrinter ? <span className="text-danger ms-2">kies een printer</span> : ''}
							{printers && printers.filter((printer) => printer !== undefined && printer !== null).map((printer) => RenderPrinterDevice(printer))}
						</div>
					)}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="w-100">
					<div className="col-12 m-2">
						<ZebraPrinterHostInfo />
					</div>
					<div className="col-12 m-2">
						<DymoPrinterHostInfo />
					</div>
				</div>
			</Modal.Footer>
		</Modal>,
		modalsRoot
	);
};

export {PrintersModal};
