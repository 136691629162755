/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'


type Props = {
    contactPerson?: {
        firstName?: string | null;
        lastName?: string | null;
        telephone?: string | null;
        email?: string | null;
    };
    inline?: boolean;

}
const ContactPerson: React.FC<Props> = ({contactPerson,inline = false}) => {
    if (!contactPerson) return (<></>)

    const contactLine = `${contactPerson.firstName ?? ''} ${contactPerson.lastName ?? ''}, ${contactPerson.telephone ?? ''}, ${contactPerson.email ?? ''}`;

    return (
        <div>
            {inline ? (
                <span>{contactLine}</span>
            ) : (
                <>
                    <span>{contactPerson.firstName ?? ''} {contactPerson.lastName ?? ''}</span><br />
                    <span>t: {contactPerson.telephone ?? ''}</span><br />
                    <span>e: {contactPerson.email ?? ''}</span>
                </>
            )}
        </div>
    );
}

export {ContactPerson}
