import React, {useCallback, useRef} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';

import {EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar} from '../../../../shared/components/card';
import {ConfirmDialog} from '../../../../shared/components/ConfirmDialog';
import {PimRoutePath} from '../PimRoutes';
import Loading from '../../../../shared/components/Loading';
import {EkoTable, TableHeader} from '../../../../shared/components/table';
import {KTSVG} from '../../../../shared/components/KTSVG';
import {SillType} from '../../../../modules/api-client/generated';
import {BooleanText, Mm} from '../../../../shared/components';
import {SillDetailsNeutPositionsCard} from '../sill/SillDetails/SillDetailsNeutPositionsCard';
import {useSillQuery} from '../sill/hooks/use-sill-query';
import {useSillDeleteMutation} from '../sill/hooks/use-sill-delete-mutation';
import {SillDetailsCompartmentLayoutsCard} from '../sill/SillDetails/SillDetailsCompartmentLayoutsCard';
import {SillDetailsCard} from '../sill/SillDetails/SillDetailsCard';
import NotFound from '../../../../shared/components/NotFound';
import {SillVariantsCard} from '../sill/SillDetails/SillVariantsCard';
import {SillPostAssemblyOptionDetails, SillPreAssemblyOptionsDetails} from '../sill/SillDetails/SillAssemblyOptionsDetails';
import {SillAerationAndDrainageCard} from '../sill/SillDetails/SillAerationAndDrainageCard';

const SillDetailsPage: React.FC = () => {
	const {id} = useParams<{id: string}>();

	const {data: sill, isInitialLoading, remove: invalidateQuery} = useSillQuery(id);

	const navigate = useNavigate();

	const openDeleteDialog = useRef<(show: boolean) => void>();

	const deleteMutation = useSillDeleteMutation(id!);

	const deleteCallback = useCallback(async () => {
		await deleteMutation.mutateAsync();
		navigate(PimRoutePath.link(PimRoutePath.sills));
	}, [deleteMutation, navigate]);

	if (isInitialLoading) return <Loading />;

	if (!sill) return <NotFound />;

	return (
		<>
			<SillDetailsCard sill={sill} />

			<SillVariantsCard sill={sill} onChanged={invalidateQuery} />

			<SillDetailsCompartmentLayoutsCard sill={sill} />

			<SillDetailsNeutPositionsCard sill={sill} />

			<EkoCard className={'mb-3'}>
				<EkoCardHeader title={'Neuten'}>
					<EkoCardToolbar>
						<button type="button" className="btn btn-primary" disabled>
							Wijzigen (TODO)
						</button>
					</EkoCardToolbar>
				</EkoCardHeader>
				<EkoCardBody>
					<div className="row mb-4">
						<div className="col-xxl-2 col-6">
							<label className="form-label">Heeft neuten?</label>
						</div>
						<div className="col">
							<BooleanText value={sill.neutOptions.hasNeuten} />
						</div>
					</div>

					{sill.neutOptions.hasNeuten && (
						<>
							<div className="row mb-4">
								<div className="col-xxl-2 col-6">
									<label className="form-label">Standaard neut hoogte</label>
								</div>
								<div className="col">
									<Mm value={sill.neutOptions.defaultNeutHeight} />
								</div>
							</div>
							<div className="row mb-4">
								<div className="col-xxl-2 col-6">
									<label className="form-label">Afwijkende hoogte mogelijk</label>
								</div>
								<div className="col">
									<BooleanText value={sill.neutOptions.customHeight} />
								</div>
							</div>

							<div className="row mb-4">
								<div className="col-xxl-2 col-6">
									<label className="form-label">Standaard breedtes</label>
								</div>
								<div className="col">
									{sill.neutOptions.standardWidths?.map((w, index) => (
										<span key={index}>
											{index > 0 ? ', ' : ''}
											<Mm value={w} />
										</span>
									))}
								</div>
							</div>

							<div className="row mb-4">
								<div className="col-xxl-2 col-6">
									<label className="form-label">Afwijkende breedte mogelijk</label>
								</div>
								<div className="col">
									<BooleanText value={sill.neutOptions.customWidth} />
								</div>
							</div>

							<div className="row mb-4">
								<div className="col-xxl-2 col-6">
									<label className="form-label">Minimale breedte</label>
								</div>
								<div className="col">
									<Mm value={sill.neutOptions.minWidth} />
								</div>
							</div>

							<div className="row mb-4">
								<div className="col-xxl-2 col-6">
									<label className="form-label">Positie bevestigingsgat gefixeerd</label>
								</div>
								<div className="col">
									<BooleanText value={sill.neutOptions.drillHoleOptions?.fixedPositions} />
								</div>
							</div>

							<div className="row mb-4">
								<div className="col-xxl-2 col-6">
									<label className="form-label">Positioneringsstrategie bevestigingsgaten horizontaal</label>
								</div>
								<div className="col">{sill.neutOptions.drillHoleOptions?.positioningStrategyName}</div>
							</div>

							<div className="row mb-4">
								<div className="col-xxl-2 col-6">
									<label className="form-label">Positie bevestigingsgat #1</label>
								</div>
								<div className="col">
									<Mm value={sill.neutOptions.drillHoleOptions?.defaultYPositions?.drillHole1} />
								</div>
							</div>
							<div className="row mb-4">
								<div className="col-xxl-2 col-6">
									<label className="form-label">Positie bevestigingsgat #2</label>
								</div>
								<div className="col">
									<Mm value={sill.neutOptions.drillHoleOptions?.defaultYPositions?.drillHole2} />
								</div>
							</div>
						</>
					)}
				</EkoCardBody>
			</EkoCard>

			<div className="row">
				<div className="col mb-3">
					<SillPreAssemblyOptionsDetails sillId={id ?? ''} assemblyOption={sill.assemblyOptions.preAssembly} preAssemblyEnabled={sill.assemblyOptions.preAssemblyEnabled} />
				</div>
				<div className="col mb-3">
					<SillPostAssemblyOptionDetails sillId={sill.id} assemblyOption={sill.assemblyOptions.postAssembly} />
				</div>
			</div>

			{(sill.type === SillType.Binnensponning || sill.type === SillType.Vouwwand) && <SillAerationAndDrainageCard sillId={sill.id} options={sill.aerationAndDrainageOptions ?? null} />}

			<EkoCard className={'mb-3'}>
				<EkoCardHeader title={'Sluitpotgebied'}>
					<EkoCardToolbar>
						<EkoCardToolbar>
							<Link to={'./sluitpot-areas/add'} className="btn btn-primary">
								Toevoegen
							</Link>
						</EkoCardToolbar>
					</EkoCardToolbar>
				</EkoCardHeader>
				<EkoCardBody>
					<EkoTable>
						<TableHeader>
							<th className="fit-content">Code</th>
							<th>Naam</th>
							<th className="fit-content text-end">Gebied</th>
							<th className="fit-content text-end">Basis-Y</th>
							<th className="fit-content text-end">Basis-Z</th>
							<th className="fit-content text-end">Hoek</th>
						</TableHeader>
						<tbody>
							{sill.areas?.map((a, index) => (
								<tr key={index}>
									<td className="fit-content">{a.code}</td>
									<td>{a.name}</td>
									<td className="fit-content text-end ">
										{a.from}-{a.to}mm
									</td>
									<td className="fit-content text-end">{a.originY}mm</td>
									<td className="fit-content text-end">{a.originZ}mm</td>
									<td className="fit-content text-end">{a.angle} graden</td>
									<td className="fit-content text-end">
										<Link to={`./sluitpot-areas/${a.id}`} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
											<KTSVG path="/media/icons/duotune/art/art005.svg" className="svg-icon-3" />
										</Link>
									</td>
								</tr>
							))}
						</tbody>
					</EkoTable>
				</EkoCardBody>
			</EkoCard>

			{/*<EkoCard className={'mb-3'}>*/}
			{/*	<EkoCardHeader title={'Configurator'}>*/}
			{/*		<EkoCardToolbar>*/}
			{/*			<Link to='configurator' relative='path' className='btn btn-primary ms-2'>*/}
			{/*				Beheer Configurator*/}
			{/*			</Link>*/}
			{/*		</EkoCardToolbar>*/}
			{/*	</EkoCardHeader>*/}
			{/*	<EkoCardBody>*/}
			{/*		{!isInitialLoadingOptions && !isErrorOptions && options && (*/}
			{/*			<>*/}
			{/*				<div className='mb-5'>*/}
			{/*					<div className='row'>*/}
			{/*						<div className='col-2'>*/}
			{/*							<label className='form-label'>Maximale lengte</label>*/}
			{/*						</div>*/}
			{/*						<div className='col'>{options?.maxLength}mm</div>*/}
			{/*					</div>*/}

			{/*					<div className='row'>*/}
			{/*						<div className='col-2'>*/}
			{/*							<label className='form-label'>Kleur</label>*/}
			{/*						</div>*/}
			{/*						<div className='col'>*/}
			{/*							<BadgeList options={options.colors.options} default={options.colors.default} />*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</>*/}
			{/*		)}*/}
			{/*	</EkoCardBody>*/}
			{/*</EkoCard>*/}

			<EkoCard className={'mb-3'}>
				<EkoCardHeader title={'Gevarenzone'}></EkoCardHeader>
				<EkoCardBody>
					<div className="row">
						<div className="col-10">
							<strong>Dorpel archiveren</strong>
							<p>Een gearchiveerde dorpel kan niet meer worden besteld.</p>
						</div>
						<div className="col d-flex justify-content-end">
							<div>
								<button type="button" className="btn btn-secondary" disabled>
									Dorpel archiveren (TODO)
								</button>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-10">
							<strong>Dorpel verwijderen</strong>
							<p>Verwijder deze dorpel definitief.</p>
						</div>
						<div className="col d-flex justify-content-end">
							<div>
								<button type="button" className="btn btn-danger" disabled={false} onClick={() => openDeleteDialog.current?.call(this, true)}>
									Dorpel verwijderen
								</button>
								<ConfirmDialog
									show={openDeleteDialog}
									onConfirm={deleteCallback}
									dialogStyle="danger"
									title="Verwijderen"
									message="Je staat op het punt deze dorpel te verwijderen. Wil je doorgaan?"
									confirmText="Dorpel verwijderen"
								/>
							</div>
						</div>
					</div>
				</EkoCardBody>
			</EkoCard>
		</>
	);
};

export default SillDetailsPage;
