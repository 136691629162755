import React from 'react';
import {AssemblyMethod} from '../../../modules/api-client/generated';
import {FieldProps} from 'formik';
import { AssemblyMethodText } from './AssemblyMethodText';

export const AssemblyMethodField: React.FC<React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>> = (props) => {
	props = {...props, value: props.value ?? '', className: 'form-select'};

	return (
		<>
			<select {...props}>
				<option value={''}>
					<AssemblyMethodText value={undefined} />
				</option>
				<option value={AssemblyMethod.PreAssembly}>
					<AssemblyMethodText value={AssemblyMethod.PreAssembly} />
				</option>
				<option value={AssemblyMethod.PostAssembly}>
					<AssemblyMethodText value={AssemblyMethod.PostAssembly} />
				</option>
			</select>
		</>
	);
};

export const AssemblyMethodComponent = (): React.ComponentType<FieldProps<string>> => {
	return ({field, form, ...props}) => {
		return <AssemblyMethodField {...field} {...props} />;
	};
};