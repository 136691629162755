import React from 'react';
import {AssemblyMethod} from '../../../modules/api-client/generated';

export interface AssemblyMethodFieldProps {
	value: AssemblyMethod | undefined;
}

export const AssemblyMethodText: React.FC<AssemblyMethodFieldProps> = (props) => {
	switch (props.value) {
		case undefined:
			return <>Selecteer...</>;
		case AssemblyMethod.PreAssembly:
			return <>Voormontage (Neofix)</>;
		case AssemblyMethod.PostAssembly:
			return <>Namontage (Neo)</>;
		default:
			return <></>;
	}
};