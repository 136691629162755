import React from 'react';

export interface InsulatedFieldProps {
	value: boolean | undefined | null;
}

export const InsulatedText: React.FC<InsulatedFieldProps> = (props) => {
	switch (props.value) {
		case null:
			return <>Selecteer...</>;
		case true:
			return <>Volledige isolatie, PU schuim</>;
		case false:
			return <>Isolatie (Kopisolatoren)</>;
		default:
			return <></>;
	}
};