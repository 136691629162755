import {FC} from 'react';
import * as Yup from 'yup';
import {ErrorMessage, Form, Formik, FormikHelpers, FormikProps} from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {Col, Row} from 'react-bootstrap';
import {usePutPlannedProductionAndPlannedExpeditionDate} from '../../../hooks/use-put-production-and-expedition-date';
import { DateTime } from 'luxon';
import SpinnerButtonV2 from "../../../../../../../shared/components/SpinnerButtonV2";
type Props = {
	id: string;
	plannedProductionDate?: Date | null;
	plannedExpeditionDate?: Date | null;
	updateStateToPlanned: boolean;
	onSuccess: (plannedProductionDate: Date) => void;
	handleClose: () => void;
	defaultNumberOfDaysBeforeExpedition?: number;
};

type FormValues = {
	plannedProductionDate?: Date | null;
	plannedExpeditionDate?: Date | null;
	updateStateToPlanned: boolean;
};

const PutPlannedProductionAndExpeditionDateForm: FC<Props> = ({id, handleClose, onSuccess, plannedProductionDate, plannedExpeditionDate, updateStateToPlanned, defaultNumberOfDaysBeforeExpedition = 5}) => {
	const mutate = usePutPlannedProductionAndPlannedExpeditionDate(id);


	const defaultStartDate =  DateTime.now().plus({days:7})

	const initialValues: FormValues = {
		plannedProductionDate: plannedProductionDate ?? defaultStartDate.minus({ days: defaultNumberOfDaysBeforeExpedition }).toJSDate(),
		plannedExpeditionDate: plannedExpeditionDate ?? defaultStartDate.toJSDate(),
		updateStateToPlanned,
	};

	const formSchema = Yup.object().shape({
		plannedProductionDate: Yup.date()
			.required('Productiedatum is verplicht')
			.min(new Date(new Date().setHours(0, 0, 0, 0)), 'Productiedatum kan niet in het verleden liggen')
		.when('plannedExpeditionDate', (plannedExpeditionDate, schema) => (plannedExpeditionDate ? schema.max(plannedExpeditionDate, 'Productiedatum moet voor de leveringsdatum zijn') : schema)),

		plannedExpeditionDate: Yup.date()
			.required('Leveringsdatum is verplicht')
			.min(new Date(new Date().setHours(0, 0, 0, 0)), 'Productiedatum kan niet in het verleden liggen')

	});

	const onSubmit = async (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {
		try {
			await mutate.mutateAsync({
				production: {
					plannedProductionDate: moment(values.plannedProductionDate).format(moment.HTML5_FMT.DATE),
				},
				expedition: {
					plannedExpeditionDate: moment(values.plannedExpeditionDate).format(moment.HTML5_FMT.DATE),
					updateStateToPlanned: values.updateStateToPlanned,
				},
			});

			if (values.plannedProductionDate) {
				onSuccess(values.plannedProductionDate);
			}
		} catch (error) {
		} finally {
			setSubmitting(false);
		}
	};


	const handlePlannedExpeditionDateChange = (date: Date | null, formik: FormikProps<FormValues>) => {
		if (date) {
			formik.setFieldValue('plannedExpeditionDate', date);

			const newPlannedProductionDate = new Date(date);
			newPlannedProductionDate.setDate(newPlannedProductionDate.getDate() - defaultNumberOfDaysBeforeExpedition);
			formik.setFieldValue('plannedProductionDate', newPlannedProductionDate);
			formik.setTouched({plannedProductionDate: true});
		}
	};
	const highlightExpeditionDate = (date: Date) => {
		return date.getTime() === initialValues.plannedExpeditionDate?.getTime() ? 'bg-warning' : '';
	};

	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
			{(formik) => {
				return (
					<Form>
						<Row>
							<Col xs={12} md={6}>
								<div className="d-flex flex-column mb-3 align-items-center ">
									<label className="form-label">Geplande Leverdatum</label>
									<div >
									<DatePicker
										dayClassName={() => "p-2 w-40px"}
										weekDayClassName={() => "p-2 w-40px"}
										inline={true}
										minDate={new Date()}
										selected={formik.values.plannedExpeditionDate}
										onChange={(date: Date | null) => handlePlannedExpeditionDateChange(date, formik)}
										locale="nl-NL"
										showWeekNumbers
										dateFormat="dd-MM-yyyy"
										className="form-control"
									/>
									<ErrorMessage name="plannedExpeditionDate" component="div" className="text-danger" />
									</div>

								</div>
							</Col>
							<Col xs={12} md={6}>
								<div className="d-flex flex-column mb-3 align-items-center ">

								<label className="form-label">Geplande Productiedatum</label>
									<div>

									<DatePicker
										dayClassName={() => "p-2 w-40px"}
										weekDayClassName={() => "p-2 w-40px"}
										monthClassName={() => "text-warning"}
										currentMonthClassName={() => "text-warning"}
										minDate={new Date()}
										title={'Geplande productiedatum'}
										maxDate={formik.values.plannedExpeditionDate}
										inline={true}
										selected={formik.values.plannedProductionDate}
										onChange={(date: Date | null) => formik.setFieldValue('plannedProductionDate', date)}
										dateFormat="dd-MM-yyyy"
										locale="nl-NL"
										showWeekNumbers
										className="form-control"
									/>
									</div>

									<ErrorMessage name="plannedProductionDate" component="div" className="text-danger" />
								</div>
							</Col>


						</Row>

						<div className="text-center pt-4">
							<button type="button" className="btn btn-light me-3" onClick={handleClose}>
								Annuleren
							</button>
							<SpinnerButtonV2 type="submit" className="btn btn-info">
								<span className="indicator-label">Order accepteren</span>
							</SpinnerButtonV2>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

export {PutPlannedProductionAndExpeditionDateForm};
