import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {
    ExpeditionsByDate,
    TransportPlanningByOrderViewArrayODataValue
} from "../../../../../modules/api-client/generated";
import { AxiosError } from "axios";
import {DateTime} from "luxon";

export const useExpeditionsByDate = (date: DateTime, enabled: boolean | undefined = true):
    UseQueryResult<ExpeditionsByDate, unknown> => {


    var key = 'emExpeditionsByDateGet';

    const response = useQuery([key, date], () => {
        return ApiClient.Em.Expedition
            .expeditionsByDay(date.toFormat("yyyy-MM-dd"))
            .then((d) => d.data);
    },
        { enabled: enabled }
    )

    if (response.error) {
        var error = response.error as AxiosError;
        toast.error(error?.message);
        // toast.error(error?.response?.data?.title || 'Onbekende fout!');
    }

    return response;
};
