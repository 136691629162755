import {useQueryClient} from '@tanstack/react-query';
import {useEkosietMutation} from '../../../../../shared/hooks/useEkosietMutation';
import * as api from '../../../../../modules/api-client/generated';
import {CreateSillCommand, UpdateSillRequest} from '../../../../../modules/api-client/generated';
import {NotFound, ValidationFailed} from '../../../../../modules/api-client/Responses';
import {SillFormValues} from '../SillUpsert';
import ApiClient from '../../../../../modules/api-client/ApiClient';

export const useSillUpsertMutation = (id?: string | undefined) => {
	const queryClient = useQueryClient();

	return useEkosietMutation<void, NotFound | ValidationFailed, SillFormValues>(
		async (values) => {
			const command: CreateSillCommand | UpdateSillRequest = {
				code: values.code,
				active: values.active,
				name: values.name,
				type: values.type as api.SillType,
				width: values.width as number,
				rabbetWidth: values.rabbetWidth as number,
				description: values.description || null,
				assemblyOptions: values.assemblyOptions,
				insulatedCatalogItem: values.catalogItemInsulated,
				uninsulatedCatalogItem: values.catalogItemUninsulated,
			};

			if (id) {
				await ApiClient.Pim.Sill.updateSill(id, undefined, command);
			} else {
				await ApiClient.Pim.Sill.createSill(undefined, command);
			}
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['ApiClient.Pim.Sill.getSill', id]).then();
			},
		}
	);
};
