/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {PrinterSelector, usePrinterContext} from '../../context/PrinterContext';
import {PrinterLabelId, PrinterMode} from './PrinterConstants';

type LabelPrinterProps = {
	printerSelector: PrinterSelector;
	labelId: PrinterLabelId;
	data: any;
};

const LabelPrinter: React.FC<LabelPrinterProps> = ({printerSelector, labelId, data}) => {
	const printerContext = usePrinterContext();
	const {status, mode, printById, usePrinterDevice} = printerContext;
	const usedPrinterDevice = usePrinterDevice(printerSelector);
	const [printing, setPrinting] = useState<boolean | undefined>();

	if (!printerContext) {
		return (
			<span className="alert alert-danger" role="alert">
				Geen printer context
			</span>
		);
	}

	if (mode === PrinterMode.Automatic && !printerSelector) {
		return (
			<span className="alert alert-danger" role="alert">
				Printer mode is automatisch maar de software heeft geen printer gedefinieerd. Selecteer handmatig een printer.
			</span>
		);
	}

	if (status === 'loading' && mode === PrinterMode.Manual) {
		return <span>Printers worden geladen...</span>;
	}

	if (status === 'loading' && mode === PrinterMode.Automatic) {
		return <span>Printers worden gezocht (dit kan even duren)...</span>;
	}

	if (!usedPrinterDevice) {
		if (printerSelector) {
			return (
				<span className="alert alert-danger" role="alert">
					Geen printer `{printerSelector.id}` gevonden. Selecteer handmatig een printer.
				</span>
			);
		} else {
			return (
				<span className="alert alert-danger" role="alert">
					Geen printer gevonden. Selecteer handmatig een printer.
				</span>
			);
		}
	}

	return (
		<div>
			<button
				disabled={!usedPrinterDevice || printing}
				className="btn btn-sm btn-primary"
				onClick={async () => {
					if (usedPrinterDevice) {
						setPrinting(true);
						try {
							await printById(labelId, data, usedPrinterDevice);
						} finally {
							setPrinting(false);
						}
					}
				}}
			>
				Print label
			</button>
			<span className="ms-2 text-muted">
				'{usedPrinterDevice.name}' geselecteerd, printer mode: {mode}
			</span>
		</div>
	);
};

export {LabelPrinter};
