/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { CustomerFilter } from '../../../backoffice/modules/crm/components/CustomerFilter';
import { useEkosietFilter } from '../../context/FilterContext';
import { ImportArchivedFilter } from '../../../backoffice/modules/crm/components/ImportArchivedFilter';

const ImportSearch: React.FC = () => {

    const { reset } = useEkosietFilter()

    return (
        <>
            <div className="row mb-3"></div>

            <div className="row mb-3">
                <div className="col">
                    <CustomerFilter fieldName='filterCustomerId'/>
                </div>
                <div className={'col'}>
                    <div className={'d-flex align-items-center h-100'}>
                        <ImportArchivedFilter fieldName={'isArchived'}/>
                    </div>
                </div>
                <div className="col col-lg-2 ms-auto d-flex justify-content-end align-items-center">
                    <button className="btn btn-secondary btn-secondary-icon btn-sm" type='reset' onClick={reset}>
                        <span>
                            <i className="la la-close"></i>
                            <span>Reset filters</span>
                        </span>
                    </button>
                </div>
            </div>
        </>
    )
}

export {ImportSearch}