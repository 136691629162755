/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {
    ImportStopProfileMappingRepresentation, MatrixConfigurationLineRepresentation,
} from "../../../../../modules/api-client/generated";
import {EkoTable, TableHeader} from "../../../../components/table";
import {useEkosietAuth0} from "../../../../../modules/auth0/core/useEkosietAuth0";
import SpinnerButton from "../../../../components/SpinnerButton";
import {Button} from "react-bootstrap";
import {ImportModal} from "../../../components/ImportModal";
import {EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar} from "../../../../components/card";
import Loading from "../../../../components/Loading";
import {useDeleteStopProfileMapping, useGetImportStopProfileMappings} from "../../../hooks/use-stop-profile-mapping";
import ImportStopProfileMappingUpsert from "../../../components/mapping/ImportStopProfileMappingUpsert";
import {useDeleteLine, useGetMatrixConfigurationLines} from "../../../hooks/matrix/use-matrix-configuration-mapping";
import MatrixConfigurationLineMappingUpsert
    from "../../../components/mapping/matrix/MatrixConfigurationLineMappingUpsert";


export const MatrixCatalogItemMappingPage: React.FC = () => {

    const {isKlant} = useEkosietAuth0();

    const {
        isInitialLoading,
        data: matrixConfigurationLines,
        isError
    } = useGetMatrixConfigurationLines();
    const [selectedMapping, setSelectedMapping] = useState<MatrixConfigurationLineRepresentation | undefined>(undefined);
    const [showModal, setShowModal] = useState<boolean>(false);
    var mutationDeleteImportMapping = useDeleteLine();

    if (isInitialLoading) {
        return <Loading/>;
    }

    if (isError) {
        return <>Fout bij het ophalen van de koppelingen!</>;
    }

    return (
        <>
            <ImportModal show={showModal} handleClose={() => setShowModal(false)}
                         title={'Koppeling toevoegen of aanpassen'}
                         size={'lg'}
                         component={
                             isKlant ?
                                 <>Alleen Ekosiet medewerkers kunnen deze koppeling beheren</>
                                 :
                                 <MatrixConfigurationLineMappingUpsert
                                     id={selectedMapping?.id}
                                     handleSuccess={
                                         () => {
                                             setSelectedMapping(undefined);
                                             setShowModal(false)
                                         }
                                     }
                                 />
                         }
                         fullscreen={'md-down'}/>
            <EkoCard>
                <EkoCardHeader title={'Matrix Artikelen'}>
                    <EkoCardToolbar>
                        {!isKlant &&
                            <Button className={'btn btn-primary'} onClick={() => setShowModal(true)}>Toevoegen</Button>
                        }
                    </EkoCardToolbar>
                </EkoCardHeader>
                <EkoCardBody>
                    <p className={'lead'}>
                        Onderstaande artikelen worden geexporteerd in de Matrix configuratie file. Deze file wordt
                        gebruikt om de Matrix instantie van de klant in te richten. De klant specifieke configuratie
                        file kan gedownload worden via het import tab op de klantkaart.
                    </p>
                    {(!matrixConfigurationLines) ?
                        <div className={'alert alert-warning'}>
                            Er zijn nog geen koppelingen.
                        </div>
                        :
                        <EkoTable>
                            <TableHeader>
                                <th>Matrix code</th>
                                <th>Omschrijving</th>
                                <th>Ekosiet Catalogus Item</th>
                                <th>DXF</th>
                                <th>Matrix kortingsgroep</th>
                                <th></th>
                            </TableHeader>
                            <tbody>
                            {matrixConfigurationLines.map((line) => (
                                <tr key={line.id}>
                                    <td>{line.code}</td>
                                    <td>{line.omschrijving}</td>
                                    <td>{line.catalogItem ? <>{line.catalogItem.code}</>:<><i className={'fa fa-triangle-exclamation text-danger'}/> </>}</td>
                                    <td>{line.dxfBestand}
                                        {line.dxfBlock && <><br/>{line.dxfBlock}</>}
                                        </td>
                                    <td>todo</td>
                                    <td>
                                        {!isKlant &&
                                            <>
                                                <button
                                                    type="button"
                                                    className={`btn btn-secondary me-2 `}
                                                    title="Wijzig Mapping"
                                                    onClick={() => {
                                                        setSelectedMapping(line);
                                                        setShowModal(true);
                                                    }}
                                                >
                                                    <i className="fas fa-edit"></i>
                                                </button>
                                                <SpinnerButton
                                                    className={`btn btn-danger`}
                                                    title="Verwijder mapping"
                                                    onClick={async () => {
                                                        await mutationDeleteImportMapping.mutateAsync(line.id);
                                                    }}
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </SpinnerButton>
                                            </>
                                        }
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </EkoTable>
                    }
                </EkoCardBody>
            </EkoCard>
        </>
    );
};

