/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {WeeklyStatsTile} from "../../sm/components/WeeklyStatsTile";
import {YTDStatsTile} from "../../sm/components/YTDStatsTile";
import {toAbsoluteUrl} from "../../../../../_metronic/helpers";
import {YTDProductionStatsTile} from "../../sm/components/YTDProductionStatsTile";
import {WeeklyProductionStatsTile} from "../../sm/components/WeeklyProductionStatsTile";
import {WeeklyExpeditionStatsTile} from "../../sm/components/WeeklyExpeditionStatsTile";
import {YTDExpeditionStatsTile} from "../../sm/components/YTDExpeditionStatsTile";
import {WeeklyLevertijdStatsTile} from "../../em/expedition/components/WeeklyLevertijdTile";
import {WeeklyOrdersPlacedByTile} from "../../sm/components/WeeklyOrdersPlacedByTile";
import {WeeklyOrdersImportStatsTile} from '../../sm/components/WeeklyOrdersImportStatsTile';

export function MgtDashboard() {
    return (
        <>
            <PageTitle>Management dashboard</PageTitle>
            <div className='row'>
                <div className='col-12 col-lg-4 mb-md-5'>
                    <WeeklyOrdersImportStatsTile />
                </div>
                <div className='col-12 col-lg-4 mb-md-5'>
                    <WeeklyOrdersPlacedByTile />
                </div>
                <div className='col-12 col-lg-4 mb-md-5'>
                    <WeeklyLevertijdStatsTile />
                </div>
            </div>
            <div className='row'>
                <div className='col-12 col-lg-4 mb-md-5'>
                    <WeeklyStatsTile />
                </div>
                <div className='col-12 col-lg-4 mb-md-5'>
                    <WeeklyProductionStatsTile />
                </div>
                <div className='col-12 col-lg-4 mb-md-5'>
                    <WeeklyExpeditionStatsTile />
                </div>
            </div>
            <div className='row'>
                <div className='col-12 col-lg-4 mb-md-5'>
                    <YTDStatsTile
                        className='mb-5 mb-xl-10'
                        description='Dit jaar'
                        color='#F1416C'
                        img={toAbsoluteUrl('/media/patterns/vector-1.png')}/>
                </div>
                <div className='col-12 col-lg-4 mb-md-5'>
                    <YTDProductionStatsTile
                        className='mb-5 mb-xl-10'
                        description='Dit jaar'
                        color='#F1416C'
                        img={toAbsoluteUrl('/media/patterns/vector-1.png')}/>
                </div>
                <div className='col-12 col-lg-4 mb-md-5'>
                    <YTDExpeditionStatsTile
                        className='mb-5 mb-xl-10'
                        description='Dit jaar'
                        color='#F1416C'
                        img={toAbsoluteUrl('/media/patterns/vector-1.png')}/>
                </div>
            </div>

        </>
    )
}
