import {useQueryClient} from '@tanstack/react-query';
import ApiClient from '../../../modules/api-client/ApiClient';
import {useEkosietMutation} from '../../hooks/useEkosietMutation';
import {NotFound, ValidationFailed} from '../../../modules/api-client/Responses';

export const useArchiveImport = () => {
	const queryClient = useQueryClient()

	return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
		return ApiClient.Acl.Import.aclImportIdArchivePost(id, undefined).then(response => response.data);
	},
	{
		onSuccess: () => {
			queryClient.invalidateQueries(['aclImportListOdataGet']).then();
		}
	});
}