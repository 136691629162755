import { FC } from 'react';
import { PrefValueRow } from './PrefValueRow';
import { AssemblyMethodComponent } from '../configurator/AssemblyMethodField';
import { InsulatedComponent } from '../configurator/InsulatedField';
import { FastenersIncludedComponent } from '../configurator/FastenersIncludedField';

export const CustomerPreferencesCommon: FC = () => {
	return (
		<>
			<PrefValueRow
				label={'Montagemethode'}
				field={{
					name: 'assemblyMethod',
					component: AssemblyMethodComponent(),
				}}
			/>

			<PrefValueRow
				label={'Isolatie'}
				field={{
					name: 'insulated',
					component: InsulatedComponent(),
				}}
			/>

			{/*<PrefValueRow*/}
			{/*	label={'Bevestigingsmiddelen meeleveren'}*/}
			{/*	field={{*/}
			{/*		name: 'fastenersIncluded',*/}
			{/*		component: FastenersIncludedComponent(),*/}
			{/*	}}*/}
			{/*/>*/}
		</>
	);
};