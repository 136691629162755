/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react';
import {Link, Outlet, useLocation} from 'react-router-dom';
import {useEkosietAuth0} from '../../../../modules/auth0/core/useEkosietAuth0';
import {ImportRoutePath} from '../../routes/ImportRoutes';
import {PageTitle} from '../../../../../_metronic/layout/core';
import clsx from 'clsx';
import {checkIsActive, WithChildren} from '../../../../../_metronic/helpers';

export const ImportMappingPage: React.FC = () => {
	const {isKlant} = useEkosietAuth0();

	return (
		<>
			<PageTitle>Import koppelingen beheren</PageTitle>
			<div className={'row'}>
				<div className={'col-12 col-lg-2'}>
					<div className="app-sidebar-menu overflow-hidden flex-column-fluid">
						<div className="app-sidebar-wrapper hover-scroll-overlay-y ">
							<div className="menu menu-column menu-rounded menu-sub-indention" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
								{isKlant && (
									<>
										<div className="menu-item">
											<div className="menu-content pt-8 pb-2">
												<span className="menu-section text-muted text-uppercase fs-8 ls-1">Klant specifieke GA koppelingen</span>
											</div>
										</div>
										<MenuItem to={`${ImportRoutePath.importGaProfileMapping}`} title={'Vakprofielen'} />
										<MenuItem to={`${ImportRoutePath.importGaSluitpotMapping}`} title={'Sluitpotten'} />
									</>
								)}
								<div className="menu-item">
									<div className="menu-content pt-8 pb-2">
										<span className="menu-section text-muted text-uppercase fs-8 ls-1">Globale GA koppelingen</span>
									</div>
								</div>
								<MenuItem to={`${ImportRoutePath.importGaSillTypeMapping}`} title={'Dorpeltypes'} />
								<MenuItem to={`${ImportRoutePath.importGaCompartmentLayoutMapping}`} title={'Vakindelingen'} />
								<MenuItem to={`${ImportRoutePath.importGaStopProfileMapping}`} title={'Aanslagprofielen'} />
								<MenuItem to={`${ImportRoutePath.importGaGlazingprofileMapping}`} title={'Beglazingsprofielen'} />
								<MenuItem to={`${ImportRoutePath.importGaGlazingbarMapping}`} title={'Glaslatten'} />
								<MenuItem to={`${ImportRoutePath.importGaCornerpiecesMapping}`} title={'Hoekstukken'} />

								<div className="menu-item">
									<div className="menu-content pt-8 pb-2">
										<span className="menu-section text-muted text-uppercase fs-8 ls-1">Globale Matrix koppelingen</span>
									</div>
								</div>
								<MenuItem to={`${ImportRoutePath.importMatrixCatalogItemMapping}`} title={'Artikelen'} />
								<MenuItem to={`${ImportRoutePath.importMatrixCatalogItemMapping}`} title={'Kortingsgroepen'} />

							</div>
						</div>
					</div>
				</div>
				<div className={'col-12 col-lg-10'}>
					<Outlet />
				</div>
			</div>
		</>
	);
};

type Props = {
	to: string;
	title: string;
};

const MenuItem: FC<Props & WithChildren> = ({children, to, title}) => {
	const {pathname} = useLocation();
	const isActive = checkIsActive(pathname, to);

	return (
		<div className="menu-item">
			<Link className={clsx('menu-link without-sub py-1', {active: isActive})} to={to}>
				<span className="menu-title">{title}</span>
			</Link>
			{children}
		</div>
	);
};
