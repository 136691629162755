export const  
  WS_START_PORT = process.env.REACT_APP_DYMO_WS_START_PORT,
  WS_END_PORT = process.env.REACT_APP_DYMO_WS_END_PORT,
  WS_CHECK_TIMEOUT = 3e3,
  WS_COMMAND_TIMEOUT = 1e4,  
  WS_SVC_HOST = process.env.REACT_APP_DYMO_SVC_HOST,
  WS_SVC_HOST_LEGACY = "localhost",  
  WS_SVC_PATH = "DYMO/DLS/Printing",
  WS_ACTIONS = {
    status: "StatusConnected",
    getPrinters: "GetPrinters",
    openLabel: "OpenLabelFile",
    printLabel: "PrintLabel",
    printLabel2: "PrintLabel2",
    renderLabel: "RenderLabel",
    loadImage: "LoadImageAsPngBase64",
    getJobStatus: "GetJobStatus",
  },
  WS_SVC_HOST_LOCAL = "localhost",
  WS_SVC_HOST_LOCAL_PORT = process.env.REACT_APP_DYMO_SVC_LOCAL_HOST_PORT,
  WS_SVC_HOST_LOCAL_PROTOCOL = process.env.REACT_APP_DYMO_SVC_LOCAL_HOST_PROTOCOL,
  WS_SVC_HOST_SERVER = process.env.REACT_APP_DYMO_SVC_HOST,
  WS_SVC_HOST_SERVER_PORT = process.env.REACT_APP_DYMO_SVC_HOST_PORT,
  WS_SVC_HOST_SERVER_PROTOCOL = process.env.REACT_APP_DYMO_SVC_HOST_PROTOCOL
  ;
