import React, {useEffect, useRef, useState} from 'react';
import WebcamStream from "./WebcamStream";
import {useFileUploadMutation} from '../../../../../shared/components/file-upload/useFileUploadMutation';
import {FormValues} from '../../../../../shared/components/file-upload/FileModal';
import * as api from '../../../../../modules/api-client/generated';
import {Form, Formik, FormikHelpers} from 'formik';
import {Button} from 'react-bootstrap';
import SubmitButton from '../../../../../shared/components/SubmitButton';
import SpinnerButtonV2 from "../../../../../shared/components/SpinnerButtonV2";

interface PhotoCaptureProps {
    title: string,
    altText: string,
    description: string,
    fileCategory: api.FileCategory,
    required: boolean;
    isActive: boolean;
    handleSkipped: () => void;
    saveFileIds: (ids: string[]) => void;
}

const PhotoCapture: React.FC<PhotoCaptureProps> = (props) => {
    const webcamRef = useRef<any>(null);
    const { mutate } = useFileUploadMutation();
    const [photos, setPhotos] = useState<{ base64: string, file: File, fileId?: string }[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.isActive) {
            webcamRef.current?.startCamera();
        } else {
            webcamRef.current?.stopCamera();
        }
    }, [props.isActive]);

    const changeCamera = () => {
        webcamRef.current?.changeCamera();
    };

    const capturePhoto = () => {
        const capturedImage = webcamRef.current?.captureImage();
        if (capturedImage) {
            const newPhoto = { base64: capturedImage, file: base64ToJpeg(capturedImage, 'deliveryPhoto.jpg') };
            setPhotos((prevPhotos) => [...prevPhotos, newPhoto]);
        }
    };

    const deletePhoto = (index: number) => {
        setPhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
    };

    const base64ToJpeg = (base64: string, fileName: string): File => {
        const byteString = atob(base64.split(',')[1]);
        const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        return new File([blob], fileName, { type: mimeString });
    };

    const finalizeCapture = async () => {
        setIsLoading(true);
        const fileIds: string[] = [];
        for (const photo of photos) {
            if (!photo.fileId) {
                await new Promise<void>((resolve, reject) => {
                    mutate({ file: photo.file, fileCategory: props.fileCategory, altText: props.altText, title: props.title, description: props.description }, {
                        onSuccess: (data) => {
                            photo.fileId = data;
                            fileIds.push(data);
                            resolve();
                        },
                        onError: (error) => {
                            console.error("Failed to upload photo:", error);
                            reject();
                        }
                    });
                });
            } else {
                fileIds.push(photo.fileId);
            }
        }
        props.saveFileIds(fileIds);
        setIsLoading(false);
    };

    const skipCapture = () => {
        props.handleSkipped();
    };

    return (
        <div>
            <div>
                <div className="mb-5 d-flex justify-content-between align-items-center bg-secondary p-3 rounded">
                    <a href={`#`} className="" onClick={changeCamera}>
                        <i className={'fa fa-refresh'}></i> Wijzig camera
                    </a>
                    <Button className="btn btn-primary btn-lg" onClick={capturePhoto}>
                        <i className={'fa fa-camera'}></i> Neem foto
                    </Button>
                    <div>
                        {!props.required && (
                            <a href={`#`} className="me-2" onClick={skipCapture}>
                                Overslaan
                            </a>
                        )}
                    <SpinnerButtonV2 className="btn btn-success btn-lg" onClick={finalizeCapture} disabled={photos.length==0}>
                        <i className={'fa fa-floppy-disk'}></i> Opslaan
                    </SpinnerButtonV2>
                    </div>
                </div>

            </div>
            <div className="row">
            <div className="col-10">

                <WebcamStream
                    ref={webcamRef}
                    facingMode="environment"
                    onError={(err) => alert(`Webcam error: ${err}`)}
                />

            </div>
            <div className="col">
                        <div className="d-flex flex-column">
                            {photos.map((photo, index) => (
                                <div key={index} className="position-relative mb-3">
                                    <img src={photo.base64} alt={`Thumbnail ${index}`} className="img-fluid rounded" />
                                    <Button
                                        variant="secondary"
                                        size="sm"
                                        className="position-absolute top-0 end-0 m-2"
                                        onClick={() => deletePhoto(index)}
                                    >
                                        <i className="fa fa-trash" />
                                    </Button>
                                </div>
                            ))}
                        </div>
                </div>

            </div>
        </div>
    );
};

export default PhotoCapture;