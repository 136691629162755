import React, {ReactElement, useState} from 'react';
import {PageTitle} from '../../../../../_metronic/layout/core';
import {EkoCard, EkoCardBody} from '../../../../shared/components/card';
import {Link, useParams} from 'react-router-dom';
import {useProductionItemGetId} from '../hooks/use-get-production-item';
import {
	ProductionBatchStatusCodes,
	ProductionBatchTypes,
	ProductionItemOperations,
	ProductionItemStatusCodesTransition,	
	PutProductionItemStatusRepresentation,
} from '../../../../modules/api-client/generated';
import {EkoCardHeader, EkoCardToolbar} from '../../../../shared/components/card';
import {ProductionItemStatus} from '../../../../shared/components/ProductionItemStatus';
import {SmRoutePath} from '../../sm/SmRoutes';
import {formatDate} from '../../../../shared/components/date';
import {ProductionItemModal} from '../components/ProductionItemModal';
import {PutRemarkForm} from '../components/PutRemarkForm';
import {PutInstructionsForm} from '../components/PutInstructionsForm';
import {usePutProductionItemStarted} from '../hooks/use-put-production-item-started';
import {usePutProductionItemNotStarted} from '../hooks/use-put-production-item-not-started';
import {usePutProductionItemFinished} from '../hooks/use-put-production-item-finished';
import {usePutProductionItemFailed} from '../hooks/use-put-production-item-failed';
import {usePutProductionItemCancelled} from '../hooks/use-put-production-item-cancelled';
import {PutProductionDateForm} from '../components/PutProductionDateForm';
import {EkoTable, TableHeader} from '../../../../shared/components/table';
import {PmRoutePath} from '../PmRoutes';
import ProductionBatchStatus from '../../../../shared/components/ProductionBatchStatus';
import {DownloadWorkOrders} from '../components/ProductionItemWorkorder';
import {StationOperationToggle} from '../components/StationOperationToggle';
import ConfiguredSillSpecification from '../../../../shared/components/ConfiguredSillSpecification';
import AfmontageSpecification from '../components/AfmontageSpecification';
import BooleanBadge from '../../../../shared/components/BooleanBadge';
import {PutKomoCertifiedForm} from '../components/PutKomoCertifiedForm';
import {PutUrgentForm} from '../components/PutUrgentForm';
import UrgentBadge from '../../../../shared/components/UrgentBadge';
import {stationLookup} from '../models/stations';
import NotFound from '../../../../shared/components/NotFound';
import {ProductionItemsOperations} from '../components/ProductionItemOperations';
import ProductionSillSpecification from '../../../../shared/components/ProductionSillSpecification';
import {PrinterSelectors} from '../../../../shared/components/printer/PrinterConstants';
import {usePutProductionItemOperation} from '../hooks/use-put-production-item-operation';
import {LabelPrinter} from '../../../../shared/components/printer/LabelPrinter';
import {ProductionItemPrinterLabelData} from '../../../../shared/components/printer/LabelPrinterTemplates';

const ProductionItem: React.FC = () => {
	const [showEditModal, setShowEditModal] = useState<boolean>(false);
	const [modalTitle, setModalTitle] = useState<string | undefined | null>(null);
	const [modalComponent, setModalComponent] = useState<ReactElement | undefined | null>(null);	
	const startMutation = usePutProductionItemStarted();
	const notStartedMutation = usePutProductionItemNotStarted();
	const finishedMutation = usePutProductionItemFinished();
	const failedMutation = usePutProductionItemFailed();
	const cancelledMutation = usePutProductionItemCancelled();
	const {id} = useParams<{id: string}>();
	const {data: pi} = useProductionItemGetId(id!);

	if (!id) return <NotFound />;
	if (!pi) return <NotFound />;

	const ids = [id];
	
	const productionDate = () => {
		setModalComponent(() => <PutProductionDateForm handleClose={() => setShowEditModal(false)} productionDate={pi.plannedProductionDate} ids={ids} />);
		setModalTitle('Wijzig productie datum');
		setShowEditModal(true);
	};

	const failed = () => {
		return new Promise<void>(() => {
			const command: PutProductionItemStatusRepresentation = {
				productionItemIds: ids,
			};
			failedMutation.mutate(command, {});
		}).finally();
	};

	const cancel = () => {
		return new Promise<void>(() => {
			const command: PutProductionItemStatusRepresentation = {
				productionItemIds: ids,
			};
			cancelledMutation.mutate(command, {});
		}).finally();
	};

	const finish = () => {
		return new Promise<void>(() => {
			const command: PutProductionItemStatusRepresentation = {
				productionItemIds: ids,
			};
			finishedMutation.mutate(command, {});
		}).finally();
	};

	const started = () => {
		return new Promise<void>(() => {
			const command: PutProductionItemStatusRepresentation = {
				productionItemIds: ids,
			};
			startMutation.mutate(command, {});
		}).finally();
	};

	const notStarted = () => {
		return new Promise<void>(() => {
			const command: PutProductionItemStatusRepresentation = {
				productionItemIds: ids,
			};
			notStartedMutation.mutate(command, {});
		}).finally();
	};
	const editKomo = () => {
		setModalComponent(() => <PutKomoCertifiedForm handleClose={() => setShowEditModal(false)} komoCertified={pi.komoCertified} ids={ids} />);
		setModalTitle('Wijzig komo');
		setShowEditModal(true);
	};
	const editUrgent = () => {
		setModalComponent(() => <PutUrgentForm handleClose={() => setShowEditModal(false)} urgent={pi.urgent} ids={ids} />);
		setModalTitle('Wijzig spoed label');
		setShowEditModal(true);
	};
	const editRemark = () => {
		setModalComponent(() => <PutRemarkForm handleClose={() => setShowEditModal(false)} remark={pi.remark} ids={ids} />);
		setModalTitle('Wijzig opmerking');
		setShowEditModal(true);
	};
	const editInstructions = () => {
		setModalComponent(() => <PutInstructionsForm handleClose={() => setShowEditModal(false)} instructions={pi.instructions} ids={ids} />);
		setModalTitle('Wijzig instructies');
		setShowEditModal(true);
	};

	const openDocument = () => {
		setModalComponent(() => <DownloadWorkOrders handleClose={() => setShowEditModal(false)} id={pi.id} title={`werkorder-` + pi.title} type={'itemId'} />);
		setModalTitle('Document');
		setShowEditModal(true);
	};

	var printLabel = () => {
		setModalComponent(() => <LabelPrinter data={{pi: pi} as ProductionItemPrinterLabelData} printerSelector={PrinterSelectors.ZebraExpeditie} labelId={'productionitem'} />);
		setModalTitle(`Label`);
		setShowEditModal(true);
	};

	function getBakNumber(): string {
		if (!pi) {
			return '-';
		}
		const batches = pi.batches.filter((batch) => {
			return (
				batch.type === ProductionBatchTypes.Neuten &&
				(batch.status === ProductionBatchStatusCodes.Accepted || batch.status === ProductionBatchStatusCodes.Started || batch.status === ProductionBatchStatusCodes.Finished)
			);
		});
		if (batches.length === 0 || !batches[0]) {
			return '-';
		}
		return batches[0].neutenGroupNumber ? batches[0].neutenGroupNumber.toString() : '-';
	}

	return (
		<>
			<PageTitle
				toolbar={
					<>
						<UrgentBadge value={pi.urgent} />
						<div>
							<div className="dropdown">
								<button className="btn btn-secondary dropdown-toggle btn-sm  me-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
									Update status
								</button>
								<ul className="dropdown-menu">
									<li>
										<a className="dropdown-item" href="#" onClick={() => notStarted()}>
											Reset
										</a>
									</li>
									<li>
										<a className="dropdown-item" href="#" onClick={() => started()}>
											Start
										</a>
									</li>
									<li>
										<a className="dropdown-item" href="#" onClick={() => finish()}>
											Gereed
										</a>
									</li>
									<li>
										<a className="dropdown-item" href="#" onClick={() => cancel()}>
											Annuleren
										</a>
									</li>
									<li>
										<a className="dropdown-item" href="#" onClick={() => failed()}>
											Mislukt
										</a>
									</li>
								</ul>
								<button className={'btn btn-secondary btn-sm me-3'} onClick={() => openDocument()}>
									Print werkbon
								</button>
								<button className={'btn btn-secondary btn-sm me-3'} onClick={() => printLabel()}>
									Print label
								</button>
								<button className={'btn btn-secondary btn-sm me-3 disabled'}>Download Dorpel XML</button>
							</div>
						</div>
					</>
				}
			>
				Dorpel
			</PageTitle>

			<ProductionItemModal show={showEditModal} handleClose={() => setShowEditModal(false)} title={modalTitle} size={'lg'} fullscreen={'false'} component={modalComponent} />

			<AfmontageSpecification id={pi.catalogItem.id} remark={pi.remark!} instruction={pi.instructions!} bakNumber={getBakNumber()} />

			<div className="card mb-4">
				<div className="card-body">
					<ProductionSillSpecification catalogItemId={pi.catalogItem.id} />
					<ConfiguredSillSpecification catalogItemId={pi.catalogItem.id} />
				</div>
			</div>
			<div className={'row'}>
				<div className={'col col-lg-6'}>
					<EkoCard className={'mb-5'}>
						<EkoCardBody>
							<div className={'row mb-4'}>
								<div className={'col'}>
									<label className="form-label">Code</label>
								</div>
								<div className={'col'}>{pi.code}</div>
							</div>
							<div className={'row mb-4'}>
								<div className={'col'}>
									<label className="form-label">Sequence</label>
								</div>
								<div className={'col'}>{pi.sequence}</div>
							</div>

							<div className={'row mb-4'}>
								<div className={'col'}>
									<label className="form-label">Status</label>
								</div>
								<div className={'col'}>
									<ProductionItemStatus status={pi.status} />
								</div>
							</div>

							<div className={'row mb-4'}>
								<div className={'col'}>
									<label className="form-label">EDS Barcode</label>
								</div>
								<div className="col barcode">
									*{pi.code}
									{pi.sequence}*
								</div>
							</div>

							<div className={'row mb-4'}>
								<div className={'col'}>
									<label className="form-label">Productiedatum (gepland)</label>
								</div>
								<div className={'col'}>
									<a href="#" onClick={() => productionDate()}>
										{pi.plannedProductionDate ? (
											<>{formatDate(pi.plannedProductionDate)}</>
										) : (
											<div className={''}>
												<i className={'fas fa-edit'}></i> Toevoegen
											</div>
										)}
									</a>
								</div>
							</div>
							<div className={'row mb-4'}>
								<div className={'col'}>
									<label className="form-label">Klant</label>
								</div>
								<div className={'col'}>{pi.customer?.name}</div>
							</div>
							<div className={'row mb-4'}>
								<div className={'col'}>
									<label className="form-label">Spoed</label>
								</div>
								<div className={'col'}>
									<a href="#" onClick={() => editUrgent()}>
										<div className={''}>
											<i className={'fas fa-edit me-1'}></i>
											<BooleanBadge value={pi.urgent} />
										</div>
									</a>
								</div>
							</div>
							<div className={'row mb-4'}>
								<div className={'col'}>
									<label className="form-label">Order</label>
								</div>
								<div className={'col'}>
									<Link to={SmRoutePath.link(SmRoutePath.orderEditLink(pi.order?.id!))} className="text-hover-primary fs-6">
										{pi.order?.code}
									</Link>
								</div>
							</div>
							<div className={'row mb-4'}>
								<div className={'col'}>
									<label className="form-label">Merk</label>
								</div>
								<div className={'col'}>{pi.merk}</div>
							</div>
							<div className={'row mb-4'}>
								<div className={'col'}>
									<label className="form-label">Komo?</label>
								</div>
								<div className={'col'}>
									<a href="#" onClick={() => editKomo()}>
										<div className={''}>
											<i className={'fas fa-edit me-1'}></i>
											<BooleanBadge value={pi.komoCertified} />
										</div>
									</a>
								</div>
							</div>
							<div className={'row mb-4'}>
								<div className={'col'}>
									<label className="form-label">Opmerking</label>
								</div>
								<div className={'col'}>
									<a href="#" onClick={() => editRemark()}>
										{pi.remark ? (
											<>{pi.remark}</>
										) : (
											<div className={''}>
												<i className={'fas fa-edit'}></i> Toevoegen
											</div>
										)}
									</a>
								</div>
							</div>
							<div className={'row mb-4'}>
								<div className={'col'}>
									<label className="form-label">Instructies</label>
								</div>
								<div className={'col'}>
									{pi.instructions ? (
										<div style={{whiteSpace: 'pre'}} className="font-monospace" dangerouslySetInnerHTML={{__html: pi.instructions!}}></div>
									) : (
										<div className={''}>
											<i className={'fas fa-edit'}></i> Toevoegen
										</div>
									)}

									<div className="mt-3 d-flex justify-content-center">
										<button type="button" className="btn btn-primary btn-sm" onClick={editInstructions}>
											Instructies wijzigen
										</button>
									</div>
								</div>
							</div>

							<div className={'row mb-4'}>
								<div className={'col'}>
									<label className="form-label">Catalogus item ID</label>
								</div>
								<div className={'col'}>{pi.catalogItem.id}</div>
							</div>
						</EkoCardBody>
					</EkoCard>

					<EkoCard className={'mb-5'}>
						<EkoCardHeader title={'Komt voor in de volgende batches'}></EkoCardHeader>
						<EkoCardBody>
							<EkoTable>
								<TableHeader>
									<th>Batch Nummer</th>

									<th>Type</th>
									<th>Status</th>
									<th>Baknummer</th>
									<th>BMH\3DM barcode</th>
								</TableHeader>
								<tbody>
									{pi.batches.map((batch) => {
										return (
											<tr key={batch.id}>
												<td>
													EDS: {batch.code}
													<br />
													BMH\3DM: {batch.bmhBatchCode}
												</td>
												<td>{batch.type}</td>
												<td>
													<ProductionBatchStatus status={batch.status} />
												</td>
												<td>
													{batch.type === 'latten' ? batch.lattenGroupNumber : ''}
													{batch.type === 'neuten' ? batch.neutenGroupNumber : ''}
													{batch.type === 'dorpel' ? 'n.v.t.' : ''}
												</td>
												<td>
													<span className="barcode">
														*{batch.bmhBatchCode}
														{batch.itemBatchSequence}*
													</span>
												</td>
											</tr>
										);
									})}
								</tbody>
							</EkoTable>
						</EkoCardBody>
					</EkoCard>

					<EkoCard className={'mb-5'}>
						<EkoCardHeader title={'Activiteit'}></EkoCardHeader>
						<EkoCardBody>
							<ActivityLogRow title={'Aangemaakt/reset'} transition={pi.notStarted!} />
							<ActivityLogRow title={'Gestart'} transition={pi.started!} />
							<ActivityLogRow title={'Gereed'} transition={pi.finished!} />
							<ActivityLogRow title={'Gefaald'} transition={pi.failed!} />
							<ActivityLogRow title={'Geannuleerd'} transition={pi.itemCancelled!} />
							<hr />
							<ActivityLogRow title={'Neuten gemaakt'} transition={pi.stationStatus.neutenGemaaktTransition!} />
							<ActivityLogRow title={'Latten gemaakt'} transition={pi.stationStatus.lattenGemaaktTransition!} />
							<ActivityLogRow title={'Dorpels gezaagd'} transition={pi.stationStatus.dorpelProfielGezaagdTransition!} />
							<ActivityLogRow title={'Dorpels bewerkt'} transition={pi.stationStatus.dorpelBewerktTransition!} />
							<ActivityLogRow title={'Neuten gemonteerd'} transition={pi.stationStatus.neutenGemonteerdTransition!} />
							<ActivityLogRow title={'Sluitpotten gemonteerd'} transition={pi.stationStatus.sluitpottenGemonteerdTransition!} />
							<ActivityLogRow title={'Latten gemonteerd'} transition={pi.stationStatus.lattenGemonteerdTransition!} />
							<ActivityLogRow title={'Kopisolatoren'} transition={pi.stationStatus.kopisolatorenGemonteerdTransition!} />
							<ActivityLogRow title={'Manchettes'} transition={pi.stationStatus.manchettesGemonteerdTransition!} />
							<ActivityLogRow title={'Hefschuif/Vouwwand'} transition={pi.stationStatus.hefschuifVouwwandTransition!} />
							<ActivityLogRow title={'Inpakken'} transition={pi.stationStatus.ingepaktTransition!} />
							<ActivityLogRow title={stationLookup.get(ProductionItemOperations.Custom)!.title} transition={pi.stationStatus.customTransition!} />
						</EkoCardBody>
					</EkoCard>
				</div>
				<div className={'col col-lg-6'}>
					<EkoCard>
						<EkoCardHeader title={'Productie'}>
							<EkoCardToolbar>
								<Link to={PmRoutePath.link(PmRoutePath.productionItemGroupLink(pi.code))} className="text-hover-primary fs-6">
									Groepeer op orderregel
								</Link>
							</EkoCardToolbar>
						</EkoCardHeader>
						<EkoCardBody>
							<>
								<StationOperationToggle id={pi.id} status={pi.stationStatus.neutenGemaakt} operation={ProductionItemOperations.NeutenGemaakt} title={'Neuten maken'} />
								<StationOperationToggle id={pi.id} status={pi.stationStatus.lattenGemaakt} operation={ProductionItemOperations.LattenGemaakt} title={'Latten maken'} />
								<StationOperationToggle id={pi.id} status={pi.stationStatus.dorpelProfielGezaagd} operation={ProductionItemOperations.DorpelProfielGezaagd} title={'Dorpel zagen'} />
								<StationOperationToggle id={pi.id} status={pi.stationStatus.dorpelBewerkt} operation={ProductionItemOperations.DorpelBewerkt} title={'Dorpel bewerken'} />
								<StationOperationToggle id={pi.id} status={pi.stationStatus.neutenGemonteerd} operation={ProductionItemOperations.NeutenGemonteerd} title={'Neuten monteren'} />
								<StationOperationToggle
									id={pi.id}
									status={pi.stationStatus.sluitpottenGemonteerd}
									operation={ProductionItemOperations.SluitpottenGemonteerd}
									title={'Sluitpotten monteren'}
								/>
								<StationOperationToggle id={pi.id} status={pi.stationStatus.lattenGemonteerd} operation={ProductionItemOperations.LattenGemonteerd} title={'Latten monteren'} />
								<StationOperationToggle
									id={pi.id}
									status={pi.stationStatus.kopisolatorenGemonteerd}
									operation={ProductionItemOperations.KopisolatorenGemonteerd}
									title={'Kopisolatoren'}
								/>
								<StationOperationToggle id={pi.id} status={pi.stationStatus.manchettesGemonteerd} operation={ProductionItemOperations.ManchettesGemonteerd} title={'Manchettes'} />
								<StationOperationToggle id={pi.id} status={pi.stationStatus.hefschuifVouwwand} operation={ProductionItemOperations.HefschuifVouwwand} title={'Hefschuif/Vouwwand'} />

								<StationOperationToggle id={pi.id} status={pi.stationStatus.ingepakt} operation={ProductionItemOperations.Ingepakt} title={'Inpakken'} />
								<StationOperationToggle
									id={pi.id}
									status={pi.stationStatus.custom}
									operation={ProductionItemOperations.Custom}
									title={stationLookup.get(ProductionItemOperations.Custom)?.title}
								/>
							</>
						</EkoCardBody>
					</EkoCard>
					<div className={'my-6'}>
						<ProductionItemsOperations barcode={`${pi.code}${pi.sequence}`} />
					</div>
				</div>
			</div>
		</>
	);
};

export const ActivityLogRow: React.FC<{
	title: string;
	transition: ProductionItemStatusCodesTransition;
}> = ({transition, title}) => {
	return (
		<>
			<div className={'row mb-4'}>
				<div className={'col'}>
					<label className="form-label">{title}</label>
				</div>
				<div className={'col'}>
					{transition ? (
						<>
							{formatDate(transition?.date, 'dd-MM-yy HH:mm')}
							<br />
							<span title={transition?.userId}>{transition?.userDisplayName}</span>
						</>
					) : (
						'-'
					)}
				</div>
			</div>
		</>
	);
};

export default ProductionItem;
