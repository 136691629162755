import React, {FC, useId, useState} from 'react';
import {Field, FieldAttributes, FieldProps, useFormikContext} from 'formik';
import {HefSchuifSlidingRailTypeComponent} from '../configurator/HefSchuifRailTypeField';
import {PrefValueRow} from './PrefValueRow';
import {Button, ButtonGroup, Col, Row} from 'react-bootstrap';

export const CustomerPreferencesHefSchuif: FC = () => {
	return (
		<>
			<PrefValueRow
				label={'Standaard looprail'}
				field={{
					name: 'slidingRailType',
					component: HefSchuifSlidingRailTypeComponent(),
				}}
			/>

			<PrefValueRow
				label={'Wordt een rubberblok toegepast in de lat voor vaste deur?'}
				field={{
					name: 'doorBarCutout',
					component: DoorBarCutoutComponent,
				}}
			/>

			<PrefValueRow
				label={'Standaard lat vastedeur inspringen'}
				field={{
					name: 'fixedDoorBarInset',
					className: 'form-control',
					type: 'number',
					min: 0,
				}}
			/>
		</>
	);
};

export const DoorBarCutoutField: React.FC<{field: FieldAttributes<any>; disabled?: boolean}> = (props) => {
	const fieldId = useId();
	const {setFieldValue} = useFormikContext<any>();
	const [doorBarCutout, setDoorBarCutout] = useState<boolean>(props.field.value !== null);

	const handleToggle = (value: boolean) => {
		setDoorBarCutout(value);
		if (!value) {
			setFieldValue(props.field.name, null);
		}
	};

	return (
		<Row>
			<Col xs={4} className="pe-0">
				<ButtonGroup>
					<Button variant={!doorBarCutout ? 'primary' : 'secondary'} onClick={() => handleToggle(false)} disabled={props.disabled}>
						Nee
					</Button>
					<Button variant={doorBarCutout ? 'primary' : 'secondary'} onClick={() => handleToggle(true)} disabled={props.disabled}>
						Ja
					</Button>

				</ButtonGroup>
			</Col>
			<Col xs={8} className="ps-0">
				<div className="input-group">
					{doorBarCutout && (
						<>
							<Field name={`${props.field.name}.length`}>
								{({field}: FieldProps) => (
									<input {...field} id={`${fieldId}-length`} type="number" className="form-control" placeholder="Lengte" disabled={props.disabled} required min={1} />
								)}
							</Field>
							<Field name={`${props.field.name}.width`}>
								{({field}: FieldProps) => (
									<input {...field} id={`${fieldId}-width`} type="number" className="form-control" placeholder="Breedte" disabled={props.disabled} required min={1} />
								)}
							</Field>
							<span className="input-group-text">mm</span>
						</>
					)}
				</div>
			</Col>
		</Row>
	);
};

export const DoorBarCutoutComponent: React.ComponentType<FieldProps> = React.memo(({field, ...props}) => {
	return <DoorBarCutoutField field={field} {...props} />;
});
