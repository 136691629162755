import React, {useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {WeeklyImportedOrdersStatsRow} from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';
import {Line} from 'react-chartjs-2';
import {TooltipItem} from 'chart.js';
import {DateTime} from "luxon";
import {EkoTable, TableHeader} from "../../../../shared/components/table";
import YearPicker from "../../../../shared/components/YearPicker";

export const WeeklyOrdersImportStatsTile: React.FC = () => {
    const [currentMode, setCurrentMode] = useState<'percent' | 'count'>('percent');
    const currentYear = DateTime.now().year;
    const [year, setYear] = useState<number>(currentYear);
    const {isInitialLoading, data: orderImportData} = useQuery<{
        [key: string]: { [key: string]: WeeklyImportedOrdersStatsRow; };
    }>(
        ['weeklyImportedOrdersStats'],
        () => ApiClient.Sm.Report.weeklyImportedOrdersStats().then((res) => res.data));
    if (!orderImportData) return <Loading/>

    if (isInitialLoading) return <Loading/>
    const allWeeks = Array.from({length: 53}, (_, i) => (i + 1).toString());
    const yearData: any = {
        labels: allWeeks.map((week) => 'Week ' + week),
        datasets: [
            {
                tension: 0.3,
                label: 'Ekosiet',
                borderColor: '#093869',
                borderWidth: 2,
                fill: currentMode === 'percent',
                pointStyle: 'circle',
                backgroundColor: '#093869',
                pointHoverBackgroundColor: '#093869',
                pointRadius: 3,
                pointHoverRadius: 5,
                data: allWeeks.map((week) => currentMode === 'percent' ? orderImportData[year][week]?.percentageIngevoerdDoorEkosiet || null : orderImportData[year][week]?.aantalOrdersIngevoerdDoorEkosiet || null),
            },
            {
                tension: 0.3,
                label: 'Klanten',
                borderColor: '#ffffff',
                borderWidth: 2,
                fill: currentMode === 'percent',
                pointStyle: 'circle',
                backgroundColor: '#FFFFFFFF',
                pointHoverBackgroundColor: '#093869',
                pointRadius: 3,
                pointHoverRadius: 5,
                data: allWeeks.map((week) => currentMode === 'percent' ? orderImportData[year][week]?.percentageIngevoerdDoorKlanten || null : orderImportData[year][week]?.aantalOrdersIngevoerdDoorKlanten || null),
            },
            {
                tension: 0.3,
                label: 'Import',
                borderColor: '#ff9900',
                borderWidth: 2,
                fill: currentMode === 'percent',
                pointStyle: 'circle',
                backgroundColor: '#ff9900',
                pointHoverBackgroundColor: '#093869',
                pointRadius: 3,
                pointHoverRadius: 5,
                data: allWeeks.map((week) => currentMode === 'percent' ? orderImportData[year][week]?.percentageGeimporteerd || null : orderImportData[year][week]?.aantalOrdersGeimporteerd || null),
            },
        ],
    };
    const lineOptions = (orderImportData: any) => ({
        responsive: true,
        plugins: {
            legend: {
                display: true,
                labels: {
                    backgroundColor: '#9BD0F5',
                    borderColor: '#36A2EB',
                    color: '#fff',
                }
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem: TooltipItem<'line'>) => {
                        const week = tooltipItem.label.split(' ')[1] || '';
                        const datasetLabel = tooltipItem.dataset.label || '';
                        const weekData = orderImportData[week];

                        if (!weekData) {
                            return `${datasetLabel}: No data available for week ${week}`;
                        }
                        var percentage, count;
                        if (datasetLabel === 'Ekosiet') {
                            percentage = weekData.percentageIngevoerdDoorEkosiet || 'N/A';
                            count = weekData.aantalOrdersIngevoerdDoorEkosiet || 'N/A';
                        } else if (datasetLabel === 'Klanten') {
                            percentage = weekData.percentageIngevoerdDoorKlanten || 'N/A';
                            count = weekData.aantalOrdersIngevoerdDoorKlanten || 'N/A';
                        } else if (datasetLabel === 'Import') {
                            percentage = weekData.percentageGeimporteerd || 'N/A';
                            count = weekData.aantalOrdersGeimporteerd || 'N/A';
                        }
                        return `${datasetLabel}: ${count} (${percentage}%)`;

                    },

                },
            },
        },
        scales: {
            x: {
                border: {
                    display: false,
                },
                ticks: {
                    display: false,
                    color: '#ffffff',
                },
                grid: {
                    display: false,
                    drawOnChartArea: false,
                    drawTicks: false,
                }
            },
            y: {
                border: {
                    display: true
                },
                ticks: {
                    display: true,
                    color: '#ffffff',
                },
                grid: {
                    display: true,
                },
                stacked: currentMode === 'percent',
                beginAtZero: currentMode === 'percent',
                max: currentMode === 'percent' ? 100 : undefined,
            }
        }
    })
    const thisYearData = orderImportData[year];
    const nameOflastWeek = Object.keys(thisYearData)[Object.keys(thisYearData).length - 2];
    const nameOfCurrentWeek = Object.keys(thisYearData)[Object.keys(thisYearData).length - 1];
    const lastWeekData = thisYearData[nameOflastWeek];
    const currentWeekData = thisYearData[nameOfCurrentWeek];


    const ekosietLastWeek = lastWeekData.aantalOrdersIngevoerdDoorEkosiet || 0;
    const klantenLastWeek = lastWeekData.aantalOrdersIngevoerdDoorKlanten || 0;
    const importLastWeek = lastWeekData.aantalOrdersGeimporteerd || 0;
    const ekosietLastWeekPercentage = lastWeekData.percentageIngevoerdDoorEkosiet || 0;
    const klantenLastWeekPercentage = lastWeekData.percentageIngevoerdDoorKlanten || 0;
    const importLastWeekPercentage = lastWeekData.percentageGeimporteerd || 0;

    const ekosietCurrentWeek = currentWeekData.aantalOrdersIngevoerdDoorEkosiet || 0;
    const klantenCurrentWeek = currentWeekData.aantalOrdersIngevoerdDoorKlanten || 0;
    const importCurrentWeek = currentWeekData.aantalOrdersGeimporteerd || 0;
    const ekosietCurrentWeekPercentage = currentWeekData.percentageIngevoerdDoorEkosiet || 0;
    const klantenCurrentWeekPercentage = currentWeekData.percentageIngevoerdDoorKlanten || 0;
    const importCurrentWeekPercentage = currentWeekData.percentageGeimporteerd || 0;

    return (
        <div className="card mb-5 mb-xl-8">
            <div className="card-body p-0">
                <div className="px-9 pt-7 card-rounded h-400px w-100 bg-success">
                    <div className="d-flex flex-stack text-white justify-content-between align-items-center"><h3
                        className="m-0 fw-bold text-white fs-3">Orderinvoer per week</h3>
                        <div className={'d-flex justify-content-end'}>
                            <YearPicker setSelectedYear={setYear} initialYear={currentYear} minYear={2023} maxYear={currentYear} />
                            <div className='form-check form-switch'>
                                <input className='form-check-input' type='checkbox' role='switch'
                                       onChange={() => setCurrentMode(currentMode === 'percent' ? 'count' : 'percent')}
                                       checked={currentMode === 'percent'}
                                       id='toggleMode'/>
                                <label className='form-check-label text-white'
                                       htmlFor='toggleMode'>Percentage</label>
                            </div>

                        </div>
                    </div>
                    <div className="w-100 my-3">
                        <Line options={lineOptions(orderImportData[year])} data={yearData}/>
                    </div>

                </div>
                <div
                    className="shadow-xs card-rounded mx-5 mb-9 px-6 py-9 position-relative z-index-1 bg-body"
                    style={{marginTop: '-125px'}}>
                    <div>
                        <EkoTable>
                            <TableHeader>
                                <th></th>
                                <th>Vorige Week</th>
                                <th>Huidige Week</th>
                            </TableHeader>
                            <tbody>
                            <tr>
                                <td><span title={'Geconfigureerd door ...@ekosiet.nl'}>Ekosiet <i
                                    className={'fa fa-info-circle'}/> </span></td>
                                <td>{ekosietLastWeek} ({ekosietLastWeekPercentage}%)</td>
                                <td>{ekosietCurrentWeek} ({ekosietCurrentWeekPercentage}%)</td>
                            </tr>
                            <tr>
                                <td><span title={'Geconfigureerd door klanten'}>Klanten <i
                                    className={'fa fa-info-circle'}/></span></td>
                                <td>{klantenLastWeek} ({klantenLastWeekPercentage}%)</td>
                                <td>{klantenCurrentWeek} ({klantenCurrentWeekPercentage}%)</td>
                            </tr>
                            <tr>
                                <td><span title={'Geimporteerde orders (ekosiet + klanten)'}>Geimporteerd <i
                                    className={'fa fa-info-circle'}/></span></td>
                                <td>{importLastWeek} ({importLastWeekPercentage}%)</td>
                                <td>{importCurrentWeek} ({importCurrentWeekPercentage}%)</td>
                            </tr>
                            </tbody>
                        </EkoTable>
                    </div>
                </div>
            </div>
        </div>
    );
};