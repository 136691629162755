/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {EkoCard, EkoCardBody, EkoCardHeader} from "../../../../../shared/components/card";
import {Button} from "react-bootstrap";

export function CustomerImportMatrixConfiguration() {
    return (
        <EkoCard>
            <EkoCardHeader title={'Download Matrix configuratie'}>
            </EkoCardHeader>
            <EkoCardBody>
                <p className={'lead'}>
                    Download hier de configuratie file voor de Matrix. Deze file wordt gebruikt om de Matrix te
                    configureren zodat de koppeling goed verloopt en de prijzen binnen Matrix correct worden
                    weergegeven.
                </p>
                <Button variant="primary" className={'mb-3'} onClick={() => {
                }}>Download Matrix configuratie file.
                </Button>
            </EkoCardBody>
        </EkoCard>
    )
}

