/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {AxiosResponse} from "axios";
import ApiClient from "../../../../modules/api-client/ApiClient";

type Props = {
    id: string
    type: "itemId"|"itemCode"|"batchId"
    title: string
    handleClose: () => void,
}

const DownloadWorkOrders: FC<Props> = ({id, type,title}) => {
    const [post, setPost] = React.useState<string>();
    React.useEffect(() => {
        if (!post) {
            if (type === "itemId") {
                ApiClient.Sm.Print.pmProductionPrintProductionItemIdPdfWorkorderGet(id, undefined, {responseType: "blob"})
                    .then((d: AxiosResponse<File>) => {
                        const file = new Blob(
                            [d.data],
                            {type: 'application/pdf'});
                        setPost(URL.createObjectURL(file));
                    });
            }
        }
    })

    if (!post) return <>Document wordt aangemaakt...</>;

    return (
        <>
            <div className={'d-flex justify-content-end mb-3'}>
                <a href={post} className={'btn btn-primary me-3'} target="_blank" rel="noopener"
                   download={title + `.pdf`}>Download</a>
            </div>

            <object width="100%" height="1000" data={post} type="application/pdf">Werkorder</object>

        </>
    )
}

export {DownloadWorkOrders} 