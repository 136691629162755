import {FC, ReactNode} from 'react'
import {WithChildren} from '../../../../_metronic/helpers'
import clsx from "clsx";

type Props = {
  className?: string
  title?: string
    subtitle?: ReactNode; // Updated subtitle property to accept ReactNode

}

const EkoCardHeader: FC<Props & WithChildren> = ({className, children,title, subtitle}) => {
  return (
      <div  className={clsx(
          'card-header',
          className && className,
      )}>
          <div className='card-title m-0 d-flex flex-column'>
              <h3 className='fw-bolder m-0'>{title}</h3>
              {subtitle && <div className='fw-normal m-0'>{subtitle}</div>} {/* Render subtitle if provided */}

          </div>
          {children}
      </div>
  )
}

export {EkoCardHeader}
