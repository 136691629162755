import {FC, useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {
    Configuration,
    PostConfiguratorOrderLineRepresentation, SillConfiguration, ZijlichtConfiguration,
} from '../../../../modules/api-client/generated';
import {usePostOrderline} from '../orders/hooks/orderline/use-post-orderline';
import {useEkosietMutation} from '../../../../shared/hooks/useEkosietMutation';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {Form, Formik} from 'formik';
import SpinnerButton from '../../../../shared/components/SpinnerButton';
import OrderConfiguratorModalFinish from '../orders/edit-order/components/OrderConfiguratorModalFinish';
import {
    createPath,
    createSearchParams,
    Link,
    Outlet,
    Route,
    Routes,
    URLSearchParamsInit,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import {usePutOrderline} from '../orders/hooks/orderline/use-put-orderline';
import {ConfiguratorSillPicker} from '../../../../shared/components/configurator/ConfiguratorSillPicker';
import {modalsRoot} from '../../../../global-helpers';
import {SmRoutePath} from '../SmRoutes';
import * as Yup from 'yup';
import {useEkosietAuth0} from "../../../../modules/auth0/core/useEkosietAuth0";
import {CustomerRoutePath} from "../../../../customer-portal/routing/RoutePath";
import {ConfiguratorComponent} from "../../../../shared/components/configurator/ConfiguratorComponent";
import { ExtendedSillConfiguration } from '../../../../shared/components/configurator/ConfiguratorContextProvider';

export type Sill = {
    id: string;
    title: string;
};

export interface OrderLineConfiguratorProps {
    isOrderEdit?: boolean;
}

interface OrderLineFormValues {
    quantity?: number;
    remark?: string;
    merk?: string;
}

export const OrderLineConfigurator: FC<OrderLineConfiguratorProps> = ({isOrderEdit = true}) => {
    const {id: orderId} = useParams<{ id: string }>();

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [title] = useState<string>('Voeg dorpel toe aan order');
    const [configuration, setConfiguration] = useState<SillConfiguration | ZijlichtConfiguration | ExtendedSillConfiguration>();

    const {isKlant} = useEkosietAuth0();

    const mutatePostOrderLine = usePostOrderline();
    const mutatePutOrderLine = usePutOrderline();

    const putConfigurationMutation = useEkosietMutation((data: SillConfiguration | ZijlichtConfiguration) => {
        return ApiClient.Pim.Configurator.saveConfiguration(undefined, data, {}).then((r) => r.data);
    });

    const orderLineId = searchParams.get('orderLineId');
    const priceListId = searchParams.get('pl');
    const sillId = searchParams.get('sillId');
    const customerId = searchParams.get('customerId');
    const quantity = searchParams.get('q');
    const remark = searchParams.get('remark');
    const merk = searchParams.get('merk');

    const search = {
        ...(orderLineId ? {orderLineId: orderLineId} : {}),
        ...(sillId ? {sillId: sillId} : {}),
        ...(customerId ? {customerId: customerId} : {}),
        ...(priceListId ? {pl: priceListId} : {}),
        ...(quantity ? {q: quantity} : {}),
        ...(remark ? {remark: remark} : {}),
        ...(merk ? {merk: merk} : {}),
    };

    const handleClose = () => {
        if (isKlant) {
            if (isOrderEdit) {
                navigate(CustomerRoutePath.link(CustomerRoutePath.orderLink(orderId!)));
            } else {
                navigate(CustomerRoutePath.link(CustomerRoutePath.orderWizardLink(orderId!)));
            }
        } else {
            if (isOrderEdit) {
                navigate(SmRoutePath.link(SmRoutePath.orderEditLink(orderId!)));
            } else {
                navigate(SmRoutePath.link(SmRoutePath.orderAddWithIdLink(orderId!)));
            }
        }
    };

    const handleModelSelected = (sill: Sill) => {
        navigate(
            createPath({
                pathname: './',
                search: createSearchParams({
                    ...(orderLineId ? {orderLineId: orderLineId} : {}),
                    sillId: sill.id,
                    ...(customerId ? {customerId: customerId} : {}),
                    ...(priceListId ? {pl: priceListId} : {}),
                    ...(quantity ? {q: quantity} : {}),
                    ...(remark ? {remark: remark} : {}),
                    ...(merk ? {merk: merk} : {}),
                }).toString(),
            }),
        );
    };

    const handleConfigurationConfirmed = async (configuration: SillConfiguration | ZijlichtConfiguration) => {
        setConfiguration(configuration);
        navigate({pathname: './finish', search: createSearchParams(search).toString()});
    };

    const handleOrderLine = async (values: OrderLineFormValues) => {
        const result = await putConfigurationMutation.mutateAsync(configuration!);
        const line: PostConfiguratorOrderLineRepresentation = {
            ...values,
            quantity: values.quantity ?? 1,
            orderlineType: 'configurationLine',
            catalogItemId: result.catalogItemId,
            fastenersIncluded: configuration && 'fastenersIncluded' in configuration ? configuration.fastenersIncluded ?? false : false,
        };

        if (!orderLineId) {
            await mutatePostOrderLine.mutateAsync({orderId: orderId!, line: line});
        } else {
            await mutatePutOrderLine.mutateAsync({orderLineId: orderLineId, line: line});
        }

        handleClose();
    };

    return (
        <Modal container={modalsRoot}
               tabIndex={-1}
               aria-hidden='true'
               dialogClassName='configurator-modal modal-dialog-scrollable'
               show={true}
               onHide={handleClose}
               animation={false}
               backdrop="static">
            <Routes>
                <Route index
                       element={
                           <ConfigureStep
                               title={title}
                               search={search}
                               orderLineId={orderLineId}
                               onConfirm={handleConfigurationConfirmed}
                               onCancel={handleClose}/>
                       }/>
                <Route path={'select-model'}
                       element={
                           <SelectModelStep
                               title={'Dorpel model'}
                               onConfirm={handleModelSelected}
                               onCancel={handleClose}/>
                       }/>
                <Route path={'finish'} element={
                    <FinishStep
                        title={title}
                        initValues={{
                            quantity: quantity ? parseInt(quantity) : undefined,
                            remark: remark!,
                            merk: merk!
                        }}
                        configuration={configuration!}
                        orderlineId={orderLineId!}
                        handleAddOrderLine={handleOrderLine}
                        onCancel={handleClose}/>
                }/>
            </Routes>
            <Outlet/>
        </Modal>
    );
};

const SelectModelStep: FC<{
    title: string;
    onConfirm: (sill: Sill) => void;
    onCancel: () => void;
}> = ({title, onConfirm, onCancel}) => {
    return (
        <>
            <Modal.Header>
                <Modal.Title className={'text-nowrap'}>{title}</Modal.Title>
                <div className={'d-flex w-100 justify-content-end align-items-center'}>
                    <button type='button' className={'btn btn-link mx-4'} onClick={onCancel}>
                        Annuleren
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <ConfiguratorSillPicker onSillSelected={onConfirm}/>
            </Modal.Body>
        </>
    );
};

const ConfigureStep: FC<{
    title: string;
    search: URLSearchParamsInit;
    orderLineId: string | null;
    onConfirm: (configuration: SillConfiguration | ZijlichtConfiguration) => Promise<void>;
    onCancel: () => void;
}> = (props) => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const sillId = searchParams.get('sillId');
    const customerId = searchParams.get('customerId') ?? undefined;
    const configurationId = searchParams.get('configurationId');

    useEffect(() => {
        if (!sillId && !configurationId) {
            navigate(createPath({
                pathname: './select-model',
                search: createSearchParams(props.search).toString(),
            }), {replace: true});
        }
    }, []);

    return <ConfiguratorComponent sillId={sillId} configurationId={configurationId} orderLineId={props.orderLineId} customerId={customerId}
                                  onConfirm={props.onConfirm}>
        {({isSubmitting, submit, configuratorData, formElement}) => <>

            <Modal.Header>
                <Modal.Title className={'text-nowrap'}>{props.title}</Modal.Title>

                <div className={'d-flex w-100 justify-content-end align-items-center'}>
                    <button type='button' className={'btn btn-link mx-4'} onClick={props.onCancel}>
                        Annuleren
                    </button>

                    <SpinnerButton type='button' className={'btn btn-primary mx-4'} onClick={submit}
                                   spinning={isSubmitting}>
                        Volgende <span className='fas fa-angle-right'></span>
                    </SpinnerButton>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className={'position-relative mb-5'}>
                    <div className='position-absolute top-50 translate-middle-y'>
                        <Link to={{pathname: './select-model', search: createSearchParams(props.search).toString()}}
                              relative='path' className={'btn btn-light-primary'}>
                            <i className='fas fa-arrow-left'></i> Wijzig dorpelmodel
                        </Link>
                    </div>
                    <h1 className={'text-center'}>Configureer {configuratorData?.sillName}</h1>
                </div>
                <div>
                    {formElement}
                </div>
            </Modal.Body>
        </>}
    </ConfiguratorComponent>
};

// const ConfigureForm: FC<{
//     title: string;
//     search: URLSearchParamsInit;
//     onConfirm: (configuration: SillConfiguration) => Promise<void>;
//     onCancel: () => void;
// }> = (props) => {
//     const {values, submitForm, isSubmitting} = useFormikContext<SillConfiguration>();
//
//
//     const configuratorContext = useConfiguratorContext();
//
//     return (
//         <Form>
//             <Modal.Header>
//                 <Modal.Title className={'text-nowrap'}>{props.title}</Modal.Title>
//
//                 <div className={'d-flex w-100 justify-content-end align-items-center'}>
//                     <button type='button' className={'btn btn-link mx-4'} onClick={props.onCancel}>
//                         Annuleren
//                     </button>
//
//                     <SpinnerButton type='button' className={'btn btn-primary mx-4'} onClick={submitForm}
//                                    spinning={isSubmitting}>
//                         Volgende <span className='fas fa-angle-right'></span>
//                     </SpinnerButton>
//                 </div>
//             </Modal.Header>
//             <Modal.Body>
//                 <div className={'position-relative mb-5'}>
//                     <div className='position-absolute top-50 translate-middle-y'>
//                         <Link to={{pathname: './select-model', search: createSearchParams(props.search).toString()}}
//                               relative='path' className={'btn btn-light-primary'}>
//                             <i className='fas fa-arrow-left'></i> Wijzig dorpelmodel
//                         </Link>
//                     </div>
//                     <h1 className={'text-center'}>Configureer {configuratorContext.configuratorData?.sillName}</h1>
//                 </div>
//                 <div>
//                     <ConfigurationForm configurator={configuratorContext.configuratorData}/>
//                 </div>
//             </Modal.Body>
//         </Form>
//     );
// };

const FinishStep: FC<{
    title: string;
    orderlineId?: string;
    configuration: Configuration;
    initValues: OrderLineFormValues;
    handleAddOrderLine: (values: OrderLineFormValues) => Promise<void>;
    onCancel: () => void;
}> = ({title, initValues, configuration, handleAddOrderLine, onCancel}) => {


    const formSchema = Yup.object().shape({
            remark: Yup.string().min(3, 'Minimaal 3 karakters.').notRequired().nullable(),

            quantity: Yup.number()
                .min(0, 'Negatief aantal is niet toegestaan. Gebruik een negatieve prijs!')
                .max(999, 'Aantallen groter dan 999 zijn niet toegestaan. Gebruik meerdere order regels.')
                .nullable()
                .required('Aantal is verplicht.'),

            merk: Yup.string()
                .notRequired()
                .nullable(),
        },
    );

    const navigate = useNavigate();

    return (
        <Formik initialValues={initValues} onSubmit={handleAddOrderLine} validationSchema={formSchema}>
            {({submitForm, isSubmitting, isValidating}) => {
                return (
                    <Form>
                        <Modal.Header>
                            <Modal.Title className={'text-nowrap'}>{title}</Modal.Title>
                            <div className={'d-flex w-100 justify-content-end align-items-center'}>
                                <button type='button' className={'btn btn-link mx-4'} onClick={onCancel}>
                                    Annuleren
                                </button>
                                <SpinnerButton type='button' className={'btn btn-primary mx-4'} onClick={submitForm}
                                               spinning={isSubmitting}>
                                    Opslaan
                                </SpinnerButton>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-6 offset-3'>
                                        <OrderConfiguratorModalFinish configurationId={null}/>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6 offset-3 d-flex justify-content-center'>
                                        <button type='button' className={'btn btn-secondary mx-4'}
                                                onClick={() => navigate(-1)}>
                                            Terug
                                        </button>
                                        <SpinnerButton type='button' className={'btn btn-primary mx-4'}
                                                       onClick={submitForm} spinning={isSubmitting}>
                                            Opslaan
                                        </SpinnerButton>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Form>
                );
            }}
        </Formik>
    );
};
