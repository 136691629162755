import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast"
import ApiClient from "../../../../../modules/api-client/ApiClient";
import {
    ExpeditionsByDate,
    TransportPlanningByOrderViewArrayODataValue
} from "../../../../../modules/api-client/generated";
import { AxiosError } from "axios";
import {DateTime} from "luxon";

export const useGetTransportPlanning = ():
    UseQueryResult<Array<ExpeditionsByDate>, unknown> => {


    var key = 'emTransportPlanning';

    const response = useQuery([key], () => {
        return ApiClient.Em.Expedition
            .getExpeditionsFromFirstNonDeliveredDate()
            .then((d) => d.data);
    }
    )

    if (response.error) {
        var error = response.error as AxiosError;
        toast.error(error?.message);
        // toast.error(error?.response?.data?.title || 'Onbekende fout!');
    }

    return response;
};
